import * as actionTypes from "../ActionTypes";
import axios from "../../config/axios";

// export const loginRequestStart = () => {
//     return {
//       type: actionTypes.LOGIN_REQUEST
//     };
//   };
  
  export const loginRequestSuccess = (res) => {
    return {
      type: actionTypes.LOGIN_REQUEST_SUCCESS,
      data: res
    };
  };
  
  export const loginRequestFail = (error) => {
    // console.log(error.response);
    return {
      type: actionTypes.LOGIN_REQUEST_FAIL,
      data: error.response
    };
  };
  export const logoutRequest = (res) => {
    return {
      type: actionTypes.LOGOUT_REQUEST,
      data: res
    };
  };

  
  export const requestForLogin = ( data )=> {
    return dispatch => {
        // dispatch(loginRequestStart());
        let FormData = {
            username: data.username, 
            password: data.password, 
            client_id: "inventory-demo",
            client_secret: "Wqhqdz9PMKMHWUG9pZ0Oowvrz",
            grant_type: "password"
          };

          return axios.post('/oauth/access_token', FormData)
          .then( res => {
              return dispatch( loginRequestSuccess(res.data) );
          } )
          .catch( error => {
              return dispatch( loginRequestFail( error ) )
          } )
    }
  }

  export default requestForLogin;