 
  import React , { Component } from 'react';
  import urlCongigration from './../../config/urlCongigration';
  import Aux from '../../pages/Wrapper/Auxiliary';
  import BreadCrumb from './../../pages/BreadCrumb/BreadCrumb';
export default class RedirectNews extends Component{
  cmsBaseUrl = urlCongigration()?.cmsBaseUrl;

    constructor(props){
        super(props)
    }
    componentDidMount(){
      window.location.replace(this.cmsBaseUrl+'news/')
    }

    render(){
        return(
          <Aux>
          <BreadCrumb headerTitle={"News"} headerName={"News"} headerContent={""}></BreadCrumb>
          <section id="content-section" className="" style={{marginBottom:40+'px', marginTop:40+'px'}}>
          <div className="container">
            <div className="text-center my-3">
              <div class="col-12 w-100">
                   <p class="loading alert alert-primary text-center">Please wait... <i class="fas fa-circle-notch fa-spin"></i></p>
              </div>
           </div>
          </div>
          </section>
          </Aux>
        )
    }
}

