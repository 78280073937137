
import React, { Component } from 'react';
import  { Link } from 'react-router-dom';

export default class SocialIcons extends Component{
    render() {

        return(
            <div>
                 <span className="follow-us">Follow us on: &nbsp;</span> <a target="_blank" href="https://www.facebook.com/ParkEngageLLC/" className="px-0  btn-link "><i className="la la-2x la-facebook"></i></a>
                    <a href="https://twitter.com/Parkengagellc" target="_blank" className="px-0   btn-link"><i className="la la-2x la-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/parkengage" target="_blank" className="px-0  btn-link"><i className="la la-2x la-linkedin"></i></a>
                    <a href="https://www.instagram.com/parkengagellc/" target="_blank" className="px-0  btn-link"><i className="la la-2x la-instagram"></i></a>

            </div>
        )

    }
}

