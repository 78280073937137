import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import * as actionCreators from '../../Redux/Actions';
import NotificationSystem from 'react-notification-system';
import Aux from "../Wrapper/Auxiliary";
import Wrapper from '../Wrapper//Wrapper';
import Input from "../../Global/Input/Input"
import Spinner from "../../Global/Spinner/Spinner"
import "./req-a-demo.css";
import InputMask from 'react-input-mask';
import demoImg from "./Assets/demo-lt.png";
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery';

import {fade,makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Form, Button } from 'react-bootstrap';

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #ced4da',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#4A90E2', 0.25)} 0 0 0 2px`,
      borderColor: '#4A90E2',
    },
  },
  focused: {},
}));
function RedditTextField(props) {
  const classes = useStylesReddit();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

class RequestDemo extends Component {
    ServicesList= []; 
    selectBoxWrapperClass='col-md-12 mb-3';
    
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.notificationSystem = React.createRef();
      let path = this.props.history.location
        if(this.props.isFooterForm){
            this.selectBoxWrapperClass='col-md-12 mb-3';
        }else{
            this.selectBoxWrapperClass='col-md-12 mb-3';
        }
        this.state = {
            requestAForm: {
                name: {
                    value: '',
                    name: 'name',
                    type:'text',
                    label: 'Full Name',
                    validation:'required'
                  },
                  email: {
                    value: '',
                    name: 'email',
                    type:'text',
                    label: 'Email Address',
                    validation:'email'
                  },
                 
                  contact: {
                    value: '',
                    name: 'contact',
                    type:'number',
                    pattern:'\d*',
                    label: 'Phone Number',
                    validation:'integer|max:10||min:10'
                  },
                  company_name: {
                    value: '',
                    name: 'company_name',
                    type:'text',
                    label: 'Company Name',
                    validation:'required'
                  },
                  city: {
                    value: '',
                    name: 'city',
                    type:'text',
                    label: 'City',
                    validation:'required'
                  },
                  state: {
                    value: '',
                    name: 'state',
                    type:'text',
                    label: 'State',
                    validation:'required'
                  }
              },
            
            formIsValid: false,
            loading: false,
            country: '',
            service: '',
        }
    }

    componentWillMount() {  
        this.props.requestFetchCountry().then(() => {
          if(this.props['countries']) {
            this.countryData = this.props.countries.data;
            this.setState({ countryData: this.countryData });
          }
        });
        this.props.requestForDemoServices().then(() => {
            if(this.props['result']) {
              this.ServicesData = this.props.result.data;
            this.setState({ ServicesData: this.ServicesData });
            }
          });
    
      }

      InputHandler = (event) => {
        let { name, value } = event.target;
        this.validator.showMessageFor(name);
        let updated = { ... this.state.requestAForm}
        let nameRegix = /^[A-Za-z\s]*$/;
        let phoneRegix = /^[\d]+$/g;
        if(name ==='name'){
          if(value.match(nameRegix)){
            value = value
          }
          else {  
            if(value.length ===0){
              value = '';
            }else {
              value = updated[name].value;
            }
             }
        }
        if(name ==='contact'){
          if(value.match(phoneRegix)){
            if( value.length > 10 ){
              value = updated[name].value
            }else {  value = value  }
          }
          else {  
            if(value.length ===0){
              value = '';
            }else {
              value = updated[name].value;
            }
           }
        }
        updated[name].value = value;
        this.setState({requestAForm:updated})
      }
    submitHandler = (event) => {
      $('footer .srv-validation-message').show();
        const notification = this.notificationSystem.current;
        event.preventDefault();
        const formData = {};

        for (let formElementIdentifier in this.state.requestAForm) {

            formData[formElementIdentifier] = this.state.requestAForm[formElementIdentifier].value;
        }
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            formData['country'] = this.state.country;
            formData['product'] = this.state.service;
            this.props.requestForDemo(formData).then((data) => {
                this.setState({ loading: false });
                 if(data.type ==='DEMO_REQUEST_SUCCESS'){
                  document.getElementById("requestDemoForm").reset();
                  for (let formElementIdentifier in this.state.requestAForm) {
                    this.state.requestAForm[formElementIdentifier].value = '';
                   }
                    notification.addNotification({
                        title: 'Thank You',
                        message: 'Your request has been submitted successfully',
                        level: 'success'
                    });
             
                      this.props.history.push('/thank-you', 'contactus' );
                 }else{
                     let error = data['error'].response
                     error = error['data'].errors
                     for (let formElementIdentifier in this.state.requestAForm) {
                     this.state.requestAForm[formElementIdentifier].value = '';
                    }
                    notification.addNotification({
                        title: error.message,
                        message: 'Please enter required details',
                        level: 'error'
                    });
                 }
                
            });

        }else {
            this.setState({ loading: false });
            this.validator.showMessages();
            this.forceUpdate();
      //       notification.addNotification({
      //        title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
      //        });
        }

        
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }
        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }
        return isValid;
    }

    UpdateCountry = (event)=> {
        const { name, value } = event.target;
        let {country} =  this.state
        if(name == 'country'){
          this.setState({country: value})
        }
      }
      UpdateService = (event)=> {
        const { name, value } = event.target;
        let {country} =  this.state
        if(name == 'service'){
          this.setState({service: value})
        }
      }

    inputChangedHandler = (event, inputIdentifier) => {
        const { value } = event.target;
        const numberRex = /^[0-9.\b]+$/;
        if(inputIdentifier == 'contact' && value.match(numberRex) == null && value !== ''){
            return false;
        }
        const updatedDemoForm = {
            ...this.state.requestAForm
        };
        const updatedFormElement = {
            ...updatedDemoForm[inputIdentifier]
        };
        updatedFormElement.value = value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedDemoForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifier in updatedDemoForm) {
            formIsValid = updatedDemoForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ requestAForm: updatedDemoForm, formIsValid: formIsValid });
    }

    render() {
      
        const {  countryData, ServicesData, requestAForm }  = this.state
        let FieldsArr = []; let i = 0;
    for( let formData in requestAForm ){
      FieldsArr[i] = { name: formData, value:requestAForm[formData].value, label:requestAForm[formData].label,
        type:requestAForm[formData].type, validation:requestAForm[formData].validation  }
        i++;
    }
        const formElementsArray = [];
        for (let key in this.state.requestAForm) {
            formElementsArray.push({
                id: key,
                config: this.state.requestAForm[key]
            });
        }

        let form = (
            <form  autocomplete="false"  autocomplete="off" onSubmit={this.submitHandler} id="requestDemoForm" >
                <div className="row row-cols-1 row-cols-sm-1">
                   
                    { FieldsArr.map( (field, index) =>{ 
                    let validationTxt= '';
                    if(field.name == 'email') { validationTxt='Email' }else { validationTxt = field.label }
                     return(
                    <div key={index} className="col col-sm-6">
                      <div className="form-label-group">
                      {/* {field.name == "contact" ? 
                        <InputMask type="tel" mask="9999999999" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={() => this.validator.showMessageFor(field.name)}
                       />
                        :
                        <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={() => this.validator.showMessageFor(field.name)}
                       />
                        } */}
                        <input type="text"  id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={() => this.validator.showMessageFor(field.name)}
                       />
                       {/* <label htmlFor={field.name}>{field.label}</label> ; */}
                       {this.validator.message(validationTxt, field.value, 'required|'+field.validation)}
                    </div>
                    </div>
                  ) }) }
            
                  
                    <div className="col col-sm-6">
                    <div className="form-group ">
                    <Form.Group  controlId="exampleForm.ControlSelect1">
    {/* <Form.Label>Example select</Form.Label> */}
    <Form.Control as="select" onChange={this.UpdateCountry} name="country">
      <option>Please select Country</option>
      {
                      countryData && countryData.map( (country, index)=>(
                        <option value={country.name}>{country.name}</option>
                      ) )
                    }
    </Form.Control>
    {this.validator.message('country', this.state.country, 'required')}
  </Form.Group>
  {/* {this.validator.message('country', this.state.country, 'required')} */}
                    {/* <select id="country" className="custom-select form-control" onChange={this.UpdateCountry} name="country">
                    <option value="">Please select Country</option>
                    {
                      countryData && countryData.map( (country, index)=>(
                        <option value={country.name}>{country.name}</option>
                      ) )
                    }
                  </select>
                  {this.validator.message('country', this.state.country, 'required')} */}
                  </div>
                  </div>
                  <div className="col col-sm-6">
                  <div className="form-group ">

                  <Form.Group  controlId="exampleForm.ControlSelect1">
    {/* <Form.Label>Example select</Form.Label> */}
    <Form.Control as="select"  onChange={this.UpdateService} name="service">
        <option selected={ !(this.state.product) ? true : false} value="">Please select a Service</option>
        {
                            ServicesData && ServicesData.map( (service, index)=>(
                            <option selected={ (this.state.product) ? true : false} key={index} value={service.id}>{service.full_name}</option>
                          ) )
                        }
    </Form.Control>
    {this.validator.message('service', this.state.service, 'required')}
  </Form.Group>


{/* 
                    <select id="service" className="custom-select form-control" onChange={this.UpdateService} name="service">
                    <option selected={ !(this.state.product) ? true : false} value="">Please select a Service</option>
                    {
                        ServicesData && ServicesData.map( (service, index)=>(
                        <option selected={ (this.state.product) ? true : false} key={index} value={service.id}>{service.full_name}</option>
                      ) )
                    }
                  </select> */}
                  {/* {this.validator.message('service', this.state.service, 'required')} */}
                  </div>
                  </div>
                    
                
                </div>
                <div className="row">
                <div className="col-12">
                { (this.state.loading) ? 
                  <Button type="submit" disabled className="btn bookButton btn-primary mt-3 mb-5"> Request Demo </Button>
                 :
                 <Button variant="primary"  type="submit"  className="bookButton mt-3 mb-5" >Request Demo </Button>
                //  <Button type="submit" className="btn bookButton btn-primary mt-3 mb-5"> Request Demo </Button>
                  }
                
                </div> 
                </div> 
            </form>
        )

        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                {/* {(this.state.loading) ? <Spinner /> : form} */}
                  {/* {(this.state.form) ? <Spinner /> : form} */}
                  {form}
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.reqDemo.isFetching,
        result: state.reqDemo.result,
        result: state.fetchDemoServices.result,
        countries: state.fetchDemoServices.countries,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestForDemoServices: data =>  dispatch(actionCreators.requsrtFetchServicesDemo()),
        requestForDemo: (data) => dispatch(actionCreators.requestForDetailsDemo(data)),
        requestForDemoServices: () => dispatch(actionCreators.requsrtFetchServicesDemo()),
        requestFetchCountry: data =>  dispatch(actionCreators.requestFetchCountry()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestDemo));