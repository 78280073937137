import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import ClientCarousel from "../ClientCarousel/ClientCarousel";

const Testimonial = (props) => {
  return (
    <section className="py-5 testimonial bg-secondry">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h2 className="font-weight-bold text-center text-dark">Client Speaks</h2>
          </div>
          <div className="col-md-8">
           <ClientCarousel items={props.testimonial.items} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;