import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import {Link } from "react-router-dom";
import BannerStyle from './bannerStyle.scss';
import {
    Card, CardBody,
    CardTitle
  } from 'reactstrap';


class CarouselItems extends Component{
   constructor(props){
       super(props);

   }

    render() {
        
        return (             
          <div > 
          <div className="visible-xs">
         {this.props.carouselData.map((item, index) => (
          <div className="py-2 px-1 mb-2 shadow border rounded">
            <div className="row align-items-center">
              <div className="col-4 pr-0">
                <img className="img-fluid" alt={item.title}    src={item.itemImage} />
              </div>
              <div className="col-8">
              <b> {item.title}</b>
              <p style={{fontSize:'12px'}} className="mb-1" >{item.description}</p>
              <Link className="" to={item.link}>
                                {item.linkText} <i className="fa fa-angle-right"></i>
                                </Link>
              </div>
            </div>

          </div>
          ))}
          </div> 
          <div className="hidden-xs">
          <OwlCarousel   ref="car" options={this.props.carouselOptions}  >
          {this.props.carouselData.map((item, index) => (
            

             
              <Card key={index} className="mb-4 " to={item.link}>
              <CardBody className="text-center">
            
                            
                                <img className="" alt={item.title} height="180"   src={item.itemImage} />
                                <CardTitle className="service_title">
                                   <b> {item.title}</b>
                                    </CardTitle>
                                <p style={{minHeight:'80px'}}>
                                {item.description}
                                </p>
                                <Link className="" to={item.link}>
                                {item.linkText} <i className="fa fa-angle-right"></i>
                                </Link>
                            </CardBody>
              </Card>
            ))}
                
            </OwlCarousel>
            </div>
            </div>   
            
         )
    }
}

export default CarouselItems;