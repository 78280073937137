import React from 'react';
import { Link, NavLink, withRouter} from 'react-router-dom';

import BreadCrumb from './../pages/BreadCrumb/BreadCrumb'
import Aux from './../pages/Wrapper/Auxiliary';

const UserComponent = (props)=> {
    if(localStorage.getItem('access_token')){
        // console.log(props, 'here')
    }else {
        props.history.push('/page')
    }
    
    return (
        <div>12121</div>
    )   
}

export default  withRouter(UserComponent)