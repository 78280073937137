import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Link, Route, Redirect } from 'react-router-dom';
import Home from './../pages/home/homeComponent';
import Login from './../login/login';
import Logout from './../login/logout';
import LogoutFromAdmin from './../login/logoutAdmin';
import GenerateNewPassword from './../login/generate-new-password';
import Services from './../pages/services/servicesComponent';
import AddToCart from '../pages/cart/add-to-cart';
import Payment from '../pages/cart/payment';
import Solutions from './../pages/solutions/solutionComponent';
import AboutUs from "./../pages/About/AboutUs";
import CareerPage from "./../pages/Career/Career";
import CompanyProfile from './../pages/company-profile/companyProfileComponent';
import Industries from './../pages/industries/industriesComponent';
import Support from './../pages/support/supportComponent';
import RequestADemoPage from './../pages/request-demo/requestDemoWrapper';
import UsefulLinks from './../pages/useful-links/usefulLinksComponent';
import OurTeam from './../pages/our-team/ourTeamComponent';
import HeaderComponent from './../common/header';
import FooterComponent from './../common/footer/footer';
import ServiceDetails from './../pages/services/service-details/serviceDetails'; 
import RequestTrial from './../pages/request-trial/request-trial';
import ContactUs  from './../pages/contact-us/contact-us';
import ForgetPassword from './../login/forget-password';
import ResetPassword from './../login/reset-password'
import ThankYouRequestTrial from './../pages/request-trial/thank-you';
import TermsConditions from './../pages/terms-conditions/terms-conditions';
import PrivacyPolicy from './../pages/terms-conditions/privacy-policy';
import PaymentHistory from './../profile/payment-history';
import PaymentSetting from './../profile/payment-setting';
import UserComponent from './../profile/index';
import RedirectNews from '../pages/news/news';
import Enforcement from "../pages/Enforcement/index";

import NtoFound from './../pages/not-found/error-404'
import comingsoon from '../pages/ComingSoon/comingsoon';
import softwaredefinedparcs from '../pages/ComingSoon/softwaredefinedparcs';
import testpay from '../pages/TestPay/testpay';
import AirportPark from '../pages/Airport';
class MainRoutes extends Component {
    constructor(props) {
        super(props);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
          this.setState({ prevPath: this.props.location })
        }
      }

    render() {
        // console.log(this.props, '2')
        return (
            <Router>
                <HeaderComponent></HeaderComponent>
                {this.props.children}
                <Switch>
                    <Route exact path="/" component={Home} ></Route>
                    {/* <Redirect from="/about-us" to="/" />
                    <Redirect from="/request-a-demo" to="/" /> */}
                    
                    <Route path="/about-us" component={AboutUs} ></Route>
                    <Route path="/services/:serviceName" component={ServiceDetails} ></Route>
                    <Route path="/services" component={Services} ></Route>
                    <Route path="/payment" component={AddToCart} ></Route>
                    <Route path="/make-Payment" component={Payment} ></Route>
                    
                    <Route path="/ParkNPlay/:id" component={ Enforcement }/>
                    <Route path="/ParkNPlay" component={ Enforcement }/>

                    <Route path={`/service-detail`} component={ServiceDetails} ></Route>
                    <Route path="/solutions" component={Solutions} ></Route>
                    <Route path="/our-team" component={OurTeam} ></Route>
                    <Route path="/company-profile" component={CompanyProfile} ></Route>
                    <Route path="/industries" component={Industries} ></Route>
                    <Route path="/support" component={Support} ></Route>
                    <Route path="/request-a-demo" component={RequestADemoPage} ></Route>
                    <Route path="/career" component={CareerPage} ></Route>
                    <Route path="/useful-links" component={UsefulLinks} ></Route>
                    <Route path="/privacy-policy" component={PrivacyPolicy} ></Route>
                    <Route path="/request-trial" component={RequestTrial} ></Route>
                    <Route path="/thank-you" component={ThankYouRequestTrial}></Route>
                    <Route path="/login" component={Login} ></Route>
                    <Route path="/forgot-password" component={ForgetPassword} ></Route>
                    <Route path="/reset-password/:token" component={ResetPassword} ></Route>
                    <Route path="/generate-new-password/:token" component={GenerateNewPassword} ></Route>
                    <Route path="/contact-us" component={ContactUs} ></Route>
                    <Route path="/terms-condition" component={TermsConditions} ></Route>
                    <Route path="/privacy-policy" component={PrivacyPolicy} ></Route>
                    <Route path="/news" component={RedirectNews} ></Route>
                    <Route path="/airport-parking" component={AirportPark} />
                    {/* <Route path="/payment-history" component={PaymentHistory} ></Route> */}
                    {/* <Route path="/user">
                        <UserComponent></UserComponent>
                        <Route path="/payment-history" component={PaymentHistory} ></Route>
                    </Route> */}
                    
                    <Route path="/logout" component={Logout} ></Route>
                    <Route path="/log-out" component={LogoutFromAdmin} ></Route>
                    <Route path="/software-defined-parcs" component={softwaredefinedparcs} ></Route>
                    <Route path="/whitelabel" component={comingsoon} ></Route>
                    <Route path="/testpay" component={testpay} ></Route>
                    {/* <Route component={Home} ></Route> */}
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
                <FooterComponent></FooterComponent>
            </Router>
        )
    }
}

export default MainRoutes