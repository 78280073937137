import { saveAs ,FileReader } from 'file-saver';
import $ from 'jquery';

import {
    PAYMENT_HISTORY_REQUEST,
    PAYMENT_HISTORY_SUCCESS,
    PAYMENT_HISTORY_FAIL,
    PAYMENT_INVOICE_REQUEST,
    PAYMENT_INVOICE_SUCCESS,
    PAYMENT_INVOICE_FAIL
} from "./../ActionTypes";
import axios from "../../config/axios";
import {ApiConfig} from  "../../config/ApiConfig";
import moment from "../../config/Moment";

  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const FetchPaymentHistory = (page = 1, search = '' )=> {
    return dispatch => {
    dispatch({ type: PAYMENT_HISTORY_REQUEST });
    let endpoint = `${ApiConfig.paymantHistory}`;
    // endpoint += `/page/${page}`;
    // if( search === '' ) search = 'NO';
    // endpoint += `?search=${search}`
    // console.log(endpoint, 'url')
       return axios.get(endpoint, config)
       .then( res => {
         return  dispatch({ type: PAYMENT_HISTORY_SUCCESS, data: res.data ,isFetching:false });
       }  )
       .catch ( err => {
         return dispatch ( {type: PAYMENT_HISTORY_FAIL,  isFetching: false } );
       } )
    }
  }

    export const FetchPaymentInvoive = (planId, type) => (dispatch) => {
        let date = new Date();
        let url = '';
        let fileName = planId+'_'+moment(date).format('MM_DD_YYYY') 
        if(type === 1){
             url = ApiConfig.partnerInvoice+ '/pdf/'+planId
        const headers = { 'Content-Type': 'application/pdf', 'Authorization': 'Bearer ' + token }
        fetch(url, { headers })
        .then(response => response)
        .then(data => data.blob() ).then( myBlob => { 
             saveAs(myBlob, fileName+".pdf");
            }    
        );
        }else {
             url = ApiConfig.partnerInvoice+ '/jpg/'+planId
        const headers = { 'Content-Type': 'application/pdf', 'Authorization': 'Bearer ' + token }
        fetch(url, { headers })
        .then(response => response)
        .then(data => data.blob() ).then( myBlob => { 
             let links = document.createElement('a');
            links.href = URL.createObjectURL(myBlob);
            $(links).attr('target', '_blank'); 
            links.click();
            }    
        );  
        }
        
    };