import React, { Component, PropTypes } from 'react';
import FontAwesome from 'react-fontawesome';
import SortByArrow, { ASCENDING } from './shortByArrow';
require("./AdminListTable.scss");

/**
 * This is the Admin list table element. Use this to display a collection of
 * admin-controlled resources on a table. It uses the following props:
 * @prop {array} data - the array of resource objects to map to the table rows
 * @prop {array} columns -
 *   an array of column objects, used to map headers and values (see below)
 * @prop {string} columns[].header -
 *   The string to use for the table header for this column
 * @prop {function} columns[].accessor -
 *   an accessor function to retrieve the data for each column. the current
 *   resource will be passed in as the first parameter
 * @prop {string} columns[].styles Optional. Object of styles to apply to this column
 * @prop {string} columns[].sortable Optional. Allow users to sort the table by this column
 * @prop {function} getNewRecords Optional.
 *   Function to get a new page of results. First argument should be page,
 *   if you implement search using showSearch second argument can be the search term
 * @prop {boolean} isFetching Optional. Whether or not records are currently being fetched.a
 * @prop {number} curPage Optional.
 *   The current page of results you are on
 * @prop {number} perPage Optional.
 *   Number of results to show per page
 * @prop {number} total Optional.
 * @param {boolean} condensed. Optional. Set to true to reduce the amount of padding in the table cells.
 * @prop {boolean} showSearch Optional. Show the search box for this data. Defaults to false.
 * @prop {function} onRowClick Optional. If given, this function will fire when the user clicks on a row.
 *       								 Should accept an object which will represent the data in that row
 *  Total number of records available. Used to calculate max page.
 * const exampleProps = {
 *   data: [
 *     {id: 1, firstName: 'Bart', lastName: 'Simpson', photo: {image_name: 'bart.jpg'}}
 *     {id: 2, firstName: 'Lisa', lastName: 'Simpson', photo: {image_name: 'lisa.png'}}
 *   ],
 *   columns: [
 *     {header: 'Image Url', accessor: d => d.photo.image_name},
 *     {header: 'Name', accessor: d => ${d.firstName} ${d.lastName}},
 *     {header: 'Edit', accessor: d => <Link to={/admin/users/${d.id}}>Edit</Link>}
 *  ]
 * };
 */
export default class AdminListTable extends Component {
    constructor() {
        super();

        this.state = {
            curPage: 1,
            search: '',
            sortBy: '',
            sortByIndex: false,
            sortByOrder: ASCENDING,
            isSearched:false
        };
    }

    componentWillReceiveProps = (nextProps) => {
        const maxPages = this.maxPages(nextProps);

        if (!maxPages) return this.setState({ curPage: 0 });

        if (nextProps.curPage > maxPages) {
            // Get first page if our current page is beyond search results
            this.setState({ curPage: 1 });
            this.props.getNewRecords(1, this.state.search);
            return;
        }

        if (nextProps.isFetching) return; // Prevents flash of previous page number

        this.setState({ curPage: nextProps.curPage });
    };

    onSortArrowClick = (colName, index, order) => {
        this.setState({
            sortBy: colName,
            sortByIndex: index,
            sortByOrder: order
        });
    };

    transformDataForSorting = (data) => {
        if (!data || typeof data !== 'string') return data;
        return data;
        // return parseInt(data.replace(/[^0-9]/g, ''), 10) || data;
    };

    sort = (data) => {
        if (!this.state.sortBy) return data;
        const accessor = this.props.columns[this.state.sortByIndex].accessor;
        return data.sort((a, b) => {
            const sortA = this.transformDataForSorting(accessor(a));
            const sortB = this.transformDataForSorting(accessor(b));
            if (this.state.sortByOrder === ASCENDING) return sortA < sortB ? -1 : 1;
            return sortA > sortB ? -1 : 1;
        });
    };

    /**
     * Get max pages in the current result set
     * @return number
     */
    maxPages = (props = false) => {
        if (!props) props = this.props;
        const { total, perPage } = props;
        return Math.ceil(total / perPage);
    };

    renderHeader = () => {
        const { columns, showHeader } = this.props;
        if (!showHeader) return false;
        return (
            <tr>
                {columns.map((col, i) => {
                    return (
                        <th key={i} style={col.styles ? col.styles : {}}>
                            {col.header}
                            {col.sortable && <SortByArrow
                                colName={col.header}
                                index={i}
                                order={this.state.sortByOrder}
                                selected={this.state.sortBy}
                                onClick={this.onSortArrowClick}
                            />}
                        </th>
                    );
                })}
            </tr>
        );
    }

    renderRow = (data, i) => {
        const { columns, onRowClick } = this.props;
        const rowClass = onRowClick ? 'clickable-row' : '';
        return (
            <tr key={i} onClick={() => onRowClick ? onRowClick(data) : () => {}} className={`${rowClass} admin-table-tr` }>
                {columns.map((col, j) => this.renderColumn(data, col, j))}
            </tr>
        );
    }

    renderColumn = (data, col, i) => {
        return (
            <td className='col-auto ' data-label={col.header} key={i} style={col.styles ? col.styles : {}}>
                <div className="">{col.accessor(data)}</div>
            </td>
        );
    }

    /**
     * Handle someone entering data into the input to jump to a specific page
     * @param  {[type]} page [description]
     * @return {[type]}      [description]
     */
    handlePageInput = (page) => {
        if (page > Math.ceil(this.props.total / this.props.perPage)) return;

        this.setState({ curPage: page });

        if (page < 1) return;

        this.props.getNewRecords(page, this.state.search);
    };

    /**
     * Update the current search term
     */
    updateSearchTerm = (event) => {
        this.setState({ search: event.target.value, isSearched:true });
    };

    /**
     * Handle a keydown event while we are in the search term box
     */
    handleSearchKeyDown = (event) => {
        if (event.keyCode !== 13) return;
        this.updateSearchTerm(event);
        this.props.getNewRecords(this.state.curPage, this.state.search);
    }

    /**
     * Show search bar for admin list table
     * Note that
     */
    renderSearch() {
        if (!this.props.showSearch) return null;
        const { getNewRecords } = this.props;
        const { curPage, search } = this.state;
        let searchVal = (this.state.isSearched)?search:this.props.search;
        return (
            <div className="admin-list-table-search">
                <input type="text" className="form-control" placeholder="Search here" value={searchVal} onChange={this.updateSearchTerm} onKeyDown={this.handleSearchKeyDown} />
                <FontAwesome className="admin-list-search-submit" name="search" size="lg" onClick={() => getNewRecords(curPage, searchVal)} />
            </div>
        );
    }

    renderPagination() {
        if (!this.props.getNewRecords) return null;

        const { getNewRecords, curPage } = this.props;
        const maxPages = this.maxPages();

        // Hide if we only have one page
        if (maxPages <= 1) return null;

        return (
            <div className="admin-table-pagination">
                {curPage < maxPages && <button className="fa fa-chevron-right fa-lg next-page" aria-hidden="true" onClick={() => getNewRecords(curPage + 1, this.state.search)}></button>}
                {curPage === maxPages && <button className="fa fa-chevron-right fa-lg next-page disabled" aria-hidden="true" disabled  onClick={() => getNewRecords(curPage + 1, this.state.search)}></button>}
                {curPage !== 1 && <button className="fa fa-chevron-left fa-lg prev-page" aria-hidden="true" onClick={() => getNewRecords(curPage - 1, this.state.search)}></button>}
                {curPage === 1 && <button className="fa fa-chevron-left fa-lg prev-page disabled" aria-hidden="true" disabled onClick={() => getNewRecords(curPage - 1, this.state.search)}></button>}
                <span className="current-page">
                    On page
                    <input
                        className="enter-page"
                        type="number"
                        value={curPage}
                        onChange={(event) => this.handlePageInput(event.target.value)}
                    />
                    of {maxPages}
                </span>
            </div>
        );
    }

    /**
     * Render message if there are no results available
     * @return {[type]} [description]
     */
    renderNoResults() {
        const message = this.state.search === '' ? 'No data available' :  'No results for that search.';
        return (
            <div className="no-results p-1 text-center mb-1">
            <p className="mb-0 alert alert-danger">{message}</p>
        </div>
        );
    }

    render() {
        const { data } = this.props;
        const tableClass = 'table-responsive';
        return (
            <div className="table-container">
                {(this.props.showSearch || this.props.getNewRecords) &&
                    <div className="table-header card-body clearfix">
                        {this.renderSearch()}
                        {this.renderPagination()}
                    </div>
                } 
                {data.length === 0 && this.renderNoResults()}
                <div className={tableClass}>
                <table className="highlight table mb-0 table-hover " style={{minWidth: '640px'}}>
                    {data.length !== 0 && <thead className="thead-light">{this.renderHeader()}</thead>}
                    <tbody className="">
                        {this.sort(data).map(this.renderRow)}
                    </tbody>
                </table>
                </div>
                {data.length !== 0 && (this.props.showSearch || this.props.getNewRecords) &&
                    <div className="table-footer card-footer" style={{ minHeight: '55px' }}>
                        {/* {this.renderSearch()} */}
                        {this.renderPagination()}
                    </div>
                }
            </div>
        );
    }
}

// AdminListTable.propTypes = {
//     columns: PropTypes.array,
//     data: PropTypes.array,
//     getNewRecords: PropTypes.func,
//     curPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     showSearch: PropTypes.bool,
//     onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//     condensed: PropTypes.bool,
//     showHeader: PropTypes.bool
// };

AdminListTable.defaultProps = {
    showSearch: false,
    showHeader: true
};
