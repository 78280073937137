import React from 'react';

const AddressSection =(props)=> {
    return (
        <div className="col-xs-12 col-md-12">
            <div className="card">
              <div className="card-block map-1">
                <h3 className="card-title">{props.location}</h3>
                <p className="card-text mb-1">{props.locationAddress}</p>
                <p className="card-text"><b>{props.contactType ? props.contactType + ':' : ''  }</b> {props.contactNumber}</p>
              </div>
            </div>
          </div>
    )
}

export default AddressSection;
