import React, { Component } from 'react';
// import LoadingMessage from 'Components/Global/Loading/LoadingMessage';
import { Container, Row, Col,Jumbotron, Button  } from 'reactstrap';


class Enforcement extends Component {
    
    constructor() {
        super();
        this.state = {
            
            showPartnerFilter:false
        }
        this.createFacility = false;
        
    }

    componentDidMount = () => {
        const id = this.props.match.params.id;
        if(id){
            window.location.assign(`https://checkin.parkengage.com/autogate-citation-payment-details/${id}`);
        }else{
            window.location.assign('https://checkin.parkengage.com/touchless-parking-autogate/eyJnYXRlIjoiMTEiLCJmYWNpbGl0eV9pZCI6MTg2fQ==');   
        }      
        
    };
    
    render() {
        
            return (
                <div className="content">
                    <div className="hero-img">
                <Jumbotron  fluid className=" bgImage">
                <Container>
                    <Row className="justify-content-center">
                    <Col className="text-center">
                            <h1 className="text-uppercase text-dark">The Next Generation of<br/><span>Touchless Parking</span></h1>
                            <p className="  text-dark font-weight-bold ">The exclusive digital platform for the parking industry.</p>
                            <div className="text-center  hero-baanner">
                                
                            </div>
                        </Col>
                    </Row>
                    </Container>
                    
                </Jumbotron>
                
                </div>
                    
                </div>
            );
        
    }
}


export default Enforcement;
