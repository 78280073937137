import React from "react";
import { connect } from "react-redux";
import Aux from "../Wrapper/Auxiliary";
import {EncryptionHandler} from './../../config/encryption';
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import * as actionCreators from "../../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import monthlyPayment from "./assets/monthly-payment.png";
import inventory from "./assets/inventory.png";
import mobileBarcode from "./assets/mobile-barcode.png";
import mobileValet from "./assets/mobile-valet.png";
import promotion from './assets/Promotion.png';
import SimpleReactValidator from 'simple-react-validator';
import Input from "./../../Global/Input/Input";
import InputMask from 'react-input-mask';

import creditCard from './assets/credit-card.png';
import creditCardIcon from './assets/credit-card-icon.svg';
import infoGrayImg from './assets/info-gray.svg';
import $ from 'jquery';

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { Form, Button , Tooltip, OverlayTrigger} from 'react-bootstrap';
const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #ced4da',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#4A90E2', 0.25)} 0 0 0 2px`,
      borderColor: '#4A90E2',
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

function renderTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
   Your 3 Digit CVV Number 
    </Tooltip>
  );
}



class RequestTrial extends React.Component {
  ServicesData = {};
  countryData = '';
  servicesImg =[ 
    promotion,inventory,monthlyPayment,mobileBarcode,mobileValet
  ]
  
   selectedServiceId = '';
   planSelected = false;
   submittedFormData = {};

    dateMask = '99/99';
    cardMask = '9999 9999 9999 9999';
    security_codeMask = '9999';
    phoneMask = '(999) 9999-999';

  constructor(props) {
    super(props);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        placement: 'top',
        html: true
      })
    })
    this.CardToolTip = 'Please enter your card number';
    this.validator = new SimpleReactValidator();
    this.notificationSystem = React.createRef();
    this.state = {
      showPayment: false,
      service_plan_id: {
        value: '',
        name: 'service_plan_id',
        valid: false,
      },
      membership_plan_id: {
        value: '',
        name: 'membership_plan_id',
        amount: '',
        is_trial: 0,
        valid: false,
      },
      requestTrialForm: {
        first_name: {
            value: '',
            name: 'first_name',
            type:'text',
            label: 'First Name',
            validation:'required'
          },
          last_name: {
            value: '',
            name: 'last_name',
            type:'text',
            label: 'Last Name',
            validation:'required'
          },
          email: {
            value: '',
            name: 'email',
            type:'text',
            label: 'Email Id',
            validation:'required|email'
          },
         
          phone: {
            value: '',
            name: 'phone',
            type:'text',
            label: 'Contact Number',
            pattern:'\d*',
            validation:'required'
          },
          company_name: {
            value: '',
            name: 'company_name',
            type:'text',
            label: 'Company Name',
            validation:'required'
          },
          city: {
            value: '',
            name: 'city',
            type:'text',
            label: 'City',
            validation:'required'
          },
          
      },
      selectedPlan: {
        name: '',
        amount: '',
        no_of_days:'',
        no_of_users: '',
        is_trial: '',
        id: '',
        valid: false
      },
      country: '',
      plansSelected: [],
      servicesImg: this.servicesImg,
      formIsValid: false,
      loading: false,
      agreeForTerms: false,
      paymentsFields: {
        name_on_card: {
          name: 'name_on_card',
          value: '',
          error: '',
          placeholder: 'Name on Card',
          valid: false
        },
        card_number: {
          name: 'card_number',
          value: '',
          error: '',
          placeholder: 'Card Number',
          valid: false
        },
        expiration_date: {
          name: 'expiration_date',
          value: '',
          error: '',
          placeholder: 'Expiration Date',
          valid: false

        },
        security_code: {
          name: 'security_code',
          value: '',
          error: '',
          placeholder: 'Security Code',
          valid: false
        },
        zip_code: {
          name: 'zip_code',
          value: '',
          error: '',
          placeholder: 'Zip Code',
          valid: false
        }
      }
    };

  }

  componentWillMount() {
    this.props.requestForDemoServices().then(() => {
      if(this.props['result']) {
        this.ServicesData = this.props.result.data;
      this.setState({ ServicesData: this.ServicesData });
      }
    });
    
    this.props.requestFetchCountry().then(() => {
      if(this.props['countries']) {
        this.countryData = this.props.countries.data;
        this.setState({ countryData: this.countryData });
      }
    });
  }


  SelectServiceHandler = (event, planData)=> {
    //console.log(event.target)
    let {service_plan_id, plansSelected, ServicesData} =  this.state
     const { name, value, checked } = event.target;
    let serviceId = planData.id;
    let planId = planData.value;
     if(checked){
      plansSelected.push({id:serviceId,planValue:planId })
     }else {
       let index = plansSelected.findIndex(x => {
         return x.id === serviceId
        });
        plansSelected.splice(index, 1);
     }
     this.setState({plansSelected:plansSelected})
  }
  
   InputHandler = (event) => {
    let { name, value } = event.target;
    this.validator.showMessageFor(name);
    let updated = { ... this.state.requestTrialForm}
    let phoneRegix = /^[\d]+$/g;
    if(name ==='phone'){
      if(value.match(phoneRegix)){
        if( value.length > 10 ){
          value = updated[name].value
        }else {  value = value  }
      }
      else {  
        if(value.length ===0){
          value = '';
        }else {
          value = updated[name].value;
        }
       }
    }
    updated[name].value = value;
    this.setState({requestTrialForm:updated})
  }

  changeCardFields = (event)=> {
    let { name, value } = event.target;
    let updated = { ... this.state.paymentsFields}
    let error = '';
    let isValid = false;

    if(name !== "name_on_card"){
      updated[name].value = value.trim();
    }else { updated[name].value = value }
    updated[name].error = error;
    updated[name].valid = isValid;
    this.setState({paymentsFields:updated});
  }

  UpdateCountry = (event)=> {
    const { name, value } = event.target;
    let {country} =  this.state
    if(name == 'country'){
      this.setState({country: value})
    }
  }

  formSubmitHandler = (event)=> {
   
    event.preventDefault();
    
    const notification = this.notificationSystem.current;
    if(!this.state.plansSelected.length>0){ 
      $('html, body').animate({
        scrollTop: $("#selectProductDemoCard").offset().top
        }, 500); 
        this.validator.showMessages();
        this.forceUpdate();
      notification.addNotification({ title: 'Validation Error!',  message: 'Please select a Service!', level: 'error' });
    return false
    }
    if (this.validator.allValid()) {
      let formData = {};
      let {requestTrialForm, plansSelected, country, ServicesData, paymentsFields } = this.state;
      for (let field in requestTrialForm) {
        formData[field] = requestTrialForm[field].value;
      }
      if(this.state.plansSelected.length>0){
        let PlansArr = {};
         plansSelected.map( ( item, index ) =>{
          PlansArr[item.id] = item.planValue.toString()
         } )
        formData['membership'] = PlansArr;
        formData['country'] = country;
        formData['no_of_days'] = '15';
        formData['plan_type'] = '0';
        formData['is_trial'] = '1';
        formData['payment_type']= "credit_card";
        formData['savePaymentMethod']=  1;
        let cardData = {};
          for (let field in paymentsFields) {
            cardData[field] = paymentsFields[field].value;
          }
          formData['nonce'] = EncryptionHandler(cardData);
          
        this.setState({ loading: true });
        this.props.requestForTrial(formData).then((data) => {
          if(data['error']){
            if(data.error.response.status == 422){
             let errorData = data.error.response
             let errorDataMsg = errorData.data.errors
             let errorTitle =errorDataMsg.message
             let errorMsgs = errorDataMsg.detail;
             if(errorMsgs){
              for(let field in errorMsgs ) {
                let error =  errorMsgs[field][0]; 
                notification.addNotification({
                  title: errorTitle+'!',
                  message: error,
                  level: 'error'
              });
              }
             }else {
              notification.addNotification({
                title: errorTitle+'!',
                message: 'Invalid Data',
                level: 'error'
            });
             }
              
              
            this.setState({ loading: false });
            return false;
            }else {
              let errorMessage = '';
              let errorData = data.error.response
              let errorMsg =  '';
              if(errorData.data['data']){
                errorMsg =  errorData.data.data
                console.log(errorMsg)
                errorMsg = errorMsg.errors
              }else {
                errorMsg =  errorData.data.errors
                console.log(errorMsg)
                errorMsg = errorMsg.message
              }
              notification.addNotification({
                title: 'Validation Errors!',
                message: errorMsg,
                level: 'error'
            });
            }
            
          }else if( data['data'] ){
            notification.addNotification({
              title: 'Thank You',
              message: 'Your request has been submitted successfully, We will get in touch with you soon',
              level: 'success'
          });
         let Res = data.data
          let access_token = '';
            let token = Res.data.token;
            access_token = token.access_token 
            formData['allServices'] = ServicesData
           // localStorage.setItem('subscribeToken', access_token);
          
          this.props.history.push('/thank-you', "Trial" );
          }else {
            notification.addNotification({
              title: 'Sorry, Something went Wrong!',
              message: 'Please Try again letter!',
              level: 'error'
          });
          }
          this.setState({ loading: false });
        });
      } else {
        if(!this.state.plansSelected.length>0){ 
          notification.addNotification({  title: 'Validation Error!',  message: 'Please select a Service!', level: 'error' });
        }
         
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      // notification.addNotification({
      //   title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
      // });
    }
  }

  
  ChangePlanHandler = (plan)=> {
    //this.planSelected = true;
     let {selectedPlan} = this.state;
      selectedPlan['name'] =  plan.name;
      selectedPlan['no_of_days'] =  plan.no_of_days;
      selectedPlan['no_of_users'] =  plan.no_of_users;
      selectedPlan['amount'] =  plan.amount;
      selectedPlan['id'] = plan.id;
      selectedPlan['is_trial'] = plan.is_trial
      selectedPlan['valid'] = true;
      this.setState({ selectedPlan: selectedPlan });
  }

  checkAgreeStatus = (event)=> {
    let {agreeForTerms} = this.state;
    const { name, value, checked } = event.target;
    if(checked){
      agreeForTerms = true;
     }else {
      agreeForTerms = false
     }
    this.setState({ agreeForTerms: agreeForTerms });
  }

  render() {
    this.validator.purgeFields();
    let srvData = "";
    const {requestTrialForm, loading, countryData, selectedPlan, agreeForTerms, showPayment, paymentsFields} = this.state;
    let selectedServiceId = this.selectedServiceId;
    let FieldsArr = []; let i = 0;
    for( let formData in requestTrialForm ){
      FieldsArr[i] = { name: formData, value:requestTrialForm[formData].value, label:requestTrialForm[formData].label,
        type:requestTrialForm[formData].type, validation:requestTrialForm[formData].validation  }
        i++;
    }
    if (this.state.ServicesData) {
      
      srvData = (
           <>
           { this.state.ServicesData.map( ( service, index ) => {
             if( service.id == 4 ) return ( false )
             let TrialPlanId = '';
             let disable = true
             for( let plan of service.membership_plan ){
               if(plan.is_trial === '1'){
                TrialPlanId = plan.id;
               }
             }
             
             if( (service.id == 3) || (service.id == 2) ){
              disable = true
             }else {
              disable = false
             }
              return (
        <div key={index} id={` service-${service.id} `} className="col mb-4" >
          <div className="card " style={{height:'100%'}} >
            <div className="card-body p-2">
              <div className="row align-items-center">
                  <div className="col text-center col-auto">
                    <img alt="choose business plan" className="image-fluid" style={{width:'100px'}} src={this.state.servicesImg[index]} />
                  </div>
                  <div className="col">
                      <h4>{ (service.id == 5) ? 'Promotions' : service.full_name}</h4>
                      <p className="mb-2 " style={{fontSize: '14px', lineHeight:'1.2'}}>
                        You can choose business plan suits for you 
                      </p>
                  </div>
                  <div className="col-auto">
                    <div className="radio-active">
                        <input style={ disable ? { pointerEvent:'none', display:'none'} : {}} 
                          type="checkbox"
                            name={service.full_name}
                            value={service.id}
                            id="radio-one" onClick={(e)=>this.SelectServiceHandler(e,{id:service.id, value: TrialPlanId})}
                            className="form-radio"
                          />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
           ) } )
           }
            
        </>    
      );
    }

    let FormFields = this.state.requestTrialForm;
    return (
      <Aux>
      <NotificationSystem ref={this.notificationSystem} />
        <BreadCrumb
          headerTitle={`Request Trial`}  headerName={"Welcome to ParkEngage"}  headerContent={
            "Your one stop for all your parking solutions need" }
        />
        <section id="content-section" id="selectProductDemoCard">
          <div className="container">
            <div className="text-center my-3">
              <h2 className="text-dark font-weight-light">
                To get started you need to share your details with us
              </h2>
            </div>
          </div>
          {!showPayment &&
          <div className="register-form pt-2 pb-3">
            <div className="container">
              <form name="requestTrialForm" onSubmit={this.formSubmitHandler} >
                <div className="select-product-demo-card">
                  <h4 className="text-center  text-dark mb-3">Select product for Trial</h4>
                  <div className=" m-auto">
                    <div className=" row justify-content-center">
                      <div className="col-12 col-sm-10">
                          {!srvData && 
                            <div class="col-12 w-100">
                              <p class="loading alert alert-primary text-center">Loading Services... <i class="fas fa-circle-notch fa-spin"></i></p>
                            </div>
                          }
                          <div className="mg-rows row row-cols-1 row-cols-md-2">
                            {srvData}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="request-trial-form border-top mt-4">
                  <h3 className="text-center text-dark mt-4 mb-5">Fill your informations</h3>
                  <div className="row row-cols-1 row-cols-md-3 row-cols-sm-2">
                  { FieldsArr.map( (field, index) =>{ 
                    let validationTxt= '';
                    if(field.name == 'email') { validationTxt='Email' }else { validationTxt = field.label }
                     return(
                    <div key={index} className="col  mb-2">
                      <div className="form-label-group">
                      {field.name == "phone" ? 
                        <InputMask type="tel" mask="9999999999" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        :
                        <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        } 
                        {/* <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                       <label htmlFor={field.name}>{field.label}</label> */}
                       {this.validator.message(validationTxt, field.value, field.validation)}
                    </div>
                    </div>
                  ) }) }
                  <div className="col  mb-2">
                    <div className="form-group ">
                         {/* <label>Select Country:</label>  */}
                       <Form.Group controlId="country">
                          <Form.Control as="select"onChange={this.UpdateCountry} name="country">
                            <option value="">Please select Country</option>
                              {
                                countryData && countryData.map( (country, index)=>(
                                  <option value={country.name}>{country.name}</option>
                                ) )
                              }
                          </Form.Control>
                          {this.validator.message('country', this.state.country, 'required')}
                        </Form.Group>

                                        {/* <select id="country" className="custom-select" onChange={this.UpdateCountry} name="country">
                                        <option value="">Please select Country</option>
                                        {
                                          countryData && countryData.map( (country, index)=>(
                                            <option value={country.name}>{country.name}</option>
                                          ) )
                                        }
                                      </select>
                        {this.validator.message('country', this.state.country, 'required')} */}
                      </div>
                  </div>
                  </div>
                    
                        
                        

                  <div className="request-trial-form cart-payment ">
              <div className="light-box p-4">
              <div className="row">
              <div className="col-md-8 col-lg-9 mb-4">
                <div className="">
                  <label className=""><b>Payment Selection</b></label>
                  <p>Safe money transfer using your bank account. Discover, Maestro, Visa, American Express.</p>
                </div>
              </div>
              <div className="form-group col-md-4 col-lg-3 mb-4 text-left text-lg-center">
                <img className="payment-card" alt="credit-card-image" src={creditCard}/>
              </div>
              <div className="form-group col-md-6 mb-4">
                <label>NAME ON CARD</label>
                <input placeholder="Enter your Name on Card" type="text" name={paymentsFields.name_on_card.name} value={paymentsFields.name_on_card.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                 {this.validator.message('Name on Card', paymentsFields.name_on_card.value, 'required|min:3')}
              </div>
              <div className="form-group col-md-6 mb-4">
                <label htmlFor="cardnumber">CARD NUMBER</label>
                <InputMask placeholder="Enter your Card Number" type="tel" mask={this.cardMask} alwaysShowMask="false" maskChar={null} className="form-control" name={paymentsFields.card_number.name} value={paymentsFields.card_number.value}  onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                <span className="float-right info-input" data-toggle="tooltip" data-html="true" data-placement="top" title="Enter your Credit card number">
                  <img src= {creditCardIcon} alt="credit-card-icon" />
                 </span>
                     
                 {this.validator.message('Card Number', paymentsFields.card_number.value, 'required|card_num')}
              </div>
              
              <div className="form-group col-md-3 mb-4">
                <label>EXPIRY DATE</label>
                <InputMask type="tel" name={paymentsFields.expiration_date.name} alwaysShowMask="false" mask={this.dateMask} maskChar={null} value={paymentsFields.expiration_date.value} className="form-control" placeholder="MM/YY" onChange={this.changeCardFields} onBlur={this.changeCardFields}/>
                
                 {this.validator.message('expiry date ', paymentsFields.expiration_date.value, 'required|card_exp')}
              </div>
              <div className="form-group col-md-3 mb-4">
                <label>CVV CODE</label>
                <InputMask placeholder="XXXX" type="tel" alwaysShowMask="false" mask={this.security_codeMask} maskChar={null} name={paymentsFields.security_code.name} value={paymentsFields.security_code.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                <span className="float-right info-input" data-toggle="tooltip" data-placement="top" title="CVV code of Credit Card">
                  <img src={infoGrayImg} alt="icon" />
                 </span>
                
                 {this.validator.message('cvv code', paymentsFields.security_code.value, 'required|integer|min:3')}
              </div>
              <div className="form-group col-md-3 mb-4">
                <label>Zip Code</label>
                <InputMask placeholder="Zipcode" type="tel" name={paymentsFields.zip_code.name} alwaysShowMask="false" mask="999999" maskChar={null} value={paymentsFields.zip_code.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields}/>
                
                 {this.validator.message('Zipcode ', paymentsFields.zip_code.value, 'required|integer|min:5')}
              </div>
              </div>
            
                          {/* <div className="my-4 pb-4">           
                      { loading &&  
                        <button style={{background: '#4A90E2', color: '#fff'}} type="submit" className="btn btn-primary btn-outline-primary float-left float-md-right disabled">Please Wait... <i class="fas fa-circle-notch fa-spin"></i></button>
                      }{!loading &&
                        <button onClick={this.SubmitPaymentForm} type="submit" className="btn btn-primary btn-outline-primary float-left float-md-right">Complete Order <i className="la la-angle-right"></i></button>
                      
                        }
                      </div> */}
                    </div>
                  </div>

                  <div className="form-group mt-4 ">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" onChange={this.checkAgreeStatus} />
                    <label class="custom-control-label" for="customCheck1">I agree with <a target="blank" href="/terms-condition" >Terms &amp; Condition</a> and <a target="blank" href="/privacy-policy" >Privacy Policy</a></label>
                  </div>
                    
                    </div>
                    <div className="form-group ">
                    {!loading && 
                    <button disabled={!agreeForTerms} type="submit" className="btn btn-primary btn-outline-primary" >Complete Order <i className="la la-angle-right"></i> </button>
                    }
                    {loading && 
                    <a style={{background: '#4A90E2'}} disabled className="disabled text-light btn btn-primary btn-outline-primary">Complete Order <i className="la la-angle-right"></i></a>
                    }
                    </div>
                </div>
                      
                   
                
                </form>
            </div>
          </div>
        }
        </section>
        
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.fetchDemoServices.isFetching,
    result: state.fetchDemoServices.result,
    countries: state.fetchDemoServices.countries,
    response: state.trialRequest.result
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestForDemoServices: data =>  dispatch(actionCreators.requsrtFetchServicesDemo()),
    requestFetchCountry: data =>  dispatch(actionCreators.requestFetchCountry()),
    requestForTrial: (data) => dispatch(actionCreators.requestForTrial(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTrial);
