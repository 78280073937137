import * as actionTypes from "../ActionTypes";
import axios from "../../config/axios";

export const fetchContentStart = () => {
  return {
    type: actionTypes.CONTENT_REQUEST
  };
};

export const fetchContentSuccess = (orders) => {
  return {
    type: actionTypes.CONTENT_REQUEST_SUCCESS,
    orders: orders
  };
};

export const fetchContentFail = (error) => {
  return {
    type: actionTypes.CONTENT_REQUEST_FAIL,
    error: error
  };
};

export const getConent = () => {
  return dispatch => {
    dispatch(fetchContentStart());
    axios.get('https://jsonplaceholder.typicode.com/posts')
      .then(res => {
        const fetchedOrders = [];
        for (let key in res.data) {
          fetchedOrders.push({
            ...res.data[key],
            id: key
          });
        }
        dispatch(fetchContentSuccess(fetchedOrders));
      })
      .catch(err => {
        dispatch(fetchContentFail(err));
      });
  };
}