import * as actionTypes from './../ActionTypes';
import  Axios  from './../../config/axios';
import { ForgetPasswordSuccess, ForgetPasswordFail } from './ForgetPasswordRequest';

export const ContactFormRequest = ()=> {
    return {
        type: actionTypes.CONTACT_FORM_REQUEST
    }
}

export const ContactFormSuccess = (res)=> {
    return {
        type : actionTypes.CONTACT_FORM_SUCCESS,
        data : res
    }
}
export const ContactFormFail = (error)=> {
    return {
        type : actionTypes.CONTENT_REQUEST_FAIL,
        data: error.response
        
    }
}

export const ContactFormRequestService = (data)=> { 
    data['client_id'] = "inventory-demo";
    data['client_secret']= "Wqhqdz9PMKMHWUG9pZ0Oowvrz";
    data['grant_type'] = "password";   
    return dispatch => {
        dispatch(ContactFormRequest());
        return Axios.post('/save-contact-us', data).
        then( res => { 
            return dispatch( ContactFormSuccess(res) )
         } ).
         catch ( error => {
             return dispatch(ContactFormSuccess(error))
         } )
    } 
}

export const subscriberRequest = (data)=> { 
    return dispatch => {
        dispatch({ type: actionTypes.SUBSCRIBER_FORM_REQUEST });
        return Axios.post('/newsletter', data).
        then( res => { 
            return dispatch({ type: actionTypes.SUBSCRIBER_FORM_SUCCESS, data : res.data })
        }).
        catch ( error => {
             return dispatch({ type: actionTypes.SUBSCRIBER_FORM_FAIL, data: error.response })
        })
    } 
}

export default ContactFormRequestService;