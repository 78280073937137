import React, { Component, PropTypes } from 'react';
import FontAwesome from 'react-fontawesome';

import './SortByArrow.scss';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export default class SortByArrow extends Component {
    reverseOrder = (order) => {
        return order === ASCENDING ? DESCENDING : ASCENDING;
    };

    render() {
        return <FontAwesome
            className={`sort-by-arrow ${this.props.selected === this.props.colName && 'selected'}`}
            name={this.props.order === 'asc' ? 'caret-up' : 'caret-down'}
            onClick={() => this.props.onClick(
                this.props.colName,
                this.props.index,
                this.reverseOrder(this.props.order)
            )}
        />;
    }

}

// SortByArrow.propTypes = {
//     selected: PropTypes.string,
//     index: PropTypes.number,
//     colName: PropTypes.string,
//     order: PropTypes.string,
//     onClick: PropTypes.func
// };
