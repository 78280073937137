import React, { Component } from 'react';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import ServiceDescription from './serviceDescription';
import ServiceFeatures from './ServiceFeatures';
import HowItWork from './HowItWork';
import Aux from '../../Wrapper/Auxiliary';
import * as actionCreators from "../../../Redux/Actions/index";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import NotificationSystem from "react-notification-system";
import $ from 'jquery';
import monthlyPayment from "./../assets/monthly-payment.png";
import inventory from "./../assets/inventory.png";
import mobileBarcode from "./../assets/mobile-barcode.png";
import mobileValet from "./../assets/mobile-valet.png";
import promotion from "./../assets/Promotion.png";
import monthlyParking from "./../assets/monthlyparking.png";
import touchless from "./../assets/ticket-less-parking-system.jpg";
import parkingInventory from "./../assets/parking-inventory-system.jpg";
import onlineReservation from "./../assets/online-reservation-poster.jpg";

import ServicesImage3 from './../assets/book-yellow.png';

import ServicesImageACMC from './../assets/tvp.png';
import ServicesImagePRLO from './../assets/PRLO.jpeg';
import ServicesImageALPR from './../assets/anpr.png';
import ServicesImageARM from './../assets/arm.png';
import ServicesImageRAD from './../assets/rad.png';
import ServicesImageWLA from './../assets/wla.png';


import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';

class ServiceDetails extends Component {
    serviceId = '';
    servicesImg =[
      promotion,mobileValet,monthlyPayment, mobileBarcode, inventory
      ]
      isLoggedIn = false;
      activeMonthly = true;
      enableActive = false;
    constructor(props){
        super(props)
        if(localStorage.getItem('access_token')){
          this.isLoggedIn = true;
        }else {
          this.isLoggedIn = false;
        }
       this.validator = new SimpleReactValidator();
        this.notificationSystem = React.createRef();
        let location = props.history.location
        let currentServiceId = location.state
        this.state= {
            hideBuyButton : true,
            hideUserForm: true,
            serviceId: '',
            serviceData: this.serviceData,
            CurrentPath: this.props.match.params.serviceName,
            userSelectedPlan : {
                amount: '',
                type: '',
                days: '',
                is_trial: '',
                plan_id: '',
                service_id: '',
            },
            country: '',
            loading: false,
            agreeForTerms: false,

            requestTrialForm: {
                first_name: {
                    value: '',
                    name: 'first_name',
                    type:'text',
                    label: 'First Name',
                    validation:'required'
                  },
                  last_name: {
                    value: '',
                    name: 'last_name',
                    type:'text',
                    label: 'Last Name',
                    validation:'required'
                  },
                  email: {
                    value: '',
                    name: 'email',
                    type:'text',
                    label: 'Email Id',
                    validation:'email'
                  },
                 
                  phone: {
                    value: '',
                    name: 'phone',
                    type:'text',
                    label: 'Contact Number',
                    validation:'integer|min:10'
                  },
                  company_name: {
                    value: '',
                    name: 'company_name',
                    type:'text',
                    label: 'Company Name',
                    validation:'required'
                  },
                  city: {
                    value: '',
                    name: 'city',
                    type:'text',
                    label: 'City',
                    validation:'required'
                  },
                  
              },
        }
    let serviceId = this.state.serviceId
    if(this.state.CurrentPath === 'parking-inventory-management-system'){
        this.serviceId = 1;
    } else if(this.state.CurrentPath === 'monthly-parking-system'){
        this.serviceId = 2;
     } else if(this.state.CurrentPath === 'touchless-parking-system'){
        this.serviceId = 3;
     } else if(this.state.CurrentPath === 'mobile-valet-system'){
        this.serviceId = 4;
     } else if(this.state.CurrentPath === 'promotion'){
        this.serviceId = 5;
    
      } else if(this.state.CurrentPath === 'aggregator-and-channel-management'){
        this.serviceId = 6;
     
    } else if(this.state.CurrentPath === 'promotions-and-loyalty'){
      this.serviceId = 7;
   
    }
    else if(this.state.CurrentPath === 'alpr-based-parking-enforcement'){
      this.serviceId = 8;
    }
    // } else if(this.state.CurrentPath === 'accounts-receivable-management'){
    //   this.serviceId = 9;
   
    // }
    else if(this.state.CurrentPath === 'reportin-analytics-dashboards'){
      this.serviceId = 10;
   
    } if(this.state.CurrentPath === 'white-label-apps'){
    this.serviceId = 11;
   
  }
  this.setState({serviceId: this.serviceId})
}

  serviceData =[
    {   pageSlug:'parking-inventory-management-system',
        serviceSlogen: '',
        serviceTitle: 'Aggregator and Channel Management',
        serviceDesc: `Gain control of your parking inventory. Embrace the strengths of aggregators and channel partners and resell your parking inventory on your own terms.`,
         serviceImage: parkingInventory,
         shortDesc: `
         Publish and manage your own reservable <span class="text-primary font-weight-bold">parking inventory for all</span> channel partners in one platform `,
         serviceFeatures: [
             'Maintain control over your parking assets.',
             'Generate new revenue streams.',
             'Consolidate channel partner management.',
             'Maximize revenues through dynamic pricing.',
             'Maintain competitive advantage through market pricing.'
            ],
            HowItWork: [
              'Reach a wider market and expand your partner ecosystem.',
              'Continue to work with parking aggregators to publish available parking spaces.',
              'Partner with nearby businesses (hotels, restaurants, etc.) to promote your inventory through their channels.',
              'Get real-time oversight and control of your parking inventory.',
              'Gain better negotiating power.'
             ]
    },
    {    pageSlug:'monthly-parking-system',
        serviceSlogen: '',
        serviceTitle: 'Touchless Monthly Parking',
        serviceDesc: `Touchless Monthly Parking allows operators to create a more pleasant and convenient parking experience for their monthly parkers, while enabling staff to provide enhanced service.`,
         serviceImage: monthlyParking,
         shortDesc: 'Our Monthly Parking solution provides a  <span class="text-primary font-weight-bold">seamless and comfortable experience</span> for monthly parkers, while <span class="text-success font-weight-bold">creating and encouraging brand loyalty</span>.',
        
         serviceFeatures: [
         'Increase safety and social distancing.',
         'Touchless and paperless.',
         'Easy payment methods.',
         'No upfront investments, Pay as you use.',
         'Self-service solution for monthly account holders.',
         'Quick and error-free parking enforcement.',
         'No hang tags, stickers, proximity cards.',
        //  'Lower cost - No need for expensive proximity cards, RFID tags, or hangtags.',
        //  'Easy “Get My Car” feature enables customer to schedule or change pickup time.'
         ],
         HowItWork: [
          'Online monthly parking signups, online payments',
          'Convenient account self-service.',
          'Accounts Receivable management.',
          'Automatic invoices and auto-charges.',
          'Convenient recurring monthly payments.',
          'Manage multiple cars per family or per business.',
          'Use touchless parking to check-in check-out.',
          // 'No need to issue proximity cards or RFID tags to allow entry into gated garages.',
          // 'Parking officers verify permits using mobile ALPR from their vehicle while driving.'
         ]
    },
    { pageSlug:'touchless-parking-system',
        serviceSlogen: '',
        serviceTitle: 'Touchless Self-Parking',
        serviceDesc: `Meet the needs and expectations of your highly mobile customer base and create a completely touchless parking experience. `,
         serviceImage: touchless,
         shortDesc: `With our Touchless Parking Service, create a <span class="text-primary font-weight-bold">seamless parking experience</span> for customers in valet and self-park facilities.`,
         serviceFeatures: [
       'Mobile check-in and check-out without an app.',
       'Integrate with any gate system.',
       'Reduce or remove wait times at exits or valet.',
       'Eliminate paper tickets.',
       'Efficient and convenient mobile payment options.',
       'Option to integrate add-on services.',
       'Personalized customer service.',
          
         ],
         HowItWork: [
          'Customers arrive and scan a QR code or open their branded app. ',
          'This will generate a customer record and enable them to check into the facility. ',
          'Customer receives a mobile alert with their eTicket and proceeds to park. ',
          'Upon returning, customer opens app or SMS eTicket to initiate mobile payment.',
          'Customer departs facility following a truly touchless parking experience!',
          
         ]
    },
    { pageSlug:'online-reservations',
        serviceSlogen: '',
        serviceTitle: 'Online Parking Reservations',
        serviceDesc: `Enhance the parking experience by allowing parkers to reserve space in your parking facility ahead of time, while incorporating comprehensive touchless parking solutions.
        `,
         serviceImage: onlineReservation,
         shortDesc: `Integrate with<span class="text-success font-weight-bold"> real-time inventory,</span> <span class="text-primary font-weight-bold">manage pricing, incorporate targeted promotions,</span> and much more. 

         `,
         serviceFeatures: [
           
             'Effectively manage real-time pricing and availability.',
             'Enhance customer loyalty.',
            'Maximize parking revenues.',
            'Create a seamless experience for customers.',
            'Reduce potential headaches and offer peace of mine.',
            'Accept multiple payment options.'

         ],
         HowItWork: [
          'Specify pricing structure in the pricing engine.',
          'Integrate with your real-time inventory to maximize sales.',
          'Offer touchless parking promotions and discounts.',
          'Enable dynamic pricing.',
          'Manage specific parking facility information.',
          'Incorporate through your website or mobile app.' 
         ]
    },
    { pageSlug:'promotion',
        serviceSlogen: '',
        serviceTitle: 'Promotions and Loyalty',
        serviceDesc: `Attract new customers with comprehensive and integrated marketing initiatives. Our Promotions and Loyalty service helps you stay ahead of your competition and create brand loyalty with long-term, repeat customers.`,
         serviceImage: promotion,
         shortDesc: `Provide convenient, touchless parking and create brand loyalty through our <span class="text-primary font-weight-bold">Promotion and Loyalty Service</span>.`,
         serviceFeatures: [
             'Allow customers to apply coupons toward payments.',
             'Implement custom or randomly generated discounts.',
             'Schedule and promote timely discounts and benefits.',
             'Apply discounts or benefits to specific locations.',
             'Loyal customers stay engaged and keep returning back'

         ],
         HowItWork: [
           'Customers earn loyalty points for parking and other services',
           'Customers redeem loyalty points for rewards',
          'Create personalized promotion codes for your customers. ',
          'Utilize analytics to understand customer behavior and target specific groups.',
          'Tailor and launch targeted promotions quickly.',
          'Develop and distribute direct email campaigns.',
          'Generate comprehensive campaign analytics to understand ROI.'
         ]
    },
    { pageSlug:'valet-parking-system',
      serviceSlogen: '',
      serviceTitle: 'Touchless Valet Parking',
      serviceDesc: `This web-based and mobile-based service enables your valet parking facility to quickly deploy touchless parking capabilities.`,
       serviceImage: ServicesImageACMC,
       shortDesc: 'Our Touchless Valet Service creates a convenient experience for parkers, while <span class="text-primary font-weight-bold">streamlining your valet staff operation</span>.',
       
       serviceFeatures: [
           'Mobile check-in and check-out without an app.',
           'Eliminate paper tickets.',
           'Reduce or remove wait times.',
           'Convenient mobile payment options.',
           'Option to integrate add-on services',
           'Integrates with existing operation.',
           'Personalized customer service.'

          ],
       HowItWork: [
        'Customers arrive at facility and check-in.',
        'Requests for any add-on services',
        'e-ticket is sent to the customer.',
        'Valet inspects the vehicle, attaches vehicle condition report.',
        'Customer opens e-ticket to pay and notifies valet of his ETA.',
        'Upon arrival valet delivers vehicle to the customer.'

       ]
  },
  { pageSlug:'parking-enforcement-system',
      serviceSlogen: '',
      serviceTitle: 'ALPR Based Parking Enforcement',
      serviceDesc: `Now more than ever parking owners and operators are seeking solutions that come low to no upfront capital investments. Automated License Plate Recognition (ALPR)-based enforcement solutions reduce these costs while helping to increase capture rates, revenues, and more.`,
       serviceImage: ServicesImageALPR,
       shortDesc:'ALPR-based solution allows <span class="text-primary font-weight-bold">Parking Operators and Owners</span> to implement more accurate parking enforcement procedures, increasing revenues and <span class="text-success primary font-weight-bold">streamlining operations</span>.',
       
       serviceFeatures: [
           'No upfront capital investment.',
           'Pay-as-you-go payment option.',
           'Highly accurate capture rate.',
           'Simple set up and installation.',
           'Increases compliance rates.',
           'Increases revenue generation.',
           'Streamlines enforcement procedures.'
          
          
          ],
       HowItWork: [
        'Enforcement officer uses mobile phone to scan license plates.',
        'Sophisticated pattern recognition and permit matching algorithms.',
        'System notifies officer of whether vehicle payment is up to date.',
        'Officer can obtain all necessary permit data to make informed decision.',
        'Officer can print violation tickets.'
       ]
  },
  // { pageSlug:'parking-AR-system',
  //     serviceSlogen: '',
  //     serviceTitle: 'Accounts Receivable (A/R) Management',
  //     serviceDesc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
  //     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
  //     quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.`,
  //      serviceImage: ServicesImageARM,
  //      shortDesc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor <span class="text-primary font-weight-bold"> dolore magna aliqua. </span>`,
       
  //      serviceFeatures: [
  //          'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //          'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //          'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //          'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'],
  //      HowItWork: [
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  //      ]
  // },
  { pageSlug:'analytics-system',
      serviceSlogen: '',
      serviceTitle: 'Reporting and Analytics Dashboards',
      serviceDesc: `Reporting and Analytics Dashboards provide path from data to decisions. System offers the key performance indicators for the parking industry, tools used to analyze the data, identifying patterns in the data, and the strategies for applying this analysis to the decision making.`,
       serviceImage: ServicesImageRAD,
       shortDesc:
       'Reporting and Analytics Dashboards for <span class="text-primary font-weight-bold">Parking Operators and Owners</span> to <span class="text-success font-weight-bold">understand customer behavior, evaluate markets, optimize operations, increase revenue and profit margins</span>.',
      
       
       serviceFeatures: [
           'Parking industry specific KPI.',
           'Analytics to help create a more efficient parking operation.',
           'Decisions for all aspects of business – Sales, Marketing, HR, Operations etc.',
           'Pay-as-you-go payment option.',
           'Maintain a real-time view of operations at your fingertips.'
          ],
       HowItWork: [
        'Data is collected from all functions of business.',
        'Easily customizable canned out-of-box reports and Dashboards.',
        'Well-defined set of metrics and data analysis is applied. ',
        'Parking industry KPIs for Sales, Marketing, HR, Operations.',
        'Comprehensive data collection framework.',
        'Aggregation with market data.',
        'Easy to use tools to slice and dice data.',
        'Works with ParkEngage cloud.'
    

       ]
  },
  { pageSlug:'permit-parking-system',
      serviceSlogen: '',
      serviceTitle: 'Touchless Permit Parking',
      serviceDesc: `Touchless Permit Parking enables you to implement a custom and personalized solution to make their parking experience as pleasant as possible.`,
       serviceImage: ServicesImage3,
       shortDesc: 'Permit customers are your most loyal and profitable base. Provide personalized service with our <span class="text-primary font-weight-bold"> Touchless Permit Parking Solution</span>.',
  
       
       serviceFeatures: [
           'Touchless and paperless.',
           'Easy payment methods.',
           'No upfront investments, pay as you use.',
           'Self-service solution for permit holders.',
           'Quick and error-free permit verification.',
           'No hang tags, permit stickers, proximity cards.'
          ],
       HowItWork: [
        'Permit holder buys permit online.',
        'Permit holder receives e-Permit on mobile phone.',
        'Permits can be set to auto renew if desired.',
        'Customer pays online or via student bill, payroll etc.',
        'Parking officers verify permits using mobile ALPR.',
        'Register multiple vehicles on single permit.'
       ]
  },
  { pageSlug:'white-label-apps',
      serviceSlogen: '',
      serviceTitle: 'White Label Apps',
      serviceDesc: `White label parking apps by ParkEngage are fully functional customer apps that can be easily customized and branded, to offer all the parking services offered by the parking operators and parking facility owners. These Apps easily integrate and leverage existing garage infrastructure.`,
       serviceImage: ServicesImageWLA,
       shortDesc: 
       'White Label Apps for the <span class="text-primary font-weight-bold"> Parking Operators and Parking Facilities</span> Owners who would like to offer a feature rich Parking App to their customers to get <span class="text-success font-weight-bold">personalized parking experience</span>.',
      
       serviceFeatures: [
           'No upfront capital investment to develop the Apps.',
           'Pay-as-you-go payment option.',
           'Simple customization, rebranding and integration.',
           'Comprehensive app usage analytics.',
           'Agile rollout of new features.',
          ],
       HowItWork: [
        'Apps are customized to offer the services provided by the operators',
        'Apps are integrated with existing PARCS and backend systems.',
        'Works with ParkEngage cloud.',
        'Offered for single-facility or multi-facility parking operations.',
        'Customers download Branded Apps from the Apple and Google App stores.',
        'Features like search, reserve, check-in, check-out, pay and many more.',
        'Features like targeted marketing, customer service, promotions and loyalty.',
       
       ]
  }
]
  
    componentWillMount() {
        this.props.requestForDemoServices().then(() => {
          if(this.props['result']) {
            this.ServicesData = this.props.result.data;

           if(this.ServicesData){
               for( let service of this.ServicesData ){
                if(service.id ===  this.serviceId){
                    this.setState({ currentService: service });
                }
               }
           }
          }
        });

        this.props.requestFetchCountry().then(() => {
            if(this.props['countries']) {
              this.countryData = this.props.countries.data;
              this.setState({ countryData: this.countryData });
            }
          });
      }

      InputHandler = (event) => {
        let { name, value } = event.target;
        this.validator.showMessageFor(name);
        let updated = { ... this.state.requestTrialForm}
        let nameRegix = /^[A-Za-z\s]*$/;
        let phoneRegix = /^[\d]+$/g;
      
        if(name ==='phone'){
          if(value.match(phoneRegix)){
            if( value.length > 10 ){
              value = updated[name].value
            }else {  value = value  }
          }
          else {  
            if(value.length ===0){
              value = '';
            }else {
              value = updated[name].value;
            }
           }
        }
        updated[name].value = value;
        this.setState({requestTrialForm:updated})
      }

      UpdateCountry = (event)=> {
        const { name, value } = event.target;
        let {country} =  this.state
        if(name == 'country'){
          this.setState({country: value})
        }
      }
      BuyServicePlanByLoggedInUser = ()=>{
          if(localStorage.getItem('access_token') && localStorage.getItem('refresh_token') ){
            this.setState({hideUserForm: true})
            let formData= [];
            let {userSelectedPlan } = this.state;
            //let serviceImgId = userSelectedPlan.service_id;
                         // serviceImgId = parseInt(serviceImgId) -1;
                          formData['membership_plan_id'] = userSelectedPlan.plan_id;
                          formData['allServices'] =  this.ServicesData;       
                          formData['type'] = userSelectedPlan.type;
                          formData['days'] = userSelectedPlan.days;
                          formData['amount'] =  userSelectedPlan.amount;
                          formData['is_trial'] =  userSelectedPlan.is_trial;
                          formData['service_plan_id'] = userSelectedPlan.service_id;
                          //formData['servicesImg'] = this.servicesImg[serviceImgId];
                          formData['existing_membership_plan_id'] = userSelectedPlan.plan_id;
                          formData['token'] = localStorage.getItem('access_token');
                          this.props.history.push('/payment', formData);

          }else {
            this.setState({hideUserForm: false})
          }
          
      }
      ChangePlanType = (type)=>{ 
        this.validator.hideMessages()
        this.enableActive = false
        this.setState({hideBuyButton: true, hideUserForm: true})
        if(type == 1){
            this.activeMonthly = true
        }else {
            this.activeMonthly = false
        }
      }
      selectedPlanForBuy = (plan, type)=> {
        $('#customCheck1').prop("checked", false);
        this.setState({ agreeForTerms: false });
        this.validator.hideMessages()
        this.enableActive = true
          let { userSelectedPlan } = this.state;
        if(type == 'montly'){
            userSelectedPlan['amount']= plan.amount
            userSelectedPlan['type']= '1'
            userSelectedPlan['days']= '30';
            userSelectedPlan['name']= plan.name
        }
        else {
            userSelectedPlan['type']= '2'
            userSelectedPlan['days']= '365'
            userSelectedPlan['amount']= plan.annual_amount;
            userSelectedPlan['name']= plan.name;
        }
        userSelectedPlan[ 'plan_id'] = plan.id
        userSelectedPlan['service_id']= plan.service_id;
        userSelectedPlan['is_trial']= plan.is_trial
        if(this.isLoggedIn ){
            this.setState({userSelectedPlan: userSelectedPlan, hideBuyButton: false, hideUserForm:true});
        }else {
          this.setState({userSelectedPlan: userSelectedPlan, hideBuyButton: false, hideUserForm:false});
        }
        }

        checkAgreeStatus = (event)=> {
            let {agreeForTerms} = this.state;
            const { name, value, checked } = event.target;
            if(checked){
              agreeForTerms = true;
             }else {
              agreeForTerms = false
             }
            this.setState({ agreeForTerms: agreeForTerms });
          }

          formSubmitHandler = (event)=> {
            event.preventDefault();
            const notification = this.notificationSystem.current;
            if (this.validator.allValid()) {
                let formData = {};
                let {requestTrialForm,  country, userSelectedPlan } = this.state;
                    for (let field in requestTrialForm) {
                        formData[field] = requestTrialForm[field].value;
                    }
                    formData['country'] = country;
                    formData['membership_plan_id'] = userSelectedPlan.plan_id;
                    formData['service_id'] = userSelectedPlan.service_id;
                    formData['is_trial'] =  userSelectedPlan.is_trial;
                    formData['plan_type'] = userSelectedPlan.type;
                    formData['no_of_days'] =  userSelectedPlan.days;
                    this.setState({ loading: true });
                    this.props.requestForTrial(formData).then((data) => {
                        if(data['error']){
                          if(data.error.response.status == 422){
                           let errorData = data.error.response
                           let errorDataMsg = errorData.data.errors
                           let errorTitle =errorDataMsg.message
                           let errorMsgs = errorDataMsg.detail;
                            for(let field in errorMsgs ) {
                              let error =  errorMsgs[field][0]; 
                              notification.addNotification({
                                title: errorTitle+'!',
                                message: error,
                                level: 'error'
                            });
                            }
                          this.setState({ loading: false });
                          return false;
                          }else {
                            
                            let errorData = data.error.response
                            errorData = errorData.data
                            
                            if(errorData['errors']){
                                let errormsg = errorData.errors;
                                errormsg = errormsg['message'];
                                notification.addNotification({
                                      title: 'Validation Errors!',
                                      message: errormsg,
                                      level: 'error'
                                  });
                            }else {
                                let errormsg = errorData.data;
                                errormsg = errormsg['errors'];
                                notification.addNotification({
                                      title: 'Validation Errors!',
                                      message: errormsg,
                                      level: 'error'
                                  });
                            
                            }
                          
                          this.setState({ loading: false });
                          }
                          
                        }else if( data['data'] ){
                          notification.addNotification({
                            title: 'Thank You',
                            message: 'Your request has been submitted successfully, We will get in touch with you soon',
                            level: 'success'
                        });
                       let Res = data.data
                        let access_token = '';
                          let token = Res.data.token;
                          let planAssigned = Res.data.membership_plan
                          access_token = token.access_token 
                         ///let serviceImgId = userSelectedPlan.service_id;
                          //serviceImgId = parseInt(serviceImgId) -1;
                          formData['allServices'] =  this.ServicesData;       
                          formData['type'] = userSelectedPlan.type;
                          formData['days'] = userSelectedPlan.days;
                          formData['amount'] =  userSelectedPlan.amount;
                          formData['is_trial'] =  userSelectedPlan.is_trial;
                          formData['service_plan_id'] = userSelectedPlan.service_id;
                         // formData['servicesImg'] = this.servicesImg[serviceImgId];
                          formData['token'] = access_token;
                          formData['existing_membership_plan_id']  = planAssigned.id
                          this.props.history.push('/payment', formData);

                        }else {
                          notification.addNotification({
                            title: 'Sorry, Something went Wrong!',
                            message: 'Please Try again letter!',
                            level: 'error'
                        });
                        }

                    });

          }else {
            this.validator.showMessages();
            this.forceUpdate();
            // notification.addNotification({
            //   title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
            // });
          }
        }
      
    render() {
        const {currentService, serviceId, requestTrialForm, loading, countryData, agreeForTerms, userSelectedPlan} = this.state
      
        let FieldsArr = []; let i = 0;
    for( let formData in requestTrialForm ){
      FieldsArr[i] = { name: formData, value:requestTrialForm[formData].value, label:requestTrialForm[formData].label,
        type:requestTrialForm[formData].type, validation:requestTrialForm[formData].validation  }
        i++;
    }

        let PlansList = '';
        if(currentService && currentService['membership_plan']){
            PlansList = currentService['membership_plan']
        }
        return (
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <div>
                        { this.state.serviceData.map( ( service, index )=> (
                            <div key={index}>
                            
                        {this.state.CurrentPath == service.pageSlug &&
                            <>
                            <BreadCrumb headerTitle={`Products > ${service.serviceTitle}`} headerName={service.serviceTitle} headerContent={service.serviceSlogen} />
                            <section id="" className="mt-5 mb-3">
                        <Container>
                            <Row className="text-center justify-content-center">
                                <Col sm="auto">
                                  
                                <h2 className="text-dark font-light mb-0" dangerouslySetInnerHTML={ { __html:service.shortDesc}}></h2>

                                </Col>

                            </Row>
                                
                        </Container>
                </section>
             
                            { service.serviceDesc ? 
                            
                            
                            
                            <ServiceDescription title={service.serviceTitle} content={service.serviceDesc} image={service.serviceImage} ></ServiceDescription>
                            : null
                            }
                            { service.HowItWork ?
                                <HowItWork title={service.serviceTitle} features={service.HowItWork} ></HowItWork> 
                             : null }
                            { service.serviceFeatures ?
                                <ServiceFeatures features={service.serviceFeatures} ></ServiceFeatures> 
                             : null }
                            </>
                        }
                                  
                        </div>
                    ) ) }
                      <section className="py-5 mb-0 bg-secondry">
                            <div className="container">
                            <div class="text-center mb-3"><h2 class="text-dark  ">Pricing</h2>
                            <p class="mb-0 pb-0"><a href="mailto:info@parkengage.com">Contact us</a> to learn about specific pricing levels for your organization.</p></div>
                            {/* <div className="row justify-content-center">
                                <div className="col-auto mb-5 mt-4 ">
                                {PlansList &&
                                    <div className="btn-group customeTabDesign" >
                                        <div class="nav nav-pills mb-3" id="nav-tab" role="tablist">
                                            <a onClick={()=>this.ChangePlanType(1)} class="nav-item nav-link active" id="monthly-tab" data-toggle="tab" href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">Monthly</a>
                                            <a onClick={()=>this.ChangePlanType(2)} class="nav-item nav-link" id="yearly-tab" data-toggle="tab" href="#yearly" role="tab" aria-controls="yearly" aria-selected="false">Annually</a>
                                        </div>
                                    </div>
                                }{!PlansList &&
                                  <p class="loading alert alert-primary text-center">Loading Plans... <i class="fas fa-circle-notch fa-spin"></i></p>
                                }
                                </div>
                            </div> */}

                            <div class="tab-content customeTabDesignContent d-none" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">

                                <div className="row no-gutters align-items-center justify-content-center">
                                {PlansList && PlansList.map(( plan, index ) =>{
                                    if(plan.is_trial !== '1' && plan.status == '1' ){
                                        let active = '';
                                        if( userSelectedPlan.plan_id === plan.id && (this.enableActive) ){  active = 'active'  }else { active = ''; }
                                        let amount = parseInt(plan.amount);
                                        if(amount === 0) return null
                                return (
                                    <div className="col-12 col-sm-auto">
                                        <div className={`card shadow bg-white ${active} `}>
                                            <p className="text-center">
                                                <strong>{plan.name} </strong>
                                            </p>
                                            <h1 className="text-center"><small>$</small>
                                            { amount }
                                            <small>/mo</small></h1>
                                            <div className="clearfix my-3">
                                                <ul className="PriceBox">
                                                    <li>
                                                        <p><b>
                                                        30 Days</b> Validity</p>
                                                    </li>
                                                    <li>
                                                    <p> {plan.no_of_users} Registered Users</p>
                                                    </li>
                                                    <li>
                                                        <p>24 X 7 Technical Support</p>
                                                    </li>
                                                </ul>
                                           </div>
                                          {/* <button type="button" disabled className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>  */}

                                       { (this.isLoggedIn )
                                           ? 
                                           <button type="button" onClick={()=>this.selectedPlanForBuy(plan, 'montly')} className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>
                                           :
                                           <button type="button" onClick={()=>this.selectedPlanForBuy(plan, 'montly')} className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>
                                           }

                                        </div>
                                    </div>
                                )}
                            }
                            ) 
                            }                                   
                                </div>
                            </div>
                            <div class="tab-pane fade" id="yearly" role="tabpanel" aria-labelledby="yearly-tab">
                            <div className="row no-gutters align-items-center justify-content-center">
                            {PlansList && PlansList.map(( plan, index ) =>{
                                    if(plan.is_trial !== '1' && plan.status == '1' ){
                                        let active = '';
                                        if( userSelectedPlan.plan_id === plan.id && (this.enableActive) ){  active = 'active'  }else { active = ''; }
                                        let annual_amount = parseInt(plan.annual_amount);
                                        if(annual_amount === 0) return null
                                return (
                                    <div className="col-12 col-sm-auto">
                                        <div className={`card shadow bg-white ${active} `} >
                                            <p className="text-center">
                                             <strong>{plan.name}</strong>
                                            </p>
                                            <h1 className="text-center"><small>$</small>{annual_amount}<small>/Year</small></h1>
                                            <div className="clearfix my-3">
                                                <ul className="PriceBox">
                                                    <li>
                                                        <p><b>1 Year</b> Validity</p>
                                                    </li>
                                                    <li>
                                                        <p> {plan.no_of_users} Registered Users</p>
                                                    </li>
                                                    <li>
                                                        <p>24 X 7 Technical Support</p>
                                                    </li>
                                                </ul>
                                           </div>
                                           {/* <button type="button" disabled className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>                         */}
                                           
                                           { (this.isLoggedIn )
                                           ? 
                                           <button type="button" onClick={()=>this.selectedPlanForBuy(plan, 'yealy')} className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>                                   
                                            :
                                           <button type="button" onClick={()=>this.selectedPlanForBuy(plan, 'yealy')} className="btn text-uppercase btn-outline-secondary btn-block">Buy Plan</button>
                                           }
                                        </div>
                                    </div>
                                    )}
                            }
                            ) 
                            }   
                                    
                                    
                                </div>


                            </div>
                            </div>
                                           
                           <div className="clearfix mt-5 mb-0 text-center">
                            { !this.state.hideBuyButton &&  
                               <p>You have selected <b>{userSelectedPlan.name} plan</b>  Valid for<b> {userSelectedPlan.days} days</b>   <a role="button" onClick={this.BuyServicePlanByLoggedInUser} className="btn btn-primary ml-2 btn-fill text-white btn-sm">Confirm &amp; Pay</a> </p>
                            }
                           </div>
                            </div>
                      </section>  
                         



{ !this.state.hideUserForm && 
    <section id="content-section">
          <div className="container">
            <div className="text-center my-3">
              <h2 className="text-dark font-weight-light">
                To get started you need to share your details with us
              </h2>
            </div>
          </div>

          <div className="register-form pt-2 pb-3">
            <div className="container">
              <form name="requestTrialForm" onSubmit={this.formSubmitHandler} >
                <div className="request-trial-form border-top mt-4">
                  <h3 className="text-center text-dark mt-4 mb-5">
                    Fill your informations
                  </h3>
                  <div className="row row-cols-1 row-cols-md-3 row-cols-sm-2">
                  { FieldsArr.map( (field, index) =>{ 
                    let validationTxt= '';
                    if(field.name == 'email') { validationTxt='Email' }else { validationTxt = field.label }
                     return(
                    <div key={index} className="col  mb-2">
                      <div className="form-label-group">
                      {field.name == "phone" ? 
                        <InputMask type="tel" mask="9999999999" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        :
                        <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        }
                       {/* <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       /> */}
                        {/* <RedditTextField
                        id={field.name}
                        name={field.name}
                        placeholder={field.label}

                            onChange={this.InputHandler} onBlur={this.InputHandler}
                            label={field.label}
                            value={field.value} 
                            fullWidth
                            variant="filled"
                            defaultValue={field.value} 
                         
                           /> */}
                    
                       {this.validator.message(validationTxt, field.value, 'required|'+field.validation)}
                    </div>
                    </div>
                  ) }) }
                  <div className="col  mb-2">
                    <div className="form-group ">

                    <TextField
                          id="standard-select-ccoulgry"
                          select
                          label="Select"
                          className="form-control"
                          variant="outlined"
                        
                          onChange={this.UpdateCountry} name="country"
                        >

{
                      countryData && countryData.map( (country, index)=>(
                        <MenuItem value={country.name}>{country.name}</MenuItem>
                      ) )
                    }
                          
                        </TextField>


                    {/* <select id="country" className="custom-select" onChange={this.UpdateCountry} name="country">
                    <option value="">Please select Country</option>
                    {
                      countryData && countryData.map( (country, index)=>(
                        <option value={country.name}>{country.name}</option>
                      ) )
                    }
                  </select> */}
 {this.validator.message('country', this.state.country, 'required')}
                  </div>
                  </div>

                  </div>
                  <div className="form-group  ">
                  <div class="custom-control custom-checkbox">
  <input type="checkbox" class="custom-control-input" id="customCheck1" onChange={this.checkAgreeStatus} />
  <label class="custom-control-label" for="customCheck1">I agree with <a target="blank" href="/terms-condition" >Terms &amp; Condition</a> and <a target="blank" href="/privacy-policy" >Privacy Policy</a></label>
</div>

            
                    
                    </div>
                    <div className="form-group ">
                    {!loading && 
                    <button disabled={!agreeForTerms} type="submit" className="btn btn-primary btn-outline-primary" >Submit and Proceed </button>
                    }
                    {loading && 
                    <a style={{background: '#4A90E2'}} className="disabled text-light btn btn-primary btn-outline-primary">Submit and Proceed </a>
                    }
                    </div>
                </div>
              </form>
            </div>
          </div>
        </section>
    }



                         </div>
        </Aux>
        )
    }

}

const mapStateToProps = state => {
    return {
      isFetching: state.fetchDemoServices.isFetching,
      result: state.fetchDemoServices.result,
      countries: state.fetchDemoServices.countries,
      response: state.trialRequest.result
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      requestForDemoServices: data =>  dispatch(actionCreators.requsrtFetchServicesDemo()),
      requestFetchCountry: data =>  dispatch(actionCreators.requestFetchCountry()),
      requestForTrial: (data) => dispatch(actionCreators.requestForTrial(data)),
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
