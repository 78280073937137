import React from 'react';
import classes from './Spinner.css';

const LoadingIcon = ()=>{
    return (
        <div className="container">
            <div className="text-center my-3">
             <div class="col-12 w-100">
              <p class="loading alert alert-primary text-center">Loading... <i class="fas fa-circle-notch fa-spin"></i></p>
             </div>
            </div>
        </div>
    )
}

export default LoadingIcon