import React from 'react';


const headerTitleDescription = ()=> {
    return (
        <div class="row justify-content-center">
            <div class="text-center my-5 col-md-10">
            <h4 class="text-dark font-light">
                We are here to help you with any question regarding our products and services.
                Fill out the form below to ask a question, schedule a demo, or just receive more information.</h4>
            </div>
        </div>
    )
}
export default headerTitleDescription;