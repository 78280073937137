import React, { Component } from 'react';

import demoImg from "./Assets/demo-lt.png";
import RequestDemo from './requestDemoComponent';


class RequestADemoPage extends Component {

    render(){
        return (
            <>
                <div className="demo-section-top">
                    <div className="jumbotron jumbotron-fluid reqDemoBg"></div>
                </div>
            <div className="demo-sub-box">
                    <div className="container">
                        <div className="demo">
                            <div className="row no-gutters">
                                <div className="col-lg-5 d-none d-lg-block">
                                    <div className="demo-img-lt">
                                        <img alt="demo image" src={demoImg} />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="request-trial-form px-md-5 px-sm-4 px-3 mx-4  mx-sm-0 mx-md-0">
                                        <h2 className="text-uppercase text-dark mt-4 pt-4 mb-2">Request demo</h2>
                                        <p className="text-dark mb-4" style={{fontWeight:'300'}}>
                                            
                                        ParkEngage has developed a <span className="text-primary font-weight-bold">cloud-based digital platform </span> incorporating an extensive set of services, designed to give parking owners and operators the tools to maintain control of their <span className="text-primary font-weight-bold">parking assets, increase revenues, and enhance customer service.</span> Contact us to schedule a demo and learn more about how we can improve your operation. 

                                          </p>
                                        <RequestDemo></RequestDemo>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default RequestADemoPage;