export const CONTENT_REQUEST = 'CONTENT_REQUEST';
export const CONTENT_REQUEST_SUCCESS = 'CONTENT_REQUEST_SUCCESS';
export const CONTENT_REQUEST_FAIL = 'CONTENT_REQUEST_FAIL';
export const DEMO_REQUEST = 'DEMO_REQUEST';
export const DEMO_REQUEST_SUCCESS = 'DEMO_REQUEST_SUCCESS';
export const DEMO_REQUEST_FAIL = 'DEMO_REQUEST_FAIL';

export const DEMO_DATA_REQUEST = 'DEMO_DATA_REQUEST';
export const DEMO_DATA_REQUEST_SUCCESS = 'DEMO_DATA_REQUEST_SUCCESS';
export const DEMO_DATA_REQUEST_FAIL = 'DEMO_DATA_REQUEST_FAIL';

export const TRIAL_REQUEST = 'TRIAL_REQUEST';
export const TRIAL_REQUEST_SUCCESS = 'TRIAL_REQUEST_SUCCESS';
export const TRIAL_REQUEST_FAIL = 'TRIAL_REQUEST_FAIL';

export const PAID_PLAN_REQUEST = 'PAID_PLAN_REQUEST';
export const PAID_PLAN_REQUEST_SUCCESS = 'PAID_PLAN_REQUEST_SUCCESS';
export const PAID_PLAN_REQUEST_FAIL = 'PAID_PLAN_REQUEST_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAIL = 'LOGIN_REQUEST_FAIL';

export const GENERATE_PASSWORD_REQUEST = 'GENERATE_PASSWORD_REQUEST';
export const GENERATE_PASSWORD_REQUEST_SUCCESS = 'GENERATE_PASSWORD_REQUEST_SUCCESS';
export const GENERATE_PASSWORD_REQUEST_FAIL = 'GENERATE_PASSWORD_REQUEST_FAIL'; 

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST';
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS';
export const CONTACT_FORM_FAIL = 'CONTACT_FORM_FAIL';

export const FETCH_COUNTRY_REQUEST = 'FETCH_COUNTRY_REQUEST';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAIL = 'FETCH_COUNTRY_FAIL';

export const PAYMENT_HISTORY_REQUEST = 'PAYMENT_HISTORY_REQUEST';
export const PAYMENT_HISTORY_SUCCESS = 'PAYMENT_HISTORY_SUCCESS';
export const PAYMENT_HISTORY_FAIL = 'PAYMENT_HISTORY_FAIL';

export const PAYMENT_INVOICE_REQUEST = 'PAYMENT_INVOICE_REQUEST';
export const PAYMENT_INVOICE_SUCCESS = 'PAYMENT_INVOICE_SUCCESS';
export const PAYMENT_INVOICE_FAIL = 'PAYMENT_INVOICE_FAIL';

export const SUBSCRIBER_FORM_REQUEST = 'SUBSCRIBER_FORM_REQUEST';
export const SUBSCRIBER_FORM_SUCCESS = 'SUBSCRIBER_FORM_SUCCESS';
export const SUBSCRIBER_FORM_FAIL = 'SUBSCRIBER_FORM_FAIL';