import * as actionTypes from "../ActionTypes";
import { updateObject } from '../utility';

const initialState = {
  isFetching: true,
  data: []
}

const ContentReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.DEMO_REQUEST:
      return updateObject(state,{});
    case actionTypes.DEMO_REQUEST_SUCCESS:
      return updateObject(state, {data: action.data, isFetching:false});
    case actionTypes.DEMO_REQUEST_FAIL:
      return updateObject(state, {isFetching:false});
    default:
      return state;
  }
}

export default ContentReducer;