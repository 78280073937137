import React , { Component } from 'react';

export default class CompanyProfileComponent extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <h1>Company profile</h1>
        )
    }
}