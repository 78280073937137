import * as actionTypes from './../ActionTypes';
import {updateObject} from './../utility';


const initialState = {
    isFetching: false,
    result: {},
}

const LoginRequestReducer = ( state= initialState, action )=> {

    switch(action.type){
        // case actionTypes.LOGIN_REQUEST : 
        //     return updateObject( state );
        case actionTypes.LOGIN_REQUEST_SUCCESS : 
            return updateObject( state, { result: action.data } );
        case actionTypes.LOGIN_REQUEST_FAIL : 
            return updateObject( state, { result: action.data} );
        case actionTypes.LOGOUT_REQUEST : 
            return updateObject(action.data );
        default: 
            return false    
    }
}

export default LoginRequestReducer;