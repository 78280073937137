import * as actionTypes from './../ActionTypes';
import {updateObject} from './../utility';

const initialState = {
    isFetching: false,
    result : ''
}


const ForgetPasswordReducer = ( state = initialState, action )=> {

    switch(action.type){
        case actionTypes.FORGET_PASSWORD_REQUEST :
            return updateObject( state, {isFetching : true} )
        break;
        case actionTypes.FORGET_PASSWORD_SUCCESS : 
            return updateObject( state , { result : action.data, isFetching : false} );
        break;
        case actionTypes.FORGET_PASSWORD_FAIL : 
            return updateObject( state, { result : action.data, isFetching : false} );
        break;
        default : 
            return false        
    }

}

export default ForgetPasswordReducer;