import React, { Component } from 'react';
const currentYear = new Date().getFullYear()

export default class CpoyrightMenu extends Component {


    render() {
        return (
            <div className="col-sm-12   text-center col-12">
                
                <ul className="list-inline mb-0">
                    
                    <li className="list-inline-item text-primary">
                    Copyright &copy; {currentYear}, <a href="https://www.parkengage.com"> ParkEngage Inc, All rights reserved.</a>
                    </li>
                </ul>
            </div>
        )
    }
}