import * as actionTypes from "../ActionTypes";
import { updateObject } from '../utility';

const initialState = {
  isFetching: true,
  result: []
}

const ContentReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.CONTENT_REQUEST:
      return updateObject(state,{});
    case actionTypes.CONTENT_REQUEST_SUCCESS:
      return updateObject(state, {result: [{name:'test', age:123}], isFetching:false});
    case actionTypes.CONTENT_REQUEST_FAIL:
      return updateObject(state, {isFetching:false});
    default:
      return state;
  }
}

export default ContentReducer;