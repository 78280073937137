import React, { Component } from 'react';
const HowItWork = (props)=> {
  
  const ListItems = props.features;
   const list =  ListItems.map( (list, key) => <li className="col-sm-6" key={key}>{list}</li>  ) 
  
    return (
      <section className="pb-5 bg-secondry">
      { props.features.length > 0 && 
        <div className="container">
          <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            {/* <h2 className="font-weight-bold text-center text-dark pt-5 pb-3">{(props.title == 'PERMIT PARKING')?'':props.title}{(props.title == 'Online Reservations')?": ":(props.title == 'PERMIT PARKING')?"":": "}How it Works </h2> */}
            <h2 className="font-weight-bold text-center text-dark pt-5 pb-3"> {props.ishowshow ? props.ishowshow :'How it Works: ' }</h2>

          </div>
          <div className="col-12 col-md-11 col-sm-12 list-img">
          <ul className="row jusify-contnet-center">
              {list}
            </ul>
          </div>
          </div>
        </div>
        }
       
      </section>

    )
}
export default HowItWork