import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import  'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import './index.css';


import App from './App';
import "./assets/scss/main.scss";
import store from "./Redux/Store";
let floatingLabel;

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));