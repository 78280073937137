import React, { Component } from 'react';
import './App.scss';
import HomeComponent from './pages/home/homeComponent';
import MainRoutes from './routing/mainRoutes';
class App extends Component {
  render(){
    return (
      <MainRoutes></MainRoutes>
    );
  }
}


export default App;
