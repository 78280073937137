import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';

const ServiceDescription = (props)=> {
    console.log(props.title.toLowerCase())
    return (
        <section id="clientSection" className="pocket-info  pt-0 ">
            <Container className="py-3">

            <div className="row align-items-center">
            <div className="col-12 col-md-7">
                <h2 style={{fontSize:(props.title == 'Permit Parking ')?"60px":""}} className="font-weight-light  display-4 text-primary"><span>{props.title}</span> </h2>
                <p className="font-weight-light" dangerouslySetInnerHTML={ { __html:props.content}}></p>
            </div>
            <div className="col-12 col-md-5">
                <div className="phone-animate text-right">
                    {(props.title.toLowerCase() == 'touchless valet parking')?<iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/XSMjlgcbIkQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>:<img className=" img-fluid rounded" src={props.image} alt={props.title} />}
                </div>
            </div>
            </div>
            </Container>
        </section>
    )
}
export default ServiceDescription