import React, { Component } from 'react';
import ThankYouStyle from './thank-you.scss'
import Aux from './../Wrapper/Auxiliary';
import BreadCrumb from './../BreadCrumb/BreadCrumb';
import NotificationSystem from "react-notification-system";

class ThankYouRequestTrial extends Component {

    headerContent = '';
     planData = '';
     currentService = ''
     currentPlan = '';
     request_trial = '';
      content = '';
     plan = '';
     title = '';
     fromPayment = false;
     paymentType = ''
   constructor(props){
       super(props);

       console.log( this.props.history)
       
       this.notificationSystem = React.createRef();
       if((this.props.history.location.state && this.props.history.location.state!=="Trial" ) && ( this.props.history.location.state !== "contactus" )){
        this.planData = this.props.history.location.state
        this.fromPayment = true
        // this.headerContent = 'Order Placed Succcessfully!'
        this.headerContent = ''
        let serviceId = this.planData.serviceId
        let planId = this.planData.planId
        let services = this.planData.services;
        this.request_trial = this.planData.request_trial;
        this.paymentType = this.planData.plan_type
        for( let service in services ){
            if( services[service].id == serviceId ){
         this.currentService = services[service].short_name;

                for( let plan in services[service].membership_plan ){
                    if(services[service].membership_plan[plan].id == planId){
                        this.currentPlan = services[service].membership_plan[plan]
                    }
                }
            }
        }
    }else {
        this.planData = '';
        this.fromPayment = false;
        this.headerContent= '';
    }
    
    if (this.props.history.location.state==="Trial"){
        this.title = 'Congratulations!';
        this.content = 'Thank you for showing interest in our services. Your trial subscription has been activated. We have sent you the Welcome Email. Please activate your account and follow the instructions mentioned in the email.'
    
    }

   else if( this.fromPayment && (this.props.history.location.state !== "contactus") && (this.props.history.location.state !== "Trial")){
        this.title = 'Congratulations!';
        this.content = 'Thank you for showing interest in our services. Your '+  this.currentService +  ' subscription has been activated. We have sent you the Welcome Email. Please activate your account and follow the instructions mentioned in the email.'
    }else {
        this.title = 'Thank You';
        this.content = 'Thank you for showing interest in our service. Our representative will get in touch with you shortly.';
    }
   }

   componentDidMount = ()=>{
    let { history } = this.props
    const notification = this.notificationSystem.current;
    if(history.location.state === "contactus" ){
        notification.addNotification({  title: 'Thank You',  message: 'Your request has been submitted successfully',
                    level: 'success'  });
    }
    else if(history.location.state === "Trial" ){
        notification.addNotification({  title: 'Thank You',  message: 'Your request for Trial has been submitted successfully',
                    level: 'success'  });
    }

    
   else if(  this.fromPayment && (history.location.state !== "contactus") && (history.location.state !== "Trial")){
        notification.addNotification({  title: 'Success',  message: 'Payment has been done successfully',
                    level: 'success'  });
    }
   }
    render(){
        
if(this.props.match.path === "/thank-you"){
    window.scrollTo({
        top: 0,
        left: 0,
      })
}
        
        return(
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <BreadCrumb
              headerTitle={`Request Trial`}
              headerName={this.title}
              headerContent={this.headerContent}
            />
            <section id="content-section" className="agrement-subscription">
                <div className="container">
                    <div className="row justify-content-center" data-aos="zoom-out-down">
                    <div className=" my-3 my-md-5 col-md-8">
                        { this.currentService && 
                        <h3 className="text-dark text-center font-light mb-2">You have Subscribed <span className="text-primary font-weight-bold">{this.currentService} Service.</span></h3>
                         }
                         { this.currentPlan &&  
                         <div className="card  my-4 shadow w-75 ml-auto mr-auto">
                             <div className="card-header bg-primary text-light">
                                 <div className="row align-items-center">
                                 <div className="col"><b>Subscribed Service  : </b></div>
                                 <div className="col-auto text-right ml-auto "><h5>{this.currentService}</h5></div>
                                 </div>
                             </div>
                             <div className="card-body">
                               
                         <table className=" mb-0 table table-sm table-borderless">
                            
                            <tbody>
                                
                                <tr>
                                    <td>Plan Name</td><td>:</td><td align="right"><h5>{this.currentPlan.name}</h5></td>
                                </tr>
                                <tr>
                                    <td>Amount</td><td>:</td><td align="right"><h5>${this.planData.amount}</h5></td>
                                </tr>
                                <tr>
                                    <td>Payment Type</td><td>:</td><td align="right"><h5><small>{(this.paymentType ==='1') ? 'Monthly': 'Yearly'}</small> </h5></td>
                                </tr>
                                <tr>
                                    <td>No. of Users</td><td>:</td><td align="right"><h5>{this.currentPlan.no_of_users} <small></small></h5></td>
                                </tr>
                            </tbody>
                        </table> 
                        </div>
    
                        </div>
                         }
                        <h4 className="text-dark text-center font-light mb-2">{this.content}</h4>
                    </div>
                    
                 </div>
                    </div>
                </section>
    
            </Aux>
        )

    }
    
} 

export default ThankYouRequestTrial