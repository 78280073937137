import React from 'react';
import BreadCrumb from './../pages/BreadCrumb/BreadCrumb';
import {FetchPaymentHistory, FetchPaymentInvoive} from "./../Redux/Actions/paymentHistoryAction";
import FontAwesome from 'react-fontawesome';
import AdminListPage from './adminListPage';
import Aux from './../pages/Wrapper/Auxiliary';
import { connect } from "react-redux";
import LoadingIcon from './../Global/Spinner/loadingIcon';

// import moment from 'Components/Global/Time/Moment';

class PaymentHistory extends React.Component {
    constructor(props){
        super(props);
        if(!localStorage.getItem('access_token')){
            this.props.history.push('/page')
        }

        this.columns = [
            { header: 'Plan Name', accessor: d =>  (d.service && d.service.full_name) ? d.service.full_name : '' },
            { header: 'Plan Type', accessor: d => (d.membership_plan && d.membership_plan.name) ? d.membership_plan.name : '' },
            { header: 'Billing Date', accessor: d =><a> {d.payment_date}</a> },
            { header: 'Plan for', accessor: d => (d.user_membership && d.user_membership.plan_type ===1) ? 'Monthly' : 'Annually' },
            { header: 'Method', accessor: d => <span>{d.anet_transaction.card_type} {d.anet_transaction.method}</span> },
            { header: 'Price', accessor: d =>(d.total) ? <span>{d.total} </span> : ''},
            { header: 'Invoice', accessor: d => this.renderActions(d) }
          ];
        //   this.timeDisplayFormat = 'MM/DD/YYYY';
    }

    renderActions = (d) => {
        return (
          <div className="user-actions facility-actions mt-sm-2">
              <p className="mb-0 text-left" >
              <a onClick={()=>this.ViewInvoiceFile(d, 0)} style={{cursor:'pointer'}}><FontAwesome name="file" data-toggle="tooltip" data-placement="top" title="View Invoice" style={{fontSize: "16px"}}  /></a>
               &nbsp;&nbsp;&nbsp;&nbsp;
               <a onClick={()=>this.ViewInvoiceFile(d, 1)}  style={{cursor:'pointer'}} className=""><FontAwesome name="download" style={{fontSize: "16px"}} /></a></p>
          </div>
        );
      };
      ViewInvoiceFile = (d, type)=> {
         this.props.dispatch(FetchPaymentInvoive(d.id, type));
      }

    componentWillMount() {
        this.props.dispatch(FetchPaymentHistory());
      }

      getUpdatedHistory = (page = 1, search = '') => {
        this.setState({search:search});
        return this.props.dispatch(FetchPaymentHistory(page, search));
      };
    

    render(){
        const { data, isFetching , total, per_page,current_page } =  this.props
    return (
        <Aux>
            <BreadCrumb  headerTitle={`Billing & Invoices`} headerName={"Transction & History"}></BreadCrumb>
            <section id="content-section">
                <div className="register-form login-popup pt-2 pb-3">
                    <div className="container">
                    <div className="my-2">

                {isFetching &&
                    <LoadingIcon />
                }
        { !isFetching && ( data &&  data.length>0) &&    
                    <AdminListPage
                      className="payment-history-wrap"
                      title="Billing & Invoices"
                      data={data}
                      columns={this.columns}
                      getNewRecords={this.getUpdatedHistory}
                      isFetching={isFetching}
                      curPage={current_page}
                      perPage={per_page}
                      total={total}
                    />
                }
                
            </div>

                    </div>
                </div>
            </section> 
        </Aux>    
    )
}
}

const mapStateToProps = state => {
    const {result , isFetching} = state.FetchPaymentHistoryReducer
    // console.log(result['data']);
    const res = result['data'];
    if(res){
    return {
         isFetching,
         data: res['data'],
         per_page: res['per_page'],
         current_page: res['current_page'],
         total: res['total'],
    };
    }
  };

//   const mapDispatchToProps = dispatch => {
//     return {
//         FetchPaymentHistory: () =>  dispatch(FetchPaymentHistory()),
//     };
//   };

  export default connect(mapStateToProps)(PaymentHistory);