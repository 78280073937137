import React, { Component } from "react";
import { connect } from 'react-redux';
import * as actionCreators from '../../Redux/Actions/index';
import NotificationSystem from 'react-notification-system';
import RequestDemo from './../../pages/request-demo/requestDemoComponent';
import { FooterCSS } from './footer.scss';

class RequestDemoFooter extends Component {
  constructor(props) {
    super(props);
    this.notificationSystem = React.createRef();
    const disableBtn = true;
    this.state = {
      username: {
        value: "",
        name: "username",
        // placeholder: "your email address",
        id: "inlineFormInputName2",
        class: "form-control footerForm mb-2 mr-sm-2",
        type: "text",
        error: {
          username: ""
        }
      },
      selectDemo: {
        value: "",
        name: "SeletService",
        class: "form-control footerForm",
        id: "",
        options: [
          { value: "", label: "Select Product" },
          { value: "2", label: "Inventory", selected: true },
          { value: "1", label: "Monthly Parking Management" },
          { value: "3", label: "Touchless parking system" },
          { value: "4", label: "Quick [Mobile] Valet System" },
        ]
      },
      formData: {
        username: "",
        selectDemo: ""
      }
    };
  }

  // InputHandler = (event) => {
  //   const { name, value } = event.target;
  //   let defaultData = { ...this.state.formData };
  //   if (name == 'username') {
  //     defaultData.username = value;
  //   } else if (name == 'SeletService') {
  //     defaultData.selectDemo = value;
  //   }
  //   this.setState({ formData: defaultData });
  // }

  // handleSubmit = event => {
  //   const notification = this.notificationSystem.current;
  //   event.preventDefault();
  //   let defaultData = this.state.formData;
  //   // if(defaultData.username == '' || defaultData.selectDemo == ''){
  //   //   notification.addNotification({
  //   //     title: 'Sorry',
  //   //     message: 'Please insert desired value.',
  //   //     level: 'error'
  //   //   });
  //   // }else {
  //   //   this.props.requestForDemo(defaultData).then(() => {
  //   //     notification.addNotification({
  //   //       title: 'Thank You',
  //   //       message: 'Your request has been sumitted successfully, will touch you soon',
  //   //       level: 'success'
  //   //     });
  //   //   });
  //   // }
  // };

  render() {
    return (
      <div className="row ">
        <NotificationSystem ref={this.notificationSystem} />
       
        <div className="col-12 col-auto formFooter   ">
          {/* <form className="form-inline" onSubmit={this.handleSubmit}>
            <label className="sr-only" htmlFor="inlineFormInputName2">
              Name
            </label>
            <input
              type={this.state.username.type}
              name={this.state.username.name}
              className={this.state.username.class}
              id={this.state.username.id}
              placeholder={this.state.username.placeholder}
              onChange={this.InputHandler}
            />
            <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">
              Username
            </label>
            <div className="input-group mb-2 mr-sm-2">
              <select
                name={this.state.selectDemo.name}
                className={this.state.selectDemo.class}
                id={this.state.selectDemo.id}
                onChange={this.InputHandler}
              >
                {this.state.selectDemo.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="btn btn-light rounded-0 mb-2">
              Submit
            </button>
          </form> */}
          {/* <form>
                <div className="row mt-2">
                <div className="form-group col-md-6 ">
                  <input type="text" className="form-control footerForm " placeholder="Full name" id=""/>
                </div>
                <div className="form-group col-md-6 ">
                  <input type="text" className="form-control footerForm " placeholder="Contact No." id=""/>
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6 ">
                  <input type="email" className="form-control footerForm " placeholder="Email or Loign ID" id=""/>
                </div>
                <div className="form-group col-md-6 ">
                  <input type="email" className="form-control footerForm " placeholder="Company Email ID" id=""/>
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6 ">
                  <select className="form-control footerForm " id="" name="City">
                    <option>Select City</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
                <div className="form-group col-md-6 ">
                  <select className="form-control footerForm " id="" name="country">
                    <option>Country:</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
                <div className="form-group col-md-6 ">
                  <select className="form-control footerForm " id="" name="country">
                    <option>Select Product for demo:</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
            
                <button type="submit" disabled={true} className="btn btn-primary  ">Submit and Proceed  </button>
                </div>
                
                
              </div>
              </form> */}

              <RequestDemo  isFooterForm='true'></RequestDemo>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     isFetching: state.reqDemo.isFetching,
//     result: state.reqDemo.result
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     requestForDemo: (data) => dispatch(actionCreators.requestForDemo(data)),
//   }
// }

//export default connect(mapStateToProps, mapDispatchToProps)(RequestDemoFooter);

export default RequestDemoFooter
