import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import news1Image from './assets/news1.png';
import news2Image from './assets/news2.png';

export default class NewsLest extends Component {
  render() {
    return (

      <section id="content-section" className=" whats-news">
        <div className="container">
          <div className="row justify-content-center" data-aos="zoom-out-down">
            <div className="text-center my-5 col-md-8">
              <h2 className="text-dark font-weight-bold">What’s on News</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card card-inverse card-info">
                <img className="card-img-top" src={news1Image} />
                <div className="card-block">
                  <h4 className="card-title mt-3">Why go Frictionless</h4>
                  <div className="card-text">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                            </div>
                </div>
                <div className="card-footer">
                  <small>By: Auther &nbsp;&nbsp;|&nbsp;&nbsp;</small><small>12/01/2020 </small>
                  <Link to={'/services'} className="btn btn-link  float-right btn-sm">Learn More..</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card card-inverse card-info">
                <img className="card-img-top" src={news1Image} />
                <div className="card-block">
                  <h4 className="card-title mt-3">Why go Frictionless</h4>
                  <div className="card-text">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                          </div>
                </div>
                <div className="card-footer">
                  <small>By: Auther &nbsp;&nbsp;|&nbsp;&nbsp;</small><small>12/01/2020 </small>
                  <Link to={'/services'} className="btn btn-link float-right btn-sm">Learn More..</Link>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center pb-4">Want more to explore? <Link to={'/services'} className="text-primary"> Click here  </Link></p>
        </div>
      </section>
    )
  }
}