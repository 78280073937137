import React from "react";
import OwlCarousel from 'react-owl-carousel2';
import './OwlCarousel.css';

const options = {
  items: 1,
  nav: false,
  rewind: true,
  autoplay: true,
  autoplayTimeout: 3000,
  loop: true,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
};

const Owlcarousel = (props) => {
  return (
    <OwlCarousel options={options}>
      {props.items.map((item, i) =>{
        return <img key={i} src={item.imgSrc} />
      })}
    </OwlCarousel>
  )
}

export default Owlcarousel;