import React from 'react';
import BreadCrumb from './../BreadCrumb/BreadCrumb'
import LocationMap from './location-map';
import AddressSection from './address';
import ContactForm from './contact-form';
import SubscriberForm from './subscriber-form';
import ConstactStyle from './contact-style.scss';
import Aux from './../Wrapper/Auxiliary.js';


class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: [
                {
                    location: 'US Corporate Office',
                    locationAddress: '4100 Moorpark Avenue, Suite 203 San Jose, CA 95117',
                    contactType: 'Office',
                    contactNumber: '+1 (408) 727 1400'
                },
                // { 
                //     location : 'UK Corporate office', 
                //     locationAddress : '39 Roxborough Road, Jain World Peace Centre Harrow, HA1 1NS', 
                //     contactType: '',
                //     contactNumber : '' 
                // },
                // { 
                //     location : 'Hong Kong office', 
                //     locationAddress : 'Quarry Bay branch Suite 1008, Prosperity Millennia Plaza, 663 Kings Road, Quarry Bay, Hong Kong', 
                //     contactType: '', 
                //     contactNumber : '' 
                // },
                // { 
                //     location : 'Malaysia office', 
                //     locationAddress : 'No. 43-M, Jalan Thambypillai off Jalan Tun Sambanthan 50470 Kuala lumpur w.p. Malaysia', 
                //     contactType: '', 
                //     contactNumber : '' 
                // },
            ],
            contactInfo: [
                { officeName: 'Noida, India', contactNumber: '+1(123) 4567 890' },
                { officeName: 'Pune, India', contactNumber: '+1(123) 4567 890' },
                { officeName: 'Banglore, India', contactNumber: '+1(123) 4567 890' }
            ]
        }
    }
    render() {
        const { address } = this.state
        return (
            <Aux>
                <BreadCrumb headerTitle={`contact Us`} headerName={"Let's Connect"}></BreadCrumb>
                <section id="content-section">
                    <div className="register-form login-popup pt-2 pb-3">
                        <div className="container">
                            <ContactForm ></ContactForm>
                        </div>
                    </div>
                </section>

                {/* <div className="bg-primary py-4  ctaBlock  px-0 text-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                        <h4 className="p-0 m-0">Interested in learning more about ParkEngage? </h4>Interested in learning more about ParkEngage? Sign up for our mailing list to receive the latest news and updates
                        </div>
                        <div className="col mt-3 mt-sm-0">
                            <div className="input-group">
                                <input type="text" className="form-control customeInput" placeholder="Enter your Email Id" aria-label="Enter Your email Id" aria-describedby="button-addon2"/>
                                <div className="input-group-append">
                                    <button className="btn  btn-light customeButton" type="button" id="button-addon2">Subscribe Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                <SubscriberForm></SubscriberForm>

                <section id="content-section " className="bg-secondry d-none">
                    <div className="container">
                        <div className="row justify-content-center" data-aos="zoom-out-down">
                            <div className="text-center my-5 col-md-8">
                                <h2 className="text-dark font-weight-bold">Locate Us On</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center address-lt">
                            <div className="col-lg-6">
                                <div className="row mb-5">
                                    {
                                        address.map((address, index) => (
                                            <AddressSection key={index}
                                                location={address.location}
                                                locationAddress={address.locationAddress}
                                                contactType={address.contactType}
                                                contactNumber={address.contactNumber}
                                            ></AddressSection>
                                        ))
                                    }

                                    {/* <div className="col-md-12"><div className="border-bottom"></div></div> */}
                                    {/* {contactInfo.map( (contact, index)=> ( 
                                 <ContactInfo officeName={contact.officeName} contactNumber={contact.contactNumber} ></ContactInfo>
                              ) )  }    */}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <LocationMap></LocationMap>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}

export default ContactUs;