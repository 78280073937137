import React, { Component } from 'react';
import { Container, Button, Col, Row  } from 'reactstrap';
//import style from './assets/bannerStyle.scss';
import testimobialsImg1 from './assets/hm-testimonial-1.png';
import testimobialsImg2 from './assets/hm-testimonial-2.png';
import testimobialsImg3 from './assets/hm-testimonial-3.png';


export default class TestimonialsSection extends Component{

    render() {
        return (
            <section id="clientSection" className="home-testimonial bg-secondry py-5">
               <Container>
                    <div id="carouselId" className="carousel slide my-4" data-ride="carousel">
                    <Row>
                        <Col md="6">
                        
                    
                            <ol className="carousel-indicators">
                            <li data-target="#carouselId" data-slide-to="0" className="active"><img id="img-63132" src={testimobialsImg1} alt="Colorful corona virus infographic" /></li>
                            <li data-target="#carouselId" data-slide-to="1"><img id="img-63132" src={testimobialsImg2} alt="Colorful corona virus infographic" /></li>
                            <li data-target="#carouselId" data-slide-to="2"><img id="img-63132" src={testimobialsImg3} alt="Colorful corona virus infographic" /></li>
                            </ol>
                        
                            
                        </Col>
                        <Col md="6">
                            <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active">
                            
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quis molestias alias placeat repudiandae, asperiores quae odio temporibus facilis rerum ratione dolorum cumque quisquam! Velit dolor possimus ratione error molestiae!</p>
                            <h4>Mike Andrew</h4>
                            <p><span>CEO, Company.com</span></p>
                            </div>
                            <div className="carousel-item">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quis molestias alias placeat repudiandae, asperiores quae odio temporibus facilis rerum ratione dolorum cumque quisquam! Velit dolor possimus ratione error molestiae!</p>
                                <h4>Mike Andrew</h4>
                                <p><span>CEO, Company.com</span></p>
                            </div>
                            <div className="carousel-item">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quis molestias alias placeat repudiandae, asperiores quae odio temporibus facilis rerum ratione dolorum cumque quisquam! Velit dolor possimus ratione error molestiae!</p>
                                <h4>Mike Andrew</h4>
                                <p><span>CEO, Company.com</span></p>
                            </div>

                            </div>
                       
                        </Col>
                    </Row>
                    </div>
                </Container>
                </section>
        )
    }
} 