import React, { Component } from 'react';
import { Link} from 'react-router-dom';


import mobScreenCircleImage from './assets/mob-screen-circle.png';
import iPhoneImage from './assets/iPhone-X.png';

export default class InformationComponent extends Component {

    render( ) {

        return (
            <section id="clientSection" className="pocket-info py-4">

                <div className="container py-3">
                    <div className="row align-items-center">
                    
                    <div className="col-md-7">
                    <h2 className="font-weight-bold pt-3 pb-3 text-primary" ><span>Get all the</span> Information <span>right in your</span> pocket</h2>
                        <p className="font-weight-light d-none" >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, 
                        </p>
                        <p className="font-weight-light d-none" >
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <p className="pt-3">
                        <a href="#" className="btn btn-primary min-width btn-outline-primary mr-2 ">Read more</a> 
                        <div className="mb-2 d-block d-sm-none"></div>
                        <Link to="/request-a-demo"  className="btn btn-outline-primary min-width  btn-light" > Request Demo   </Link>
                        </p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5">
                    <div className="phone-animate text-right mb-5 mt-3 pb-4">
                        <img className="mob-circle img-fluid" data-aos="fade-right" src={mobScreenCircleImage} alt="Get all the Information right in your pocket cicle" />
                        <img className="iphone-x img-fluid" data-aos="fade-left" src={iPhoneImage} alt="Get all the Information right in your pocket iphone" />
                        </div>
                    </div>
                    </div>
                </div>
                </section>
        )

    }
 
}