import React, { Component } from 'react';
import { connect } from "react-redux";

class LogoutFromAdmin extends Component  {
    constructor(props){
        super(props)
       sessionStorage.clear();
       localStorage.clear();
       //
    }

    componentDidMount(){
       localStorage.clear();
       sessionStorage.clear();
       window.location.href = '/';
    }

    render(){
        return (
            <div>
                <h2>Loading...</h2>
            </div>
            
        )
    }
}

export default connect()(LogoutFromAdmin)
