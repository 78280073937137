import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from "./Reducers/RootReducer";

const logger = store => {
  return next => {
      return action => {
          // console.log('[Middleware] Dispatching', action);
          const result = next(action);
          // console.log('[Middleware] next state', store.getState());
          return result;
      }
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(RootReducer, composeEnhancers(applyMiddleware(logger, thunk)))

export default store;