import * as actionTypes from "../ActionTypes";
import axios from "../../config/axios";

export const trialRequestStart = () => {
    return {
      type: actionTypes.TRIAL_REQUEST
    };
  };
  
  export const trialRequestSuccess = (res) => {
    return {
      type: actionTypes.TRIAL_REQUEST_SUCCESS,
      data: res
    };
  };
  
  export const trialRequestFail = (error) => {
    return {
      type: actionTypes.TRIAL_REQUEST_FAIL,
      error: error
    };
  };

  export const paidRequestStart = () => {
    return {
      type: actionTypes.PAID_PLAN_REQUEST
    };
  };
  
  export const paidRequestSuccess = (res) => {
    return {
      type: actionTypes.PAID_PLAN_REQUEST_SUCCESS,
      data: res
    };
  };
  
  export const paidRequestFail = (error) => {
    return {
      type: actionTypes.PAID_PLAN_REQUEST_FAIL,
      error: error
    };
  };

//   const config = {
//     headers: { Authorization: `${token}` }
// };

  export const requestForTrial = (reqData) => {
    let APIurl = '';
    if(reqData['is_trial'] == "0"){
      APIurl = '/trial-registration';
    }else {
      APIurl = '/multiple-trial-registration';
    }
    return dispatch => {
      
      dispatch(trialRequestStart());
      reqData['client_id'] = "inventory-demo";
      reqData['client_secret'] = "Wqhqdz9PMKMHWUG9pZ0Oowvrz";
      reqData['grant_type'] = "password";
      return axios.post(APIurl, reqData)
        .then(res => {
          return dispatch(trialRequestSuccess(res.data));
        })
        .catch(err => {
          return dispatch(trialRequestFail(err));
        });
    };
  }
  export const requestForPaidPlan = (reqData) => {
    let subscribeToken = reqData['subscribeToken'];
    if(!subscribeToken) {
      subscribeToken = localStorage.getItem('subscribeToken');
    }
    delete reqData.subscribeToken;
    localStorage.removeItem('subscribeToken');
    return dispatch => {
      dispatch(paidRequestStart());
      return axios.post('/membership-payment', reqData, 
      { headers: { Authorization: `Bearer ${subscribeToken}` } }
      )
        .then(res => {
          return dispatch(paidRequestSuccess(res.data));
        })
        .catch(err => {
          return dispatch(paidRequestFail(err));
        });
    };
    
  }