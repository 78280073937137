import React, { Component} from 'react';
import { connect } from "react-redux";
import { Link, withRouter, NavLink } from 'react-router-dom';
import logo from './../assets/img/logo.svg';
import logowt from './../assets/img/logo-wt.svg';
import MainNavbar from './../routing/main-navbar';
// import phoneIcon from './Assets/phone-icon.svg';
import mailIcon from './Assets/email.svg';
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button
  
} from 'react-bootstrap';

class HeaderComponent extends Component {
  isAuthenticated = false;
  username = '';
  
  constructor(props) {
    super(props);
    if(localStorage.getItem('access_token')){
      this.isAuthenticated = true
      this.username = localStorage.getItem('username')
    } 
  }

  shouldComponentUpdate(prevProps , nextState){
    if(prevProps !== this.props){
      if(localStorage.getItem('access_token')){
        this.isAuthenticated = true
        this.username = localStorage.getItem('username')
      } 
      return true
    }
    return false
        
}

componentDidUpdate(prevProps){
    if(localStorage.getItem('access_token')){
      this.isAuthenticated = true
      this.username = localStorage.getItem('username')
    }     
}


  render() {

    const path = this.props.location.pathname.slice(1);
    const containerClass = (path)?'darknavbar':'';
    const containerClassMenu = (path)? 'nav-logo-phone':'';
    return (
      <header className={(path=='')?'home-page':'inner-page'} >

{/* <div className="infoBacking  hidden-xs bg-secondry clearfix ">

  <div className="row no-gutters align-items-center justify-content-center">
    <div className="col-auto">
            <p className="p-0 m-0"><h4 className="m-0 p-0">Join us for our upcoming webinar.</h4>The Next Generation of Parking Technology: Solutions to Increase Revenues and Enhance Customer Engagement  <br/>
        Thursday, December 10, 2020  |  2:00 pm EST
       
          </p>
    </div>
    <div className="col-auto ml-3">
      <a className="btn btn-primary btn-outline-primary btn-sm" target="_blank" href="https://zoom.us/webinar/register/WN_J-wvsF75RfufdsD9j7MBcA" >Join Now</a>
    </div>
  </div>
 

</div> */}
{/* <div className="top-bg-15">
<div className="container">
  <div className="row">
    <div className="col-md-12">
      <h4><span>Get started with a 15 day free trial</span>
      <span><a href="mailto:info@parkengage.com" className="min-width btn top-btn">Contact Us</a></span></h4>
      
      </div>
  </div>
</div>
</div> */}
<Navbar color="transparent" variant="none" className={`${containerClass} fixed-top    lighten-1 navbar-default`} expand="lg" >
  <div className='mx-auto d-flex justify-content-between w-100 ' style={{maxWidth:'1100px'}} >
<NavLink className="navbar-brand logo2" to="/">  <img height="45"  src={logo} alt="Park Engage" /></NavLink> 
{/* <a className={`text-dark d-none d-sm-block small font-weight-bold mt-1  mr-auto ${ containerClassMenu } `} href="mailto:info@parkengage.com">| <span className="nav-mail"><i class="fas fa-envelope"></i> info@parkengage.com</span> </a> */}
                    {/* <span className="topsocial">
                    <a target="_blank" href="https://www.facebook.com/ParkEngage/" className="px-0  btn-link "><i className="la la-2x la-facebook"></i></a>
                    <a href="https://twitter.com/ParkEngageInc" target="_blank" className="px-0   btn-link"><i className="la la-2x la-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/parkengage" target="_blank" className="px-0  btn-link"><i className="la la-2x la-linkedin"></i></a>
                    <a href="https://www.instagram.com/parkengageinc/" target="_blank" className="px-0  btn-link"><i className="la la-2x la-instagram" style={{fontWeight:'bolder'}} ></i></a>
                    </span> */}

  
  <Navbar.Toggle aria-controls="basic-navbar-nav" />

<MainNavbar isAuthenticated={this.isAuthenticated} username={this.username} headerCls={containerClass}></MainNavbar>
</div>
</Navbar>



    {/* <Navbar color="transparent" className={containerClass} className="fixed-top  header headeBar lighten-1 navbar-default" expand="lg">
    <NavLink className="navbar-brand" to="/">  <img height="55"  src={logo} alt="Park Engage" /></NavLink> <a className={`text-dark d-none d-sm-block font-weight-bold ml-3 mt-2  mr-auto ${ containerClassMenu } `} href="tel:+14087271400">| <img className="ml-2 mr-1" width="18" src={phoneIcon}/> +1 (408) 727 1400</a>
    <button className="d-block d-lg-none btn pr-0" type="button"  id="menuToggle">
   <i className="fa fa-bars fa-2x"></i>
  </button>

   
            {/* <NavbarToggler /> */}
    {/* <MainNavbar isAuthenticated={this.isAuthenticated} username={this.username} headerCls={containerClass}></MainNavbar>
            </Navbar> */} 
        
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    result:  state.loginRequest &&  state.loginRequest.result
  }
}
export default connect( mapStateToProps, null )(withRouter(HeaderComponent))
