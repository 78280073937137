import React from 'react';
import classes from './Input.css';

import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles
  } from '@material-ui/core/styles';
  import TextField from '@material-ui/core/TextField';
  import MenuItem from '@material-ui/core/MenuItem';
  import FormHelperText from '@material-ui/core/FormHelperText';
  import FormControl from '@material-ui/core/FormControl';
  import Select from '@material-ui/core/Select';
  import InputLabel from '@material-ui/core/InputLabel';
  
  import { Form, Button } from 'react-bootstrap';


  const useStylesReddit = makeStyles((theme) => ({
    root: {
      border: '1px solid #ced4da',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fff',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#4A90E2', 0.25)} 0 0 0 2px`,
        borderColor: '#4A90E2',
      },
    },
    focused: {},
  }));


function RedditTextField(props) {
    const classes = useStylesReddit();
  
    return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
  }
  


const input = (props) => {
    let inputElement = null;
    const inputClasses = [props.className];

    if (props.valid && props.shouldValidate && props.touched) {
        inputClasses.push("Invalid");
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <div className= {props.containerClass}>
                    
                    <input
                        className={inputClasses.join(' ')}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                       
                        required={true} />
                        <label htmlFor="fullname">
                        {props.elementConfig.label}
                    </label>
                        {(props.valid && props.shouldValidate && props.touched)?<small className="form-text text-muted">{props.validationMsg}</small>:null}
                </div>
            );
            break;
        case ('textarea'):
            inputElement = (
                <div className={props.containerClass}>
                    <label htmlFor="fullname">
                        {props.elementConfig.label}
                    </label>
                    <textarea
                        className={inputClasses.join(' ')}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed} />
                </div>
            );
            break;
        case ('select'):
            inputElement = (

   



                <div className={props.containerClass}>

                    <TextField
                            id="standard-select-currency"
                            select
                            className={inputClasses.join(' ')}
                            label={props.elementConfig.label && props.shouldValidate && <span style={{color:'red'}}>*</span> }
                            value={props.value}
                            onChange={props.changed}
                            // helperText="Please select your currency"
                            >
                            {props.elementConfig.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                               {option.displayValue}
                                </MenuItem>
                            ))}
                            </TextField>


                    {/* <label htmlFor="fullname">
                        {props.elementConfig.label} { props.shouldValidate && <span style={{color:'red'}}>*</span> }
                    </label> */}
                    {/* <select
                        className={inputClasses.join(' ')}
                        value={props.value}
                        onChange={props.changed}>
                        {props.elementConfig.options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.displayValue}
                            </option>
                        ))}
                    </select> */}
                </div>



            );
            break;
        default:
            inputElement = (
                <div className={props.containerClass}>
                    <label htmlFor="fullname">
                        {props.elementConfig.label}
                    </label>
                    <input
                        className={inputClasses.join(' ')}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed} />
                </div>
            );
    }

    return inputElement
};

export default input;