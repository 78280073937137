import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import itemImg1 from './../../assets/img/1.png';
import itemImg2 from './../../assets/img/2.png';
import itemImg3 from './../../assets/img/3.png';
import itemImg4 from './../../assets/img/4.png';

import { Row, Col, Card, CardText, Button } from 'reactstrap';

class FrectionlessList extends Component {
    constructor(props) {
        super(props)
        
    }

    frectionlessLists = [
        {
            title: 'Generate New Revenue Streams',
            description: 'Gain control of your parking inventory. Embrace the strengths of...',
            link: '/services',
            linkText: 'Learn more',
            itemImage: itemImg1
        },
        {
            title: 'Improve Customer Engagement',
            description: 'Attract new customers with innovative promotions that keep you ahead...',
            link: '/services',
            linkText: 'Learn more',
            itemImage: itemImg2
        },
        {
            title: 'Create Touchless Parking Experiences',
            description: 'Meet the expectations of your highly mobile and extremely busy customers...',
            link: '/services',
            linkText: 'Learn more',
            itemImage: itemImg3
        },
        {
            title: 'Enhance Operational Efficiencies',
            description: 'Valets and Attendants are the face of your garage. Poor service by any of...',
            link: '/services',
            linkText: 'Learn more',
            itemImage: itemImg4
        },

    ]

    
    render() {
        return (
            <Row className="mb-4 justify-content-center home-icon-box">
                {this.frectionlessLists.map((item, index) =>
                    <Col key={index} sm="6" md="6" lg="3">
                        <Card onClick={()=>this.props.clicked(index)} body className="mb-4 px-0 text-center">
                            <div className="icon-bg">
                                <img className="mb-3 mt-4" alt={item.title} style={{ height: 70 + 'px' }} src={item.itemImage} />
                            </div>
                            <h5 style={{color: '#2B3344', fontSize: '14px', marginBottom: '16px'}}>{item.title}</h5>
                                <p className="">{item.description}</p>
                            <Link to="" className="text-primary" onClick={()=>this.props.clicked(index)} >{item.linkText}</Link>
                        </Card>
                    </Col>
                )}
            </Row>
        )
    }
}

export default FrectionlessList;