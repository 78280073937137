import React from "react";
import CallUs from "./CallUs";
import Carousel from "../OwlCarousel/OwlCarousel";
import abtImg from './../../assets/img/aboutus-bg3.jpg';
import ipmiLogo from './../../assets/img/IPMI-logo.png';
import npaLogo from './../../assets/img/npa-logo3.png';
import veterans_logo from './../../assets/img/veterans_logo.jpg';

const ClientInfo = (props) => {
    return (<section id="clientSection" className="about-slider">
        <div className="container py-1">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 pb-5">
                <p className="text-left pb-1">
                 The ParkEngage platform offers end-to-end touchless parking for both self and valet garages, comprehensive customer relationship management, mobile valet, permit parking, and parking inventory and multi-channel management. Further, ParkEngage can integrate our solutions with any existing parking equipment or applications, or work with our clients to create custom applications that meet their unique needs. 
                    <br></br><br></br>ParkEngage is here to help owners and operators enhance their operation, while providing them with the services to effectively serve their customers. Contact us today to learn more.</p>
                </div>
            </div>
            {/* <Carousel items={props.clientInfo.carouselInfo} />
            <div className="row justify-content-center abt-contdown mt-3">
                {props.clientInfo.projectInfo.map(info => {
                    return (
                        <div key={info.title} className="col-6 col-md-3">
                            <div className="abt-contdown-box">
                                <img src={info.imgSrc} />
                                <h1 className="text-primary">{info.title}</h1>
                                <p>{info.description}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <CallUs callInfo={props.clientInfo.callInfo} /> */}
        </div>
        <div className="partner-bg py-4 mb-0 bg-secondry">
            <div className="container">
                <div className="row justify-content-center abt-partner-logo mb-4">
                    <div className="col-12 col-md-12 col-lg-12 text-center">
                        <h2 className="pt-3 pb-3 text-dark">ParkEngage proudly partners with these industry associations:</h2>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-12 col-md-5 col-lg-3">
                        <div className="abt-logo-box">
                            <img src={ipmiLogo} />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-3">
                        <div className="abt-logo-box">
                            <img src={npaLogo} />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-3">
                        <div className="abt-logo-box">
                            <img src={veterans_logo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default ClientInfo;