import React, { Component } from 'react';
import Aux from './../pages/Wrapper/Auxiliary';
import { connect } from "react-redux";
import * as actionCreators from "../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import SimpleReactValidator from 'simple-react-validator';
import BreadCrumb from './../pages/BreadCrumb/BreadCrumb';
import $ from 'jquery';
require('./login.scss');
class GenerateNewPassword extends Component {
    constructor(props) {
        super(props);
        this.access_token = localStorage.getItem('access_token');
        this.notificationSystem = React.createRef();
        this.validator = new SimpleReactValidator({
            validators: {
                pass_pattern: {
                    message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-])[^()\\`~:;"/<>]{8,15}$/) && params.indexOf(val) === -1
                    }
                },
                confirm: {
                    message: 'Password and confirmation password not matched.',
                    rule: (val, params, validator) => {
                        return (this.state.formData.password.value == this.state.formData.confirmPassword.value) && params.indexOf(val) === -1
                    }
                }
            }
        });

        this.state = {
            formData: {
                password: {
                    name: 'password',
                    value: '',
                    placeholder: 'Enter password',
                    validationMsg: '',
                    type: 'password',
                    id: 'pwd',
                    class: 'form-control',
                    valid: false
                },
                confirmPassword: {
                    name: 'confirmPassword',
                    value: '',
                    placeholder: 'Enter confirm password',
                    validationMsg: '',
                    type: 'password',
                    class: 'form-control',
                    id: 'confpwd',
                    valid: false
                }
            },
            formIsValid: false,
            loading: false,
            errorMsgPwd: '',
            errorMsgCPwd: '',
            passwordShown: false,
            cpasswordShown: false
        }
    }

    inputHandler = (event) => {
        const { name, value } = event.target;
        let prevStateData = this.state.formData
        const { errorMsgPwd, errorMsgCPwd } = this.state
        prevStateData[name].value = value;
        this.setState({ formData: prevStateData, errorMsgPwd: '', errorMsgCPwd: '' })
    }

    togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            this.setState({ passwordShown: this.state.passwordShown ? false : true })
        }
        if (key === 'cpassword') {
            this.setState({ cpasswordShown: this.state.cpasswordShown ? false : true })

        }

    }

    submitHandler = (event) => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        if (this.validator.allValid()) {
            const formData1 = {};
            this.setState({ loading: true });
            formData1['password'] = this.state.formData.password.value;
            formData1['password_confirmation'] = this.state.formData.confirmPassword.value;
            formData1['token'] = this.props.match.params.token;

            this.props.dispatch(actionCreators.ResetPasswordRequestService(formData1)).then((res) => {
                if ((res.data.status == 201) || (res.data.status == 200)) {
                    notification.addNotification({
                        title: 'Successfully!',
                        message: 'Password has been updated Successfully',
                        level: 'success'
                    });
                    setTimeout(() => { this.props.history.push('/'); }, 1000);
                    $("#myModal").modal("show");
                } else {
                    notification.addNotification({
                        title: '"' + res.data.data.errors.message + '"',
                        // message: '"' + res.data.data.errors.detail.token[0] + '"',
                        level: 'error'
                    });
                }
                this.setState({ loading: false });
            })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {
        let Field = this.state.formData;
        const { errorMsgPwd, errorMsgCPwd } = this.state
        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                <BreadCrumb
                    headerTitle={`Create Password`}
                    headerName={"Create Password"}
                    headerContent={
                        ""
                    }
                />
                <section id="content-section">
                    <div className="container">
                        <div className="login-popup">
                            <div className="container">
                                <div className="mt-4 mb-5 p-0">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-7">
                                            <div className="login-rt border px-5 py-5">
                                                <div className={`alert ${this.access_token ? 'alert-danger' : 'alert-primary'}`} role="alert">
                                                {this.access_token ? 'You are already Logged-In as a different User, Please Logout to Create a New Password' : 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).'}
                                                </div>
                                                {this.access_token ? null : <form name="LoginForm" onSubmit={this.submitHandler}>
                                                    <div className="form-group">
                                                        <label>Enter Password</label>
                                                        <div className="d-flex myiconset">
                                                            <input
                                                                type={this.state.passwordShown ? 'text' : Field.password.type}
                                                                name={Field.password.name}
                                                                onChange={this.inputHandler}
                                                                onBlur={this.inputHandler}
                                                                value={Field.password.value}
                                                                className={Field.password.class}
                                                                placeholder={Field.password.placeholder}
                                                                id={Field.password.id}
                                                            />
                                                            <i className={this.state.passwordShown ? 'fas fa-eye-slash margin-right-top' : 'fas fa-eye margin-right-top'} onClick={(e) => this.togglePasswordVisiblity(e, 'password')}></i>
                                                        </div>
                                                        {errorMsgPwd &&
                                                            <p className="input-errors">{errorMsgPwd}</p>
                                                        }
                                                        {!errorMsgPwd &&
                                                            this.validator.message('Password', Field.password.value, 'required|pass_pattern')}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Enter Confirm Password</label>
                                                        <div className="d-flex myiconset">
                                                            <input
                                                                type={this.state.cpasswordShown ? 'text' : Field.confirmPassword.type}
                                                                name={Field.confirmPassword.name}
                                                                onChange={this.inputHandler}
                                                                onBlur={this.inputHandler}
                                                                value={Field.confirmPassword.value}
                                                                className={Field.confirmPassword.class}
                                                                placeholder={Field.confirmPassword.placeholder}
                                                                id={Field.confirmPassword.id}
                                                            />
                                                            <i className={this.state.cpasswordShown ? 'fas fa-eye-slash margin-right-top' : 'fas fa-eye margin-right-top'} onClick={(e) => this.togglePasswordVisiblity(e, 'cpassword')}></i>
                                                        </div>
                                                        {errorMsgCPwd &&
                                                            <p className="input-errors">{errorMsgCPwd}</p>
                                                        }
                                                        {!errorMsgCPwd &&
                                                            this.validator.message('Confirm Password', Field.confirmPassword.value, 'required|pass_pattern|confirm')}
                                                    </div>
                                                    {!this.state.loading &&
                                                        <button type="submit" className="btn btn-primary btn-outline-primary">Create Password</button>
                                                    }
                                                    {this.state.loading &&
                                                        <button type="button" disabled className="btn btn-primary btn-outline-primary">Create Password</button>
                                                    }
                                                </form>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default connect(mapStateToProps)(GenerateNewPassword)
