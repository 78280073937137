import axios from 'axios';
import urlCongigration from './urlCongigration';

const instance = axios.create({
    baseURL: urlCongigration()?.apiBaseURL,    
});

export const config = {
    baseURL: urlCongigration()?.apiBaseURL, 
}

export default instance;