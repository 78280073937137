import React, { Component } from 'react';


class solutionComponent extends Component {

    render() {
        return (
            <div className="content">
                Solution page
            </div>
        )
    }

}

export default solutionComponent;