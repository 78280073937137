import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import {  Col, Row, Button} from 'reactstrap';
import $ from 'jquery';

import RequestDemoFooter from './requestDemoFooter';
   



class FooterMenu extends Component {

    updateFormStatus = ()=> {
        $('footer .srv-validation-message').hide()
    }

    render() {
        return (
           
            <Row>

                <Col sm="12" md="3">

                <p className="mt-4 mb-3 w-100">
                        <b className="">Links</b>
                    </p>
                    <div className="dropdown-divider mb-3"></div>
                    <ul className="footerLinks">
                        {/* <li><Link to="/" onClick={this.updateFormStatus} className="btn-link">Home</Link></li> */}
                        <li><Link to="/about-us" onClick={this.updateFormStatus}  className="btn-link">Who We Are</Link></li>
                        <li><Link to="/services" onClick={this.updateFormStatus}  className="btn-link">What We Do</Link></li>
                        <li><Link to="/news" onClick={this.updateFormStatus}  className="btn-link">News</Link></li>
                        <li><Link to="/software-defined-parcs" onClick={this.updateFormStatus}  className="btn-link">Software Defined PARCS</Link></li>
                        <li><Link to="/airport-parking" onClick={this.updateFormStatus}  className="btn-link">Airport Parking</Link></li>
                        <li><Link to="/request-a-demo" onClick={this.updateFormStatus}  className="btn-link">Request a Demo</Link></li>
                       
                        <li><Link to="/career" onClick={this.updateFormStatus}  className="btn-link">Career</Link></li>
                        <li><Link to="/contact-us" onClick={this.updateFormStatus}  className="btn-link">Contact Us</Link></li>
                        

          
                    </ul>

                </Col>
                <Col sm="12" md="" lg="">
                    <p className="mt-4 mb-3 w-100"><b>Request a Demo</b></p>
                    <div className="dropdown-divider mb-4"></div>

                            <RequestDemoFooter></RequestDemoFooter>


                </Col>
          
           
              
               
                   
                </Row>
               
        )
    }
}

export default FooterMenu