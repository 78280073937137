import React from 'react';
import BreadCrumb from './../pages/BreadCrumb/BreadCrumb'
import Aux from './../pages/Wrapper/Auxiliary';

class PaymentSetting extends React.Component {
    constructor(props){
        super(props);
        this.state = {  }
    }
    render(){
    return (
        <Aux>
            <BreadCrumb  headerTitle={`Payment Setting`} headerName={""}></BreadCrumb>
            <section id="content-section">
                <div className="register-form login-popup pt-2 pb-3">
                    <div className="container">
                        <table></table>
                    </div>
                </div>
            </section> 
        </Aux>    
    )
}

}
export default PaymentSetting;