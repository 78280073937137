import React, { Component } from 'react';
import { Container, Button  } from 'reactstrap';
import { Link } from "react-router-dom";

export default class RequestDemoSection extends Component{
    constructor(props){
        super(props)
    }
    render() {
        return (
            <section className={`py-5 mb-0  ${ this.props.class } `} >
                <Container>
          
                    <div className="clearfix text-dark text-center" >
                        <h4 style={{fontSize: '40px'}} className="text-center my-4 text-secondary">Get started with no upfront investment</h4>
                        <p className="font-weight-light py-2">
                        Experience frictionless parking using our user friendly and efficient solutions to boost your business</p>
                        <p>
                            <a href="mailto:info@parkengage.com" className="text-white min-width  btn btn-primary">Contact Us for a Free Trial</a>
                       </p>
                       {/* <p>OR</p>
                        <p>
                        <Link  className="btn btn-light btn-outline-primary min-width" to={"/request-a-demo"} >Request  Demo </Link>
                        </p> */}
                    </div>
                </Container>
            </section> 
        )
    }
}
