import React from 'react';

const LocationMap =(props)=> {
    
    return (
        <div class="dev-scan-bg border bg-white shadow">
    <iframe
  width="100%"
  height="530"
  frameborder="0" style={{border:'0'}}
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3173.141607083613!2d-121.9763823851644!3d37.31547324622497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fca95b8d3a76d%3A0xf02c65eaf95cb498!2s4100%20Moorpark%20Ave%20%23203%2C%20San%20Jose%2C%20CA%2095117%2C%20USA!5e0!3m2!1sen!2sin!4v1605959435979!5m2!1sen!2sin&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;" allowfullscreen>
</iframe>

        </div>
    )
}

export default LocationMap;