import React from 'react';
import { connect } from "react-redux";
import * as actionCreators from "../../Redux/Actions";
import NotificationSystem from "react-notification-system";
import SimpleReactValidator from 'simple-react-validator';
import Aux from './../Wrapper/Auxiliary.js';

class SubscriberForm extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.notificationSystem = React.createRef();
    this.state = {
      subscriber: '',
      isLoading: false
    }
  }

  InputHandler = (event) => {
    let { value } = event.target;
    this.setState({ subscriber: value })
  }

  formSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const notification = this.notificationSystem.current;
      this.props.subscriberRequest({ email: this.state.subscriber }).then((data) => {
        if((this.props.subcriber_res.status == 201)){
          notification.addNotification({ title: 'Thank You', message: 'Your Email Id has been subscribed successfully.', level: 'success' });
        }else{
          let errors = this.props.subcriber_res.data.errors.detail.email[0];
          notification.addNotification({ title: 'Sorry', message: errors, level: 'error' });
        }
        this.setState({ isLoading: false });
      },(err)=>{
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Aux>
        <NotificationSystem ref={this.notificationSystem} />
        <div className="bg-primary py-4  ctaBlock  px-0 text-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <h4 className="p-0 m-0">Interested in learning more about ParkEngage? </h4>Interested in learning more about ParkEngage? Sign up for our mailing list to receive the latest news and updates
                        </div>
              <div className="col mt-3 mt-sm-0">
                <div className="input-group">
                  <input type="text" className="form-control customeInput" onChange={this.InputHandler} placeholder="Enter your Email Id" aria-label="Enter Your email Id" aria-describedby="button-addon2" />
                  <div className="input-group-append">
                    <button className="btn  btn-light customeButton" onClick={this.formSubmitHandler} type="button" id="button-addon2">{(this.state.isLoading) ? 'Please wait...' : 'Subscribe Now'}</button>
                  </div>
                </div>
                {this.validator.message('subscriber_email', this.state.subscriber, 'required|email')}
              </div>
            </div>
          </div>
        </div>
      </Aux>
    )
  }
}

const mapStateToProps = state => {
  return {
    subcriber_res: state.ContactFormReducer.subscriber_result,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    subscriberRequest: (data) => dispatch(actionCreators.subscriberRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberForm)