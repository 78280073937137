import React, { Component } from "react";
import { connect } from 'react-redux';
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Wrapper from "../Wrapper/Wrapper";
import Aux from "../Wrapper/Auxiliary";

class CareerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: 'Career With Us',
      headerDesc: 'Career With Us',
     
    }
  }

  render() {
    return (<Aux>
      <BreadCrumb headerTitle={this.state.headerTitle} headerName={this.state.headerTitle} />
      {/* <CompanyInfo /> */}
      <div className="container mb-4">
      <div className="card mb-3">
      <div className="card-body">
    <h3 className="card-title text-primary">Sales Manager US West and Southwest</h3>
    <p className="card-text">ParkEngage is the leading Parking Industry Vertical SaaS (Software as a Service) solution provider. It is the only digital platform built exclusively for the parking industry. With a powerful pay-as-you-go model, and virtually no upfront investment, the customer purchasing decision-making process goes quite smoothly. The Sales Manager will be responsible for growing the sales revenue, market share, and building the go-to-market business partnerships, in the western and southwestern US markets.</p>
   
  <a className="btn btn-primary" href="mailto:career@parkengage.com">Apply Now</a>
  <a className="btn btn-link float-right text-dark collapsed expandBtn" data-toggle="collapse" href="#one" role="button" aria-expanded="false" aria-controls="one">
  <small className="ShowBlock">View Details <i className="fa fa-angle-down"></i></small>  <small className="CloseDetatils">Close Details <i className="fa fa-angle-up"></i></small>
  </a>
    
    </div>

<div className="collapse" id="one">
<hr/>
    <div className="card-body">
<h5 >Role and Responsibilities</h5>
<ul><li>Identify, nurture, and procure new business within assigned multi-state region.</li>
	<li>Assume ownership of the entire sales cycle including identifying, qualifying, negotiating, contract execution, and handoff to the customer success team.</li>
	<li>Work with the marketing team as required to develop local market relationships.</li>
	<li>Travel within territory to prospect new business and maintain existing relationships in addition to managing business through telephone and virtual meetings.</li>
	<li>Attend tradeshows, network, and convey technology solutions to prospective clients.</li>
	<li>Meet/exceed sales objectives and quotas.</li>
	<li>Complete monthly reports detailing performance, forecasting, and project bookings.</li>
	<li>Prepare a weekly forecast and activity reports as requested.</li>
</ul>

<h5 className="mt-2" >Successful Candidate Experience</h5>
<ul><li>Experience selling parking solutions to small-to-mid size parking facility owners and operators within one or more sectors including commercial business districts, hotels, airports, healthcare, entertainment and sports venues, higher education, etc.</li>
	<li>Proven ability to prioritize, balance, and self-manage multiple client relationships.</li>
	<li>Demonstrated performance in prior sales roles with outstanding execution, problem-solving skills, resourcefulness, and creativity.</li>
	<li>Analytical and data-driven, proficient with CRM tools, MS Excel, Word, and PowerPoint.</li>
	<li>Excellent written and verbal communication skills, as well as strong presentation skills.</li>
	<li>Attention to detail, commitment to quality, customer focus and results-driven attitude.</li>
	
</ul>


</div>
</div>

      </div>


      <div className="card mb-3">
      <div className="card-body">
    <h3 className="card-title text-primary">Sales Manager US East and Southeast</h3>
    <p className="card-text">ParkEngage is the leading Parking Industry Vertical SaaS (Software as a Service) solution provider. It is the only digital platform built exclusively for the parking industry. With a powerful pay-as-you-go model, and virtually no upfront investment, the customer purchasing decision-making process goes quite smoothly. The Sales Manager will be responsible for growing the sales revenue, market share, and building the go-to-market business partnerships, in the western and southwestern US markets.</p>
   
  <a className="btn btn-primary" href="mailto:career@parkengage.com">Apply Now</a>
  <a className="btn btn-link float-right text-dark collapsed expandBtn" data-toggle="collapse" href="#two" role="button" aria-expanded="false" aria-controls="two">
    <small className="ShowBlock">View Details <i className="fa fa-angle-down"></i></small>  <small className="CloseDetatils">Close Details <i className="fa fa-angle-up"></i></small>
  </a>
    
    </div>

<div className="collapse" id="two">
<hr/>
    <div className="card-body">
<h5 >Role and Responsibilities</h5>
<ul><li>Identify, nurture, and procure new business within assigned multi-state region.</li>
	<li>Assume ownership of the entire sales cycle including identifying, qualifying, negotiating, contract execution, and handoff to the customer success team.</li>
	<li>Work with the marketing team as required to develop local market relationships.</li>
	<li>Travel within territory to prospect new business and maintain existing relationships in addition to managing business through telephone and virtual meetings.</li>
	<li>Attend tradeshows, network, and convey technology solutions to prospective clients.</li>
	<li>Meet/exceed sales objectives and quotas.</li>
	<li>Complete monthly reports detailing performance, forecasting, and project bookings.</li>
	<li>Prepare a weekly forecast and activity reports as requested.</li>
</ul>

<h5 className="mt-2" >Successful Candidate Experience</h5>
<ul><li>Experience selling parking solutions to small-to-mid size parking facility owners and operators within one or more sectors including commercial business districts, hotels, airports, healthcare, entertainment and sports venues, higher education, etc.</li>
	<li>Proven ability to prioritize, balance, and self-manage multiple client relationships.</li>
	<li>Demonstrated performance in prior sales roles with outstanding execution, problem-solving skills, resourcefulness, and creativity.</li>
	<li>Analytical and data-driven, proficient with CRM tools, MS Excel, Word, and PowerPoint.</li>
	<li>Excellent written and verbal communication skills, as well as strong presentation skills.</li>
	<li>Attention to detail, commitment to quality, customer focus and results-driven attitude.</li>
	
</ul>


</div>
</div>

      </div>


      </div>
      {/* <ClientInfo clientInfo={this.state.clientInfo} /> */}
      {/* <Team teamInfo={this.state.teamInfo} />
      <Testimonial testimonial={this.state.testimonials} /> */}
    </Aux>)
  }
}

const mapStateToProps = state => {
  return {
    isFetching:state.cont.isFetching,
    result:state.cont.result
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(CareerPage, "About-us"));