import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const ServiceLongDescription = (props)=> {
  
  const ListItems = props.features;
   const list =  ListItems.map( (list, key) => <li className='col-sm-6'  key={key}>{list}</li>  ) 
  
    return (
      
      <section className="pb-5">
      { props.features.length > 0 && 
        <div className="container">
          <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h2 className="font-weight-bold text-center text-dark pt-5 pb-3">Benefits:</h2>
          </div>
          </div>
          <div className="row justify-content-center">
          <div className="col-12 col-md-9 list-img">
          <ul className=" row ">
              {list}
            </ul>
          </div>
          </div> <div className="row justify-content-center">

          <div className="col-12 col-md-8 list-img">
            <p className="text-center pt-4"><span><Link to={"/request-a-demo"} className="text-white min-width  btn btn-primary">Schedule a Demo</Link></span></p>
          </div>
          </div>
        </div>
        }
       
      </section>

    )
}
export default ServiceLongDescription