import React, { Component } from "react";
import { connect } from 'react-redux';
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Wrapper from "../Wrapper/Wrapper";
import Aux from "../Wrapper/Auxiliary";
import CompanyInfo from "./CompanyInfo";
import ClientInfo from "./ClientInfo";
import Team from "./Team";
import Testimonial from "./Testimonials";
import usersImg from "./Assets/users.png";
import codeImg from "./Assets/code.png";
import briefcaseImg from "./Assets/briefcase.png";
import coffeeImg from "./Assets/coffee.png";
import slide1Img from "./Assets/slider/slide-1.png";
import slide2Img from "./Assets/slider/slide-2.png";
import slide3Img from "./Assets/slider/slide-3.png";
import team1Img from "./Assets/teams/team1.jpg";
import team2Img from "./Assets/teams/team2.jpg";
import tImg1 from "./Assets/testimonial-1.jpg";
import tImg2 from "./Assets/testimonial-2.jpg";
import tImg3 from "./Assets/testimonial-3.jpg";
import store from "../../Redux/Store"

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: 'Who We Are',
      headerDesc: 'Who We Are',
      clientInfo: {
        projectInfo: [
          { imgSrc: usersImg, title: '3456', description: 'CLIENTS SERVED' },
          { imgSrc: codeImg, title: '6456', description: 'LINES OF CODE' },
          { imgSrc: briefcaseImg, title: '423', description: 'NO.OF PROJECTS' },
          { imgSrc: coffeeImg, title: '2323', description: 'CUPS OF COFFEE' }
        ],
        callInfo:{
          phone:'+919999999999',
          email:'support@parkengage.com'
        },
        carouselInfo:[
          { imgSrc: slide1Img },
          { imgSrc: slide2Img },
          { imgSrc: slide3Img }
        ]
      },
      teamInfo:{
        teamMembers:[
          { imgSrc: team1Img, name: 'Dusana Semenov', position:'C.E.O', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.' },
          { imgSrc: team2Img, name: 'Miriam Jesus', position:'IT Manager', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.' },
          { imgSrc: team1Img, name: 'Dusana Semenov', position:'C.E.O', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.' },
          { imgSrc: team2Img, name: 'Miriam Jesus', position:'IT Manager', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.' }
        ]
      },
      testimonials:{
        items:[
          {
            src: tImg1,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          },
          {
            src: tImg2,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          },
          {
            src: tImg3,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          }
        ]
      }
    }
  }

  render() {
    return (<Aux>
      <BreadCrumb headerTitle={this.state.headerTitle} headerName={this.state.headerTitle} />
      <CompanyInfo />
      <ClientInfo clientInfo={this.state.clientInfo} />
      {/* <Team teamInfo={this.state.teamInfo} />
      <Testimonial testimonial={this.state.testimonials} /> */}
    </Aux>)
  }
}

const mapStateToProps = state => {
  return {
    isFetching:state.cont.isFetching,
    result:state.cont.result
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(AboutUs, "About-us"));