import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import * as actionCreators from "./../Redux/Actions/index";
import Aux from '../pages/Wrapper/Auxiliary';
import BreadCrumb from './../pages/BreadCrumb/BreadCrumb';

class Logout extends React.Component  {
    constructor(props){
        super(props)
        localStorage.clear();
       //
    }

    componentDidMount(){
       this.props.requestForLogoutHandler()

    }

    render(){
        
        setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/'; 
        }, 2000);
        return (
            <Aux>
            <BreadCrumb headerTitle={"Logout"} headerName={"Logout"} headerContent={""}></BreadCrumb>
                
                <section id="content-section" className="" style={{marginBottom:40+'px', marginTop:40+'px'}}>
             <div className="container">
               <div className="text-center my-3">
                 <div class="col-12 w-100">
                      <p class="loading alert alert-primary text-center">Please wait... <i class="fas fa-circle-notch fa-spin"></i></p>
                 </div>
              </div>
             </div>
             </section>
            </Aux>
            
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        requestForLogoutHandler: () => dispatch(actionCreators.logoutRequest({}) )
    }
}

export default connect( null, mapDispatchToProps )(Logout)
