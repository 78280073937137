import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import './style.scss';

import RequestDemoSection from './../home/requestDemoSection';
import Aux from './../Wrapper/Auxiliary';

import ServicesImage11 from './assets/inventory.png';
import ServicesImage12 from './assets/dashboard-service.png';
import ServicesImage2 from './assets/ticket-less.png';
import ServicesImage3 from './assets/book-yellow.png';
import ServicesImage4 from './assets/online-reserv.png';
import ServicesImage5 from './assets/Promotion.png';
import ServicesImageACMC from './assets/tvp-icon.png';
import ServicesImageALPR from './assets/anpr.png';
import ServicesImageTMR from './assets/monthly-payment-bck.png';
import ServicesImageRAD from './assets/rad.png';
import ServicesImageWLA from './assets/wla.png';

export default class ServicesComponent extends Component {

    constructor(props) {
        super(props);
    }

    ServicesData = [
        
        {   serviceId:5,
            serviceName: 'Touchless Self-Parking',
            serviceSlug: 'touchless-parking-system',
            description: `With our Touchless Parking Service, create a seamless parking experience for customers in valet and self-park facilities.
            `,
            serviceImages: [ServicesImage2]
        },
        {   serviceId:6,
            serviceName: 'Touchless Valet Parking',
            serviceSlug: 'valet-parking-system',
            description: `Our Touchless Valet Service creates a convenient experience for parkers, while streamlining your valet staff operation.`,
            serviceImages: [ServicesImageACMC]
        },
        {   serviceId:3,
            serviceName: 'Touchless Permit Parking ',
            serviceSlug: 'permit-parking-system',
            description: `Permit customers are your most loyal and profitable base. Provide personalized service with our Touchless Permit Parking Solution.`,
            serviceImages: [ServicesImage3]
          },
          {   serviceId:3,
            serviceName: 'Touchless Monthly Parking ',
            serviceSlug: 'monthly-parking-system',
            description: `Our Monthly Parking solution provides a seamless and comfortable experience for monthly parkers, while creating and encouraging brand loyalty.`,
            serviceImages: [ServicesImageTMR]
          },
          {   serviceId:4,
            serviceName: 'Online Parking Reservations',
            serviceSlug: 'online-reservations',
            description: `Offer your regular and prospective patrons the opportunity to make online reservations to park in any of your parking facilities.`,
            serviceImages: [ServicesImage4]
        },
  
        {   serviceId:1,
            serviceName: 'Aggregator and Channel Management',
            serviceSlug: 'parking-inventory-management-system',
            description: `Publish and manage your own reservable parking inventory for all channel partners in one platform.`,
            serviceImages: [ServicesImage11, ServicesImage12]
        },

        {   serviceId:2,
            serviceName: 'Promotions and Loyalty',
            serviceSlug: 'promotion',
            description: `Provide convenient, touchless parking and create brand loyalty through our Promotion and Loyalty Service`,
            serviceImages: [ServicesImage5]
            
        },

      
        
        
        {   serviceId:8,
            serviceName: 'ALPR Based Parking Enforcement',
            serviceSlug: 'parking-enforcement-system',
            description: `ALPR-based solutions allow Parking Operators and Owners to implement more accurate parking enforcement procedures, increasing revenues and streamlining operations.`,
            serviceImages: [ServicesImageALPR]
        },
        // {   serviceId:9,
        //     serviceName: 'Accounts Receivable (A/R) Management',
        //     serviceSlug: 'parking-AR-system',
        //     description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `,
        //     serviceImages: [ServicesImageARM]
        // },
        {   serviceId:10,
            serviceName: 'Reporting and Analytics Dashboards',
            serviceSlug: 'analytics-system',
            description: `Reporting and Analytics Dashboards for Parking Operators and Owners to understand customer behavior, evaluate markets, optimize operations, increase revenue and profit margins.`,
            serviceImages: [ServicesImageRAD]
        },
        {   serviceId:11,
            serviceName: 'White Label Apps',
            serviceSlug: 'white-label-apps',
            description: `White Label Apps for the Parking Operators and Parking Facilities Owners who would like to offer a feature rich Parking App to their customers to get personalized parking experience`,
            serviceImages: [ServicesImageWLA]
        },
        
        
    ]
    

rediretToServiceDetails = (service)=> {
    this.props.history.push('/services/'+service.serviceSlug, service.serviceId);
}
    render() {
        return (
            <Aux>
                <BreadCrumb headerTitle={"Products"} headerName={"WHAT WE DO"} headerContent={""} />
                <section id="" className="mt-5 mb-3">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="text-dark font-light">ParkEngage offers a cloud-based digital platform designed to give parking owners and operators the tools to maintain control of their parking assets, increase revenues, and enhance customer service. </h2>
                        </div>
                        <div className="row mb-4 justify-content-center">
                        </div>
                    </div>
                </section>
                {this.ServicesData.map((service, index) => {
                    return (
                        <section key={index} className={`py-5 ${index % 2 != 0 ? 'mb-0 bg-secondry' : ''}`} >
                            <div className="container">
                                <div className={`row align-items-center justify-content-center ${index % 2 == 0 ? 'left-image' : ''}`}>

                                    <div className="col-12 col-md-6 col-sm-6 content-wrapper">
                                        <h2 className={`font-weight-bold pt-3 pb-2 mb-0  text-dark ${index % 2 != 0 ? 'text-small-center' : ''}`}>{service.serviceName}
                                        </h2>
                                        <p className={`font-weight-light ${index % 2 != 0 ? 'text-small-center' : ''}`}>
                                            {service.description}
                                        </p>
                                        <p className={`pt-1 pr-4 ${index % 2 != 0 ? 'text-small-center' : ''}`} >
                                           <button style={{cursor:'pointer'}} onClick={()=>this.rediretToServiceDetails(service)} role="button" className="btn btn-primary btn-outline-primary">Read more  </button>
                                        </p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-sm-6 image-wrapper">
                                     <img className="img-fluid px-5 pt-4" src={service.serviceImages[0]} alt={service.serviceName} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                })}
                <div className="border-top">
                <RequestDemoSection ></RequestDemoSection></div>
            </Aux>
        )
    }

}