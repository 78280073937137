import React, { Component } from 'react';

export default class TitleContentSection extends Component {
    constructor(props){
        super(props);
    }

    render() {
      return ( 
        <div className="row justify-content-center" data-aos="fade-zoom-in">
          <div className="text-center mb-0 mb-md-4 mt-5 col-md-9">
          {(this.props.tag)?<this.props.tag style={{ fontSize:'40px', marginBottom:'30px'}} className="text-dark">{this.props.title}</this.props.tag>:<h2 className="text-dark  ">{this.props.title}</h2>}
          <p className="mb-0 pb-0" >{this.props.content} </p>
        </div>
      </div>
      )    
    }
}