import React from "react";
import abtImg from './../../assets/img/aboutus-bg3.jpg';
const CompanyInfo = (props) => {
  return (
    <section className="mt-5 mb-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            <p className="mb-1"><img className="aboutImg" alt="client information" src={abtImg}  style={{maxWidth: "265px", marginLeft:"15px", float: "right", marginTop:"6px", marginBottom:"4px"}} />ParkEngage is the leading digital cloud platform for the parking industry. ParkEngage offers touchless parking solutions designed to enhance the entire customer journey from arrival to departure, as well as create more efficient operating solutions for your operation and your staff. 
            <br></br><br></br>
            ParkEngage technologies are aimed at providing an entirely touchless parking experience for users, allowing them to arrive at your parking facility, park, pay, and depart, seamless and touch-free through their mobile devices. ParkEngage services also help to significantly enhance parker engagement, while helping to create new revenue streams for owners and operators.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyInfo;