import React from 'react';
import {withRouter} from 'react-router-dom';
import FooterAddress from './footerAddress';
import FooterMenu from './footerMenu';

import CpoyrightMenu from './copyrightMenu';
import SocialIcons from './socialicons';
import LoginComponent from './../../login/login';

import { Container, Row, Col, Button  } from 'reactstrap';

class FooterComponent extends React.Component {
    constructor(props){
        super(props)
        const isAuthenticated = false
    }
    shouldComponentUpdate( prevProps, nextState ){
        if(localStorage.getItem('access_token')) {
            this.isAuthenticated = true
           // $("#myModal").modal("hide"); 
        }else {
            this.isAuthenticated = false
        }
        return true
    }

    render() {
        return (
            <div>
            <footer className="pt-5 bg-dark-gray">
                <Container>
                    <Row>
                    <Col sm="12" md="9" lg="auto" className="mb-1">
                        <FooterAddress></FooterAddress>
                        Follow us on -
                        <span className="topsocial gap-2 mt-4">
                          
                    <a target="_blank" href="https://www.facebook.com/ParkEngage/"><i className="la la-2x la-facebook"></i></a>
                    <a href="https://twitter.com/ParkEngageInc" target="_blank" ><i className="la la-2x la-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/parkengage" target="_blank" ><i className="la la-2x la-linkedin"></i></a>
                    <a href="https://www.instagram.com/parkengageinc/" target="_blank" ><i className="la la-2x la-instagram" style={{fontWeight:'bolder'}} ></i></a>
                    </span>

                       </Col>
                    <Col >
                        <FooterMenu></FooterMenu>
                    </Col>
                      
                    </Row>
                </Container>
                <div className="baseLine py-1">
                <Container>
                    <Row className="row align-items-center">
                            <CpoyrightMenu></CpoyrightMenu>
                            {/* <Col className="col-sm-auto ml-auto text-center ">
                                <SocialIcons></SocialIcons>
                            </Col> */}
                    </Row>
                </Container>
                </div>
                
            </footer>
            {
                    <LoginComponent></LoginComponent>
                }
            </div>
        )
    }
}

  export default withRouter(FooterComponent)
  