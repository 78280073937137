import { combineReducers } from "redux";
import ContentReducer from "./ContentReducer";
import DemoRequestReducer from "./DemoRequestReducer";
import FetchServiceReducer from './DemoServiceGetReducer';
import TrialRequestReducer from './TrialRequestReducer';
import LoginRequestReducer from './LoginRequestReducer';
import GeneratePasswordReducer from './GeneratePasswordReducer';
import ForgetPasswordReducer from './ForgetPasswordReducer';
import ResetPasswordReducer from './ResetPasswordReducer';
import ContactFormReducer from './ContactFormReducer';
import FetchPaymentHistoryReducer from './FetchPaymentHistoryReducer';

const RootReducer = combineReducers({
    cont:ContentReducer,
    reqDemo:DemoRequestReducer,
    trialRequest:TrialRequestReducer,
    fetchDemoServices: FetchServiceReducer,
    loginRequest: LoginRequestReducer,
    genetatePasswordRequest : GeneratePasswordReducer,
    forgetPasswordReducer: ForgetPasswordReducer,
    ResetPasswordReducer: ResetPasswordReducer,
    ContactFormReducer: ContactFormReducer,
    FetchPaymentHistoryReducer: FetchPaymentHistoryReducer
})

export default RootReducer;