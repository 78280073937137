import React, { Component, PropTypes } from 'react';
import FontAwesome from 'react-fontawesome';
import AdminListTable from './adminListTable';

// require('./AdminPage.scss');

/**
 * Wrapper for the admin list table to standardize layouts across admin pages
 */
export default class AdminListPage extends Component {
    
    render() {
        
        const AddNewButton = (
            <button className="btn btn-primary btn admin-page-add-new my-2" onClick={this.props.handleAddClick}>
                <FontAwesome name="plus" /> <span className="button-copy">Add New</span>
            </button>
        );

        return (
            <div className={`card admin-page-section ${this.props.className}`}>
                <div className='card-header'>
                    <h2 className="pull-left my-2">{this.props.title}</h2>
                    {this.props.handleAddClick  && AddNewButton}
                </div>
                <AdminListTable
                    columns={this.props.columns}
                    data={this.props.data}
                    curPage={this.props.curPage}
                    perPage={this.props.perPage}
                    total={this.props.total}
                    getNewRecords={this.props.getNewRecords}
                    onRowClick={this.props.onRowClick}
                    showSearch={this.props.showSearch}
                    search={this.props.search}
                    handleFilterClick = {this.props.handleFilterClick}
                />
            </div>
        );
    }
}

// AdminListPage.propTypes = {
//     className: PropTypes.string,
//     title: PropTypes.string,
//     handleAddClick: PropTypes.func,
//     columns: PropTypes.array,
//     data: PropTypes.array,
//     getNewRecords: PropTypes.func,
//     curPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     showSearch: PropTypes.bool,
//     onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//     condensed: PropTypes.bool
// };
