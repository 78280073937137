import React, { Component } from 'react';
import Aux from '../Wrapper/Auxiliary';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import * as actionCreators from "../../Redux/Actions";
import { connect } from "react-redux";
import {EncryptionHandler} from './../../config/encryption';
import creditCard from './assets/credit-card.png';
import creditCardIcon from './assets/credit-card-icon.svg';
import infoGrayImg from './assets/info-gray.svg';

import serviceImg3 from "./assets/monthly-payment.png";
import serviceImg1 from "./assets/inventory.png";
import serviceImg2 from "./assets/mobile-barcode.png";
import serviceImg4 from "./assets/mobile-valet.png";
import serviceImg5 from "./assets/Promotion.png";
import planImg from './assets/monthly-payment.png';
import SimpleReactValidator from 'simple-react-validator';
import InputMask from 'react-input-mask';
import NotificationSystem from "react-notification-system";
import CryptoJS from 'crypto-js';
import $ from 'jquery'; 



class AddToCart extends Component {

  servicesImg ={
    1: serviceImg1,
    2: serviceImg2,
    3: serviceImg3,
    4: serviceImg4,
    5: serviceImg5
  }
    

    servicesData = [];
    service_id = '';
    selected_plan_id = '';
    formDataUpdated= {};
    serviceName = '';
    dateMask = '99/99';
    cardMask = '9999 9999 9999 9999';
    security_codeMask = '9999';
    encryptKey = 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=';
    token = '';
    constructor(props){
        super(props)
        $(function () {
          $('[data-toggle="tooltip"]').tooltip({
            animated: 'fade',
            placement: 'top',
            html: true
          })
        })
        this.validator = new SimpleReactValidator();
        this.notificationSystem = React.createRef();
        const DisableBtn = {
            background: '#4A90E2', color: '#fff'
        }

        if(!props.history.location.state){
            this.props.history.push('/request-trial');
            return false;
        }
       let formData = props.history.location.state;
      //  console.log(formData, 'here')
        this.servicesData = formData.allServices
        this.service_id = formData.service_plan_id
        this.selected_plan_id = formData.membership_plan_id;
        this.token = formData.token
       // this.servicesImg = formData.servicesImg
        this.planType = formData.type
        this.PlanDays = formData.days
        this.existing_membership_plan_id = formData.existing_membership_plan_id
        if(!this.token){
          this.props.history.push('/request-trial');
        }
        this.state = {
            service_plan_id: {
              value: formData.service_plan_id,
              name: 'service_plan_id',
              valid: false,
            },
            membership_plan_id: {
              value: formData.membership_plan_id,
              name: 'membership_plan_id',
              valid: false,
            },
            
            formIsValid: false,
            loading: false,
            amount : formData.amount,
            finalAmount: formData.amount,
            is_trial: formData.is_trial,
            showPayment: false,
            subscribeToken: this.token,
            paymentsFields: {
              name_on_card: {
                name: 'name_on_card',
                value: '',
                error: '',
                placeholder: 'Name on Card',
                valid: false
              },
              card_number: {
                name: 'card_number',
                value: '',
                error: '',
                placeholder: 'Card Number',
                valid: false
              },
              expiration_date: {
                name: 'expiration_date',
                value: '',
                error: '',
                placeholder: 'Expiration Date',
                valid: false

              },
              security_code: {
                name: 'security_code',
                value: '',
                error: '',
                placeholder: 'Security Code',
                valid: false
              },
              zip_code: {
                name: 'zip_code',
                value: '',
                error: '',
                placeholder: 'Zip Code',
                valid: false
              }
            }
          };

          this.selected_plan_id = this.state.membership_plan_id.value;
    }

      changeCardFields = (event)=> {
        let { name, value } = event.target;
        let updated = { ... this.state.paymentsFields}
        let error = '';
        let isValid = false;
        if(name !== "name_on_card"){
          updated[name].value = value.trim();
        }else { updated[name].value = value }
        updated[name].error = error;
        updated[name].valid = isValid;
        this.setState({paymentsFields:updated});
      }
      SubmitPaymentForm = (event)=> {
        event.preventDefault();
         if (this.validator.allValid()) {
        const notification = this.notificationSystem.current;
        let {formIsValid, subscribeToken} = this.state;
        let error='';
        let isValid = false;
        let name = '';
        let value = '';
        let invalid = false;
        for (let field in this.state.paymentsFields) {
          formIsValid = this.state.paymentsFields[field].valid
        } 
          this.setState({loading:true});
          let cardData = {};
          let formData = {};
          let {paymentsFields, finalAmount} = this.state;
          for (let field in paymentsFields) {
            cardData[field] = paymentsFields[field].value;
          }
          formData['nonce'] = EncryptionHandler(cardData);
          this.formDataUpdated['nonce'] = formData['nonce']
          this.formDataUpdated['membership_plan_id'] = this.selected_plan_id;
          this.formDataUpdated['service_id'] = this.service_id;
          this.formDataUpdated['subscribeToken'] =  subscribeToken;
          this.formDataUpdated['payment_type']= "credit_card";
            this.formDataUpdated['plan_type'] =this.planType
            this.formDataUpdated['no_of_days'] =this.PlanDays
            this.formDataUpdated['existing_membership_plan_id'] =this.existing_membership_plan_id
          this.props.requestForPaidPlan(this.formDataUpdated).then((data) => {            
            if(data['error']){
              let errors = data.error.response
              let errorsMsg = errors['data'].errors;
             let errorsMsg1 = errorsMsg.message
                notification.addNotification({
                  title: 'Sorry!',
                  message: errorsMsg1,
                  level: 'error'
              });
              this.setState({ loading: false });
              return false;
            }else if( data['data'] ){
              let SeletedPlanData = { serviceId: this.service_id,planId:this.selected_plan_id, plan_type :this.planType,
                request_trial: 1, services: this.servicesData, amount:finalAmount } 
              this.props.history.push('/thank-you', SeletedPlanData);
              notification.addNotification({
                title: 'Thank You',
                message: 'Your request has been submitted successfully',
                level: 'success'
            });
            }else {
              notification.addNotification({
                title: 'Sorry, Something went Wrong!',
                message: 'Please Try again letter!',
                level: 'error'
            });
            }
            
            notification.addNotification({
                title: 'Thank You',
                message: 'Your request has been submitted successfully, We will get in touch with you soon',
                level: 'success'
            });
            this.setState({ loading: false });
            
          });

        } else {
          this.setState({formIsValid:false})
          this.validator.showMessages();
          this.forceUpdate();
        }
      }

      backButton = ()=> {
          this.props.history.push('/request-trial');
      }

    render(){
      const { paymentsFields, amount, finalAmount, loading} = this.state;
      // this.validator.purgeFields();
        return (
             <Aux>
                   <NotificationSystem ref={this.notificationSystem} />
                <BreadCrumb headerTitle={"Products"} headerName={"Buy Plan / Cart"} headerContent={"Your one stop for all your parking solutions need"}></BreadCrumb>
                <section id="content-section">
                    <div className="container">
                      <div className="text-center border-bottom my-3">
                        <h2 className="text-dark font-weight-light">To get started you need to share your details  with us</h2>
                     </div>
                    </div>

                    <div className="cart mt-3 pt-2 pb-3">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-8 mb-4 ">
      
      <div className="tab-content " >
          
          <div className="request-trial-form cart-payment ">
            <h4 className="text-left text-dark mb-4 cart-title">Payment Selection</h4>
            <form>
              <div className="light-box p-4">
              <div className="row">
              <div className="col-md-8 col-lg-9 mb-4">
                <div className="">
                  <label className=""><b>Credit Card</b></label>
                  <p>Safe money transfer using your  Visa, American Express.</p>
                </div>
              </div>
              <div className="form-group col-md-4 col-lg-3 mb-4 text-left text-lg-center">
                <img className="payment-card" alt="credit-card-image" src={creditCard}/>
              </div>
              <div className="form-group col-md-6 mb-4">
                <label>NAME ON CARD</label>
                <input type="text" name={paymentsFields.name_on_card.name} value={paymentsFields.name_on_card.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                 {this.validator.message('Name on Card', paymentsFields.name_on_card.value, 'required|min:3')}
              </div>
              <div className="form-group col-md-6 mb-4">
                <label htmlFor="cardnumber">CARD NUMBER</label>
                <InputMask type="tel" mask={this.cardMask} alwaysShowMask="false" maskChar={null} className="form-control" name={paymentsFields.card_number.name} value={paymentsFields.card_number.value}  onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                <span className="float-right info-input" data-toggle="tooltip" data-html="true" data-placement="top" title="Enter your Credit card number">
                  <img src= {creditCardIcon} alt="credit-card-icon" />
                 </span>
             
                 
                 {this.validator.message('Card Number', paymentsFields.card_number.value, 'required|card_num')}
              </div>
              
              <div className="form-group col-md-3 mb-4">
                <label>EXPIRY DATE</label>
                <InputMask type="tel" name={paymentsFields.expiration_date.name} alwaysShowMask="false" mask={this.dateMask} maskChar={null} value={paymentsFields.expiration_date.value} className="form-control" placeholder="MM/YY" onChange={this.changeCardFields} onBlur={this.changeCardFields}/>
                
                 {this.validator.message('expiry date ', paymentsFields.expiration_date.value, 'required|card_exp')}
              </div>
              <div className="form-group col-md-3 mb-4">
                <label>CVV CODE</label>
                <InputMask type="tel" alwaysShowMask="false" mask={this.security_codeMask} maskChar={null} name={paymentsFields.security_code.name} value={paymentsFields.security_code.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields} />
                <span className="float-right info-input" data-toggle="tooltip" data-placement="top" title="CVV code of Credit Card">
                  <img src={infoGrayImg} alt="icon" />
                 </span>
                
                 {this.validator.message('cvv code', paymentsFields.security_code.value, 'required|integer|min:3')}
              </div>
              <div className="form-group col-md-3 mb-4">
                <label>Zip Code</label>
                <InputMask type="tel" name={paymentsFields.zip_code.name} alwaysShowMask="false" mask="999999" maskChar={null} value={paymentsFields.zip_code.value} className="form-control" onChange={this.changeCardFields} onBlur={this.changeCardFields}/>
                
                 {this.validator.message('Zipcode ', paymentsFields.zip_code.value, 'required|integer|min:5')}
              </div>
              </div>
            
              <div className="my-2 pb-5">           
           { loading &&  
            <button style={{background: '#4A90E2', color: '#fff'}} type="submit" disabled className="btn btn-primary btn-outline-primary float-left disabled">Complete Order <i className="la la-angle-right"></i></button>
           }{!loading &&
            <button onClick={this.SubmitPaymentForm} type="submit" className="btn btn-primary btn-outline-primary float-left">Complete Order <i className="la la-angle-right"></i></button>
           
            }
          </div>
            
            </div>
            
           
        </form>
          
          </div>
      </div>
      </div>

      <div className="col-lg-4 mb-4">
                 <aside style={{height:'100%'}} className="cart-value mb-4">
                    <h4 className="d-flex justify-content-between font-weight-light mb-2">
                    <b>Cart</b>
                    </h4>
                    { this.servicesData.map( (service,index) => {
                            if(this.service_id == service.id){
                            return(

                  <table key={index} className="table-borderless  w-100 table-sm">
                  <tbody>
                              <tr  scope="row">
                              <td >
                               <a><img style={{width:'100px'}} alt={service.full_name} className="img-fluid" src={this.servicesImg[this.service_id]} /></a>
                              </td>
                              <td>
                              <p>{service.full_name}</p>
                              <p><b className="font-weight-bold">$ {amount}</b></p>
                              </td>
                            </tr>
                           
                              {service.membership_plan.map( (plan,index) => 
                               {
                                 if(plan.id == this.selected_plan_id) {
                                   return (
                                    <tr key={index} className="border-top"  scope="row">
                                    <td colSpan="2" scope="col">
                                  <div className="row mt-1">
                                    <div className="col">
                                    Plan
                                    </div>
                                    <div className="col font-weight-bold text-right">
                                      {plan.name}
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col">
                                    Plan Type
                                    </div>
                                    <div className="col font-weight-bold text-right">
                                    {(this.planType ==='1') ? 'Monthly': 'Yearly'}
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col">
                                   No. of Users
                                    </div>
                                    <div className="col font-weight-bold text-right">
                                     {plan.no_of_users}
                                    </div>
                                  </div>
                                    
                                    </td>
                                 </tr>
                                   )
                                 }
                               }
                                 )}
                                 </tbody>
                            </table>

) } } )
                          }
                        
                        <table className="cart-box-text ">
                        <tbody>
                          <tr>
                            <td><span>Subtotal</span></td>
                            <td><span>$ {finalAmount}</span></td>
                          </tr>
                          <tr>
                            <td><span>Service Tax</span></td>
                            <td><span>N/A</span></td>
                          </tr>
                          <tr>
                            <td><span>Total</span></td>
                            <td><h4 className="border-0">$ {finalAmount}</h4></td>
                          </tr></tbody>
                       </table>
                 </aside>
                 </div>


                        </div>
                        </div> 
                        </div> 
                </section>

            </Aux>

        )
    }
}
const mapStateToProps = state => {
  return {
    response: state.trialRequest.result
  };
};


const mapDispatchToProps = dispatch => {
  return {
    requestForPaidPlan: (data) => dispatch(actionCreators.requestForPaidPlan(data))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
