import * as actionTypes from './../ActionTypes';
import  Axios  from './../../config/axios';

export const ResetPasswordRequest = ()=> {
    return {
        type: actionTypes.RESET_PASSWORD_REQUEST
    }
}

export const ResetPasswordSuccess = (res)=> {
    return {
        type : actionTypes.RESET_PASSWORD_SUCCESS,
        data : res
    }
}
export const ResetPasswordFail = (error)=> {
    return {
        type : actionTypes.RESET_PASSWORD_FAIL,
        data: error.response
        
    }
}

export const ResetPasswordRequestService = (data)=> {
    return dispatch => {
       dispatch( ResetPasswordRequest() ) ;
       return Axios.post('/user/reset-password', data)
       .then ( res => {
           return dispatch ( ResetPasswordSuccess( res ) )
       } ).catch( error => {
           return dispatch ( ResetPasswordFail (error) )
       } )
    }
}

export default ResetPasswordRequestService;