import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Input from './../../Global/Input/Input'
import HeaderTitleDescription from './header-title';
import * as actionCreators from "./../../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import $ from 'jquery'; 

class ContactForm extends React.Component {
    
    constructor ( props ) {
        super(props)
        $(function () {
        $('.textarea1').on('input', function () { 
          this.style.height = 'auto'; 
            
          this.style.height =  
                  (this.scrollHeight) + 'px'; 
      })
    })
        this.validator = new SimpleReactValidator();
        this.notificationSystem = React.createRef();
        this.state = {
            countryRequired: false,
            contactForm: {
                first_name: {
                    value: '',
                    name: 'first_name',
                    type:'text',
                    label: 'First Name',
                    validation:'required|alpha_space'
                  },
                  last_name: {
                    value: '',
                    name: 'last_name',
                    type:'text',
                    label: 'Last Name',
                    validation:'required|alpha_space'
                  },
                  email: {
                    value: '',
                    name: 'email',
                    type:'text',
                    label: 'Email Address',
                    validation:'email'
                  },
                 
                  phone: {
                    value: '',
                    name: 'phone',
                    type:'number',
                    label: 'Phone Number',
                    validation:'integer|max:10||min:10'
                  },
                  address: {
                    value: '',
                    name: 'address',
                    type:'text',
                    label: 'Address',
                    validation:'required'
                  },
                  company_name: {
                    value: '',
                    name: 'company_name',
                    type:'text',
                    label: 'Company Name',
                    validation:'required'
                  },
                  city: {
                    value: '',
                    name: 'city',
                    type:'select',
                    label: 'City',
                    validation:'required'
                  },
                  state: {
                    value: '',
                    name: 'state',
                    type:'text',
                    label: 'State',
                    validation:'required'
                  },
                  zipcode: {
                    value: '',
                    name: 'zipcode',
                    type:'number',
                    label: 'Zip Code',
                    validation:'integer|max:6||min:5'
                  },
                  
              },
        
            country: '',
            formIsValid: false,
            loading: false,
            formSent: false
        }
    }

    UpdateCountry = (event)=> {
        const { name, value } = event.target;
        let {country} =  this.state
        if(name == 'country'){
          this.setState({country: value, countryRequired : true})
        }
      }

      InputHandler = (event) => {
        let { name, value } = event.target;
        this.validator.showMessageFor(name);
        let updated = { ... this.state.contactForm}
        let phoneRegix = /^[\d]+$/g;
       
        if(name ==='phone'){
          if(value.match(phoneRegix)){
            if( value.length > 10 ){
              value = updated[name].value
            }else {  value = value  }
          }
          else {  
            if(value.length ===0){
              value = '';
            }else {
              value = updated[name].value;
            }
           }
        }
        if(name ==='zipcode'){
          if(value.match(phoneRegix)){
            if( value.length > 6 ){
              value = updated[name].value
            }else {  value = value  }
          }
          else {  value = ''; }
        }
        
        updated[name].value = value;
        this.setState({contactForm:updated})
      }

    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }
        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }
        return isValid;
    }   

    componentWillMount() {  
        this.props.requestFetchCountry().then(() => {
          if(this.props['countries']) {
            this.countryData = this.props.countries.data;
            this.setState({ countryData: this.countryData });
          }
        });
    
      }

    submitHandler = (event) => {
        event.preventDefault();
        const { country, countryRequired} = this.state
        const notification = this.notificationSystem.current;
        const formData = {};
        for (let formElementIdentifier in this.state.contactForm) {
            formData[formElementIdentifier] = this.state.contactForm[formElementIdentifier].value;
        }
        if (this.validator.allValid()) {
                this.setState({countryRequired : false, loading: true })
            formData['country'] = country
             this.props.ContactFormHandler(formData).then((data) => {
             
                for (let formElementIdentifier in this.state.contactForm) {
                     this.state.contactForm[formElementIdentifier].value = '';
                }
                this.setState({ loading: false });
                 if(this.props.result.status ==201){
                   this.setState({formSent: true});
                    // notification.addNotification({  title: 'Thank You',  message: 'Your request has been submitted successfully',
                    //     level: 'success'  });
                          this.props.history.push('/thank-you', 'contactus' );                    
                 }else{
                   let res = this.props['result'].response;
                   res = res.data
                  
                   if(res['errors']){
                     let errors = res['errors']
                     if(errors['detail']){
                       let details = errors['detail']
if ( details['city'] ){ notification.addNotification({ title: errors['message'],  message: details.city[0],  level: 'error'}); }
if ( details['country'] ){ notification.addNotification({ title: errors['message'],  message: details.country[0],  level: 'error'}); }
if ( details['email'] ){ notification.addNotification({ title: errors['message'],  message: details.email[0],  level: 'error'}); }
if ( details['first_name'] ){ notification.addNotification({ title: errors['message'],  message: details.first_name[0],  level: 'error'}); }
if ( details['phone'] ){ notification.addNotification({ title: errors['message'],  message: details.phone[0],  level: 'error'}); }
                     }
                   }
              // notification.addNotification({  title: 'Sorry',  message: 'Something went wrong!, Try after some time',
              //           level: 'error'
              //       });
                 }
                
            });
        }
        else {
          this.validator.showMessages();
            this.forceUpdate();
            // notification.addNotification({
            //     title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
            //   });
            this.setState({ loading: false });
            let  updatedForm = { ...this.state.contactForm }
            for (let formElement in updatedForm) {
                updatedForm[formElement].touched = true;
            }
            this.setState({ loading: false, contactForm: updatedForm });
        }

        
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const { value } = event.target;
        const numberRex = /^[0-9.\b]+$/;
        if(inputIdentifier == 'phone' && value.match(numberRex) == null && value !== ''){
            return false;
        }
        if(inputIdentifier == 'pincode' && value.match(numberRex) == null && value !== ''){
            return false;
        } 
        
        const updatedDemoForm = { ...this.state.contactForm }
        const updatedFormElement = { ...updatedDemoForm[inputIdentifier] }

        updatedFormElement.value = value
        updatedFormElement.valid = this.checkValidity( updatedFormElement.value, updatedFormElement.validation );
        updatedFormElement.touched = true
        updatedDemoForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true
        for (let inputIdentifier in updatedDemoForm ) {
            formIsValid = updatedDemoForm[inputIdentifier].valid && formIsValid
        }
        this.setState( { contactForm: updatedDemoForm , formIsValid: formIsValid })
    }
    

    render() {  
        let { contactForm , countryData} = this.state;
        let FieldsArr = []; let i = 0;
    for( let formData in contactForm ){
      FieldsArr[i] = { name: formData, value:contactForm[formData].value, label:contactForm[formData].label,
        type:contactForm[formData].type, validation:contactForm[formData].validation  }
        i++;
    }

        const formElements = [];
        const numberRex = /^[0-9.\b]+$/;
        for( let key in this.state.contactForm ){
            formElements.push(
                { 
                  id: key,
                  config : this.state.contactForm[key]
                }
            )
        }

        return (
            <div class="clearfix">
                <NotificationSystem ref={this.notificationSystem} />
            <HeaderTitleDescription></HeaderTitleDescription>
            <div className="m-auto ">
              <div className="row justify-content-center">
              <div className="col-12 col-sm-9 ">
        
        <div className="row-cols-1  row-cols-sm-2 row" >
          
    
              { FieldsArr.map( (field, index) =>{ 
                    let validationTxt= '';
                    if(field.name == 'email') { validationTxt='Email Id' }else { validationTxt = field.label }
                    if(field.name == 'address') { validationTxt='Address' }else { validationTxt = field.label }
                    if(field.name == 'address') { 
                      return(
                    <div key={index} className="col col-sm-12 mb-2">
  <div className="form-group  initial no-value">

{/* <RedditTextField label={field.label}
fullWidth defaultValue={field.value}  variant="filled" multiline rows={4} onChange={this.InputHandler} onBlur={this.InputHandler} name={field.name} id={field.name}
/> */}

{/* <label htmlFor={field.name}>Let us know how ParkEngage can help you.</label> */}


<textarea type="text" id={field.name} className="form-control textarea1" name={field.name} rows="3" 
value={field.value} placeholder="Let us know how ParkEngage can help you." onChange={this.InputHandler} onBlur={this.InputHandler}
 />

 {this.validator.message(validationTxt, field.value, 'required|'+field.validation)}
</div>

                      <div className="form-group  d-none initial no-value">

                      {/* <RedditTextField label={field.label}
       fullWidth defaultValue={field.value}  variant="filled" multiline rows={4} onChange={this.InputHandler} onBlur={this.InputHandler} name={field.name} id={field.name}
      /> */}

            <label htmlFor={field.name}>{field.label}</label>
   
                     
                      <textarea type="text" id={field.name} className="form-control textarea1" name={field.name} rows="3" 
                      value={field.value} placeholder="Please Enter Your Address" onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                      
                       {this.validator.message(validationTxt, field.value, 'required|'+field.validation)}
                    </div>
                    </div>
                    
                  )
                    } else {
                      return(
                    <div key={index} className="col  mb-2">
                      
                      <div className="form-label-group ">
                        {/* {field.name == "phone" ? 
                        <InputMask type="tel" mask="9999999999" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        :
                        <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                        } */}
                        <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       />
                     
                       {this.validator.message(validationTxt, field.value, 'required|'+field.validation)}
                    </div>
                    </div>
                  )
                    }
                     
                     
                      }) }

              <div className="mb-2 col">
              <div className="form-group ">
              {/* <label >Select Country</label> */}
              <select class="form-control"  onChange={this.UpdateCountry} name="country">
                <option>Please Select Country</option>
                {
                      countryData && countryData.map( (country, index)=>(
                        <option  value={country.name}>{country.name}</option>
                      ) )
                    }

              </select>
{/* 
              <TextField
                          id="standard-select-ccoulgry"
                          select
                          label="Select"
                          className="form-control"
                          variant="outlined"
                        
                          onChange={this.UpdateCountry} name="country"
                        >

{
                      countryData && countryData.map( (country, index)=>(
                        <MenuItem value={country.name}>{country.name}</MenuItem>
                      ) )
                    }
                          
                        </TextField> */}
             
                  {this.validator.message('country', this.state.country, 'required')}
                  </div> </div>
              <div class=" col-md-12">
                  <div className="form-group  ">
                {  !this.state.loading && 
                // <button  type="submit" class="btn btn-primary btn-outline-primary mb-5"> <i class="la la-angle-right"></i></button>
                <Button onClick={this.submitHandler} className="btn-outline-primary mb-5" variant="primary">  Submit <i class="la la-angle-right"></i></Button>
                }
                {  this.state.loading &&  
                <button disabled class="btn btn-primary btn-fill btn-outline-primary mb-5" disabled="disabled" >Submit <i class="la la-angle-right"></i></button>
                }
                
               </div>
               </div>
              
            </div>
            </div>
            </div>
            </div>
            
              </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.ContactFormReducer.isFetching,
        result: state.ContactFormReducer.result,
        countries: state.fetchDemoServices.countries,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ContactFormHandler: (data) => dispatch(actionCreators.ContactFormRequestService(data)),
        requestFetchCountry: data =>  dispatch(actionCreators.requestFetchCountry()),
    }
}

export default connect( mapStateToProps, mapDispatchToProps ) (withRouter(ContactForm))