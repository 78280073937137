import CryptoJS from 'crypto-js';
import {ApiConfig} from './../config/ApiConfig';
export const EncryptionHandler = (d)=> {
    let encryptKey = ApiConfig.encryptKey
    var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code;
     var CryptoJSAesJson = {
         stringify: function (cipherParams) {
             var j = {
                 ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                 iv: '',
                 s: ''
             };
             if (cipherParams.iv)
                 j.iv = cipherParams.iv.toString();
             if (cipherParams.salt)
                 j.s = cipherParams.salt.toString();
             return JSON.stringify(j);
         },
         parse: function (jsonStr) {
             var j = JSON.parse(jsonStr);
             var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
             if (j.iv)
                 cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
             if (j.s)
                 cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
             return cipherParams;
         }
     };
     var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), encryptKey, { format: CryptoJSAesJson }).toString();
     return encrypted;
 }
