import React, { Component } from 'react';
import * as actionCreators from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import NotificationSystem from "react-notification-system";
import $ from 'jquery';
import monthlyPayment from "../../pages/services/assets/monthly-payment.png";
// import imaged from "./Assets/PARCS-IMG.png"
import imaged from "./Assets/Bitmap.png"



import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import Aux from '../Wrapper/Auxiliary';
import HowItWork from '../services/service-details/HowItWork';
import ServiceDescription from '../services/service-details/serviceDescription';
import ServiceFeatures from '../../pages/services/service-details/ServiceFeatures';

class SoftwareDefinedParcs extends Component {
    serviceId = '';
    
      isLoggedIn = false;
      activeMonthly = true;
      enableActive = false;
    constructor(props){
        super(props)
        if(localStorage.getItem('access_token')){
          this.isLoggedIn = true;
        }else {
          this.isLoggedIn = false;
        }
       this.validator = new SimpleReactValidator();
        this.notificationSystem = React.createRef();
        let location = props.history.location
        let currentServiceId = location.state
        this.state= {
            hideBuyButton : true,
            hideUserForm: true,
            serviceId: '',
            serviceData: this.serviceData,
            CurrentPath: this.props.match.params.serviceName,
            userSelectedPlan : {
                amount: '',
                type: '',
                days: '',
                is_trial: '',
                plan_id: '',
                service_id: '',
            },
            country: '',
            loading: false,
            agreeForTerms: false,

            requestTrialForm: {
              first_name: {
                value: '',
                name: 'first_name',
                type: 'text',
                label: 'First Name',
                validation: 'required'
              },
              email: {
                value: '',
                name: 'email',
                type: 'text',
                label: 'Email',
                validation: 'email'
              },
              phone: {
                value: '',
                name: 'phone',
                type: 'text',
                label: 'Contact Number',
                validation: 'integer|min:10'
              },
              company_name: {
                value: '',
                name: 'company_name',
                type: 'text',
                label: 'Company Name',
                validation: 'required'
              },
              city: {
                value: '',
                name: 'city',
                type: 'text',
                label: 'City',
                validation: 'required'
              },
              service: {
                value: 'SDPARCS',
                name: 'service',
                type: 'text',
                label: 'Service',
                validation: 'required',
                id: 16,
                disabled: true,
                readonly: true,
              },
              // country: {
              //   value: '',
              //   name: 'country',
              //   type: 'text',
              //   label: 'Country',
              //   validation: 'required'
              // }
            
             
      
            },
        }
    let serviceId = 1
    
  this.setState({serviceId: serviceId})
}

serviceData =[
 
  { pageSlug:'softwaredefinedparcs',
      serviceSlogen: 'Software Defined PARCS ',
      serviceTitle: 'SOFTWARE DEFINED PARKING AND REVENUE CONTROL SYSTEMS (PARCS)',
      serviceDesc: `From computers to cars, the technology world is moving towards Software Defined Systems. ParkEngage has created a new category of parking systems called Software Defined PARCS. These end-to-end parking systems abstract the expensive hardware and make it easily replaceable by the off-the-shelf, plug-and-play, low-cost, disposable devices.With Software Defined PARCS, ParkEngage has moved the control of the PARCS  out of the hands of the vendors into the hands of the customer`,
        serviceImage: imaged,
        shortDesc: `With our Touchless Parking Service, create a <span class="text-primary font-weight-bold">seamless parking experience</span> for customers in valet and self-park facilities.`,
        serviceFeatures: [
          'Business Agility and Business Alignment',
          'Hardware Commoditization and Miniaturization',
          'Zero Maintenance and Uninterrupted Operations',
          'Freedom from expensive Hardware Lifecycle upgrades',
          'Continuously evolving system with no end-of-life'
        
        ],
        HowItWork: [
          'Business Agility and Business Alignment',
          'Hardware Commoditization and Miniaturization',
          'Zero Maintenance and Uninterrupted Operations',
          'Freedom from expensive Hardware Lifecycle upgrades',
          'Continuously evolving system with no end-of-life'
        
        ]
  },
  
]
  
    componentWillMount() {
      document.title = 'Software Defined Parking and Revenue Control Systems'

        this.props.requestForDemoServices().then(() => {
          if(this.props['result']) {
            this.ServicesData = this.props.result.data;

           if(this.ServicesData){
               for( let service of this.ServicesData ){
                if(service.id ===  this.serviceId){
                    this.setState({ currentService: 1 });
                }
               }
           }
          }
        });

        this.props.requestFetchCountry().then(() => {
          if(this.props['countries']) {
            this.countryData = this.props.countries.data;
            this.setState({ countryData: this.countryData });
          }
        });
      }

      InputHandler = (event) => {
        let { name, value } = event.target;
        this.validator.showMessageFor(name);
        let updated = { ... this.state.requestTrialForm}
        let nameRegix = /^[A-Za-z\s]*$/;
        let phoneRegix = /^[\d]+$/g;
      
        if (name === 'phone') {
          if (value.match(phoneRegix)) {
            if (value.length > 10) {
              value = updated[name].value
            } else { value = value }
          }
          
        }
        updated[name].value = value;
        this.setState({requestTrialForm:updated})
      }

      UpdateCountry = (event)=> {
        const { name, value } = event.target;
        let {country} =  this.state
        if(name == 'country'){
          this.setState({country: value})
        }
      }

      UpdateService = (event) => {
        const { name, value } = event.target;
          this.setState({ serviceId: value })
        
      }

      BuyServicePlanByLoggedInUser = ()=>{
          if(localStorage.getItem('access_token') && localStorage.getItem('refresh_token') ){
            this.setState({hideUserForm: true})
            let formData= [];
            let {userSelectedPlan } = this.state;
            //let serviceImgId = userSelectedPlan.service_id;
                         // serviceImgId = parseInt(serviceImgId) -1;
                          formData['membership_plan_id'] = userSelectedPlan.plan_id;
                          formData['allServices'] =  this.ServicesData;       
                          formData['type'] = userSelectedPlan.type;
                          formData['days'] = userSelectedPlan.days;
                          formData['amount'] =  userSelectedPlan.amount;
                          formData['is_trial'] =  userSelectedPlan.is_trial;
                          formData['service_plan_id'] = userSelectedPlan.service_id;
                          //formData['servicesImg'] = this.servicesImg[serviceImgId];
                          formData['existing_membership_plan_id'] = userSelectedPlan.plan_id;
                          formData['token'] = localStorage.getItem('access_token');
                          this.props.history.push('/payment', formData);

          }else {
            this.setState({hideUserForm: false})
          }
          
      }
      ChangePlanType = (type)=>{ 
        this.validator.hideMessages()
        this.enableActive = false
        this.setState({hideBuyButton: true, hideUserForm: true})
        if(type == 1){
            this.activeMonthly = true
        }else {
            this.activeMonthly = false
        }
      }
      selectedPlanForBuy = (plan, type)=> {
        $('#customCheck1').prop("checked", false);
        this.setState({ agreeForTerms: false });
        this.validator.hideMessages()
        this.enableActive = true
          let { userSelectedPlan } = this.state;
        if(type == 'montly'){
            userSelectedPlan['amount']= plan.amount
            userSelectedPlan['type']= '1'
            userSelectedPlan['days']= '30';
            userSelectedPlan['name']= plan.name
        }
        else {
            userSelectedPlan['type']= '2'
            userSelectedPlan['days']= '365'
            userSelectedPlan['amount']= plan.annual_amount;
            userSelectedPlan['name']= plan.name;
        }
        userSelectedPlan[ 'plan_id'] = plan.id
        userSelectedPlan['service_id']= plan.service_id;
        userSelectedPlan['is_trial']= plan.is_trial
        if(this.isLoggedIn ){
            this.setState({userSelectedPlan: userSelectedPlan, hideBuyButton: false, hideUserForm:true});
        }else {
          this.setState({userSelectedPlan: userSelectedPlan, hideBuyButton: false, hideUserForm:false});
        }
        }

        checkAgreeStatus = (event)=> {
            let {agreeForTerms} = this.state;
            const { name, value, checked } = event.target;
            if(checked){
              agreeForTerms = true;
             }else {
              agreeForTerms = false
             }
            this.setState({ agreeForTerms: agreeForTerms });
          }

          formSubmitHandler = (event) => {
            event.preventDefault();
            const notification = this.notificationSystem.current;
            if (this.validator.allValid()) {
              let formData = {};
              let { requestTrialForm, userSelectedPlan } = this.state;
              for (let field in requestTrialForm) {
                formData[field] = requestTrialForm[field].value;
              }
              formData['country'] = 'N/A';
              formData['name'] = requestTrialForm['first_name'].value;
              formData['contact'] = requestTrialForm['phone'].value;
              // formData['phone'] = requestTrialForm['phone'].value;
              formData['product'] = requestTrialForm['service'].id;
              formData['form_status'] = 'SDPARCS';
        
              formData['membership_plan_id'] = userSelectedPlan.plan_id;
              formData['is_trial'] = userSelectedPlan.is_trial;
              formData['plan_type'] = userSelectedPlan.type;
              formData['no_of_days'] = userSelectedPlan.days;
        
              this.setState({ loading: true });
              this.props.requestForDetailsDemo(formData).then((data) => {
                if (data['error']) {
                  if (data.error.response.status == 422) {
                    let errorData = data.error.response
                    let errorDataMsg = errorData.data.errors
                    let errorTitle = errorDataMsg.message
                    let errorMsgs = errorDataMsg.detail;
                    for (let field in errorMsgs) {
                      let error = errorMsgs[field][0];
                      notification.addNotification({
                        title: errorTitle + '!',
                        message: error,
                        level: 'error'
                      });
                    }
                    this.setState({ loading: false });
                    return false;
                  } else {
        
                    let errorData = data.error.response
                    errorData = errorData.data
        
                    if (errorData['errors']) {
                      let errormsg = errorData.errors;
                      errormsg = errormsg['message'];
                      notification.addNotification({
                        title: 'Validation Errors!',
                        message: errormsg,
                        level: 'error'
                      });
                    } else {
                      let errormsg = errorData.data;
                      errormsg = errormsg['errors'];
                      notification.addNotification({
                        title: 'Validation Errors!',
                        message: errormsg,
                        level: 'error'
                      });
        
                    }
        
                    this.setState({ loading: false });
                  }
        
                } else if (data['data']) {
                  notification.addNotification({
                    title: 'Thank You',
                    message: 'Your request has been submitted successfully, We will get in touch with you soon',
                    level: 'success'
                  });
                  // let Res = data.data
                  // let access_token = '';
                  // let token = Res.data.token;
                  // let planAssigned = Res.data.membership_plan
                  // access_token = token.access_token
                  ///let serviceImgId = userSelectedPlan.service_id;
                  //serviceImgId = parseInt(serviceImgId) -1;
                  // formData['allServices'] = this.ServicesData;
                  // formData['type'] = userSelectedPlan.type;
                  // formData['days'] = userSelectedPlan.days;
                  // formData['amount'] = userSelectedPlan.amount;
                  // formData['is_trial'] = userSelectedPlan.is_trial;
                  // formData['service_plan_id'] = userSelectedPlan.service_id;
                  // formData['servicesImg'] = this.servicesImg[serviceImgId];
                  // formData['token'] = access_token;
                  // formData['existing_membership_plan_id'] = planAssigned.id
                  // this.props.history.push('/payment', formData);
        
                } else {
                  notification.addNotification({
                    title: 'Sorry, Something went Wrong!',
                    message: 'Please Try again letter!',
                    level: 'error'
                  });
                }
        
              });
        
            } else {
              this.validator.showMessages();
              this.forceUpdate();
              // notification.addNotification({
              //   title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
              // });
            }
          }
      
    render() {
        const {currentService, serviceId, requestTrialForm, loading, countryData, agreeForTerms, userSelectedPlan} = this.state
      
        let FieldsArr = []; let i = 0;
    for( let formData in requestTrialForm ){
      FieldsArr[i] = { name: formData, value:requestTrialForm[formData].value, label:requestTrialForm[formData].label,
        type:requestTrialForm[formData].type, validation:requestTrialForm[formData].validation,
        readonly: requestTrialForm[formData].readonly,
        }
        i++;
    }

        let PlansList = '';
        if(currentService && currentService['membership_plan']){
            PlansList = currentService['membership_plan']
        }
        return (
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <div>
                        { this.state.serviceData.map( ( service, index )=> (
                            <div key={index}>
                            
                        
                            <>
                            <BreadCrumb headerTitle={`Products > ${service.serviceTitle}`} headerName={service.serviceTitle}  />
                            <section id="" className="mt-5 mb-3">
                    
                </section>
             
                            { service.serviceDesc ? 
                            
                            
                            
                            <ServiceDescription title={service.serviceSlogen} content={service.serviceDesc} image={service.serviceImage} ></ServiceDescription>
                            : null
                            }
                            { service.HowItWork ?
                                <HowItWork title={service.serviceTitle} ishowshow="Benefits of the Software Defined PARCS–" features={service.HowItWork} ></HowItWork> 
                             : null }
                            
                            </>
                        
                                  
                        </div>
                    ) ) }
                
          <section id="content-section">
            <div className="container">
              <div className="text-center my-5">
              {/* <h2 className="text-dark font-weight-light  ">Stop by our <span className='font-weight-bold '>Booth #614</span> at the <span class="font-weight-bold ">NPA Convention in New Orleans</span> to learn more. If you are not coming to the NPA Convention, then connect with us today by completing the information below to learn more about Software Defined PARCS.</h2> */}
                <h2 className="text-dark font-weight-light  ">You can connect with us by completing the information below to learn more about <b>Software Defined PARCS</b>.</h2>
              </div>
            </div>

            <div className="register-form pt-2 pb-3">
              <div className="container">
                <form name="requestTrialForm" onSubmit={this.formSubmitHandler} >
                  <div className="request-trial-form  mt-4">

                    <div className="row row-cols-1 row-cols-md-2 row-cols-sm-2">
                      {FieldsArr.map((field, index) => {
                        let validationTxt = '';
                        if (field.name == 'email') { validationTxt = 'Email' } else { validationTxt = field.label }
                        return (
                          <div key={index} className="col  mb-2">
                            <div className="form-label-group">
                              {field.name == "phone" ?
                                <InputMask type="tel" mask="9999999999" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                                  value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                                />
                                :
                                <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                                  value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                                  disabled={field.disabled}
                                  readOnly={field.readonly}
                                />
                              }
                              {/* <input type="text" id={field.name} className="form-control" name={field.name} placeholder={field.label}
                      value={field.value} onChange={this.InputHandler} onBlur={this.InputHandler}
                       /> */}
                              {/* <RedditTextField
                        id={field.name}
                        name={field.name}
                        placeholder={field.label}

                            onChange={this.InputHandler} onBlur={this.InputHandler}
                            label={field.label}
                            value={field.value} 
                            fullWidth
                            variant="filled"
                            defaultValue={field.value} 
                         
                           /> */}

                              {this.validator.message(validationTxt, field.value, 'required|' + field.validation)}
                            </div>
                          </div>
                        )
                      })}
                      <div className="col  mb-2 d-none">
                        <div className="form-group ">

                          <TextField
                            id="standard-select-ccoulgry"
                            select
                            label="Please Select A Country"
                            className="form-control"
                            variant="outlined"

                            onChange={this.UpdateCountry} name="country"
                          >

                            {
                              countryData && countryData.map((country, index) => (
                                <MenuItem value={country.name}>{country.name}</MenuItem>
                              ))
                            }

                          </TextField>


                          {/* <select id="country" className="custom-select" onChange={this.UpdateCountry} name="country">
                    <option value="">Please select Country</option>
                    {
                      countryData && countryData.map( (country, index)=>(
                        <option value={country.name}>{country.name}</option>
                      ) )
                    }
                  </select> */}
                          {/* {this.validator.message('country', this.state.country, 'required')} */}
                        </div>
                      </div>
                      <div className="col  mb-2 d-none">
                        <div className="form-group ">

                          <TextField
                            id="standard-select-ccoulgry"
                            select
                            label="Please Select A Service"
                            className="form-control"
                            variant="outlined"

                            onChange={this.UpdateService} name="service"
                          >

                            {
                              this.ServicesData && this.ServicesData.map((service, index) => (
                                <MenuItem value={service.id}>{service.full_name}</MenuItem>
                              ))
                            }

                          </TextField>


                          {/* <select id="country" className="custom-select" onChange={this.UpdateCountry} name="country">
                    <option value="">Please select Country</option>
                    {
                      countryData && countryData.map( (country, index)=>(
                        <option value={country.name}>{country.name}</option>
                      ) )
                    }
                  </select> */}
                          {/* {this.validator.message('service', this.state.serviceId, 'required')} */}
                        </div>
                      </div>
                      

                    </div>
                    <div className="mb-3 text-center ">
                        <button type="submit" className="btn btn-primary  px-4" >Request Demo </button>




                      </div>


                  </div>
                </form>
              </div>
            </div>
          </section>
                         







                         </div>
        </Aux>
        )
    }

}

const mapStateToProps = state => {
    return {
      isFetching: state.fetchDemoServices.isFetching,
      result: state.fetchDemoServices.result,
      countries: state.fetchDemoServices.countries,
      response: state.trialRequest.result
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      requestForDemoServices: data =>  dispatch(actionCreators.requsrtFetchServicesDemo()),
      requestFetchCountry: data =>  dispatch(actionCreators.requestFetchCountry()),
      requestForDetailsDemo: (data) => dispatch(actionCreators.requestForDetailsDemo(data)),
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(SoftwareDefinedParcs);
