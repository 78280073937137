import React from 'react';
import bgDark from './assets/bg-dark.webp';
import logo from '../../assets/img/logo.svg';
import Find from './assets/find.svg';
import reserve from './assets/resrerve.svg';
import park from './assets/park.svg';
import leftImg from './assets/left-img.png';
import airportWeb from './assets/ariport-graphic.svg';
import airportMob from './assets/airport-graphic-mob.svg';
import Aux from '../Wrapper/Auxiliary';




function AirportPark() {
  return (
    <Aux>
    <div className='clearfix' >
      <div className='w-100 position-relative flex-column p-4 d-flex align-items-center justify-content-center  ' style={{ backgroundImage : `url(${bgDark})`, minHeight:'500px' }} >
      <img  className='img-responsive mt-5 pt-5' src={logo} alt="Park Engage" />
      <h1 className='text-center font-weight-bold text-white mt-5' >Seamless, Hassle-free ..<br/> Airport Parking</h1>
        {/* <img src={bgDark} className='w-100' /> */}

      </div>
    
      <div className='bg-light py-5'>
        <div className='container'>
        <h3 className='text-primary font-weight-bold text-center pt-5'>Airport Parking</h3>
        <p className='text-center mb-5' >With our Touchless Parking Service, create a seamless parking experience<br className='d-none d-sm-block'/> for customers in valet and self-park facilities.</p>

        <div className='row g-3 justify-content-center'>
          <div className='col'>
            <div class="  m-2 p-5 text-center">
              <img className='mx-auto' height={'50px'} src={Find}/>
              <h2 className='font-weight-light my-3 '>Find</h2>
            </div>
          </div>
          <div className='col-auto d-none d-sm-block border-right'></div>
          <div className='col'>
            <div class="  m-2 p-5 text-center">
              <img className='mx-auto' height={'50px'} src={reserve}/>
              <h2 className='font-weight-light my-3 '>Reserve</h2>
            </div>
          </div>
          <div className='col-auto d-none d-sm-block border-right'></div>
          <div className='col'>
            <div class=" m-2 p-5 text-center">
              <img className='mx-auto' height={'50px'} src={park}/>
              <h2 className='font-weight-light my-3 '>Park</h2>
            </div>
          </div>
        </div>
         
        </div>
        <div className='container'>
          <h2 className='text-dark font-weight-bold  mt-3 text-center '> Digital at Every Touchpoint</h2>
        </div>

      </div>
      <div className='container p-5 '>
        <div className='row align-items-center justify-content-center'>
          <div className='col-md-5 mb-5'><img className='w-100' src={leftImg}/></div>
          <div className='col-md-5 mb-5 '><h4 className='h1 font-weight-light text-black mb-3'>Convenient Parking,<br/> Every Time</h4>
            <ul className='d-flex flex-column nav'>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>Airport Parking</li>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>Ticketless</li>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>Contactless</li>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>Cashless</li>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>Frictionless</li>
            <li className='my-1'><i class="fa fa-circle text-primary mr-2 "></i>No lines to pay</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='bg-light py-5'>
        <div className='container px-5'>
          <h2 className='text-center font-weight-bold my-5' >Quick and Easy Parking Access</h2>

          <img src={airportWeb} className='d-none w-100  d-sm-block'/>
          <img src={airportMob} className='d-block w-100  d-sm-none'/>
        </div>

      </div>
      
    </div>
    </Aux>
  )
}

export default AirportPark
