import React , { Component } from 'react';
import Aux from '../Wrapper/Auxiliary';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
export default class PrivacyPolicy extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Aux>
           
         <BreadCrumb headerTitle={"Privacy Policy"} headerName={"Privacy Policy"} headerContent={""}></BreadCrumb>
         <section id="content-section">
             <div className="container">
               <div className="text-center border-bottom my-3">
                 <h2 className="text-dark font-weight-light">Lorem ipsum dolor sit amet</h2>
              </div>
             </div>

             <div className="cart mt-3 pt-2 pb-3">
             <div className="container">
                 <div className="row">
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi magna lorem, efficitur in erat nec, 
                 ultricies aliquet erat. Maecenas vel convallis dui, non volutpat dolor. Nunc consequat lacinia laoreet.
                  Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. 
                  Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus at
                   tincidunt sapien. Duis dui arcu, sodales ut lectus id, efficitur rhoncus ligula. Pellentesque fermentum 
                   imperdiet interdum. Morbi at velit finibus, interdum purus ac, condimentum nibh. Morbi nec tempus velit.
                    Vivamus vel orci suscipit, malesuada leo non, rutrum turpis.</p>
                    <p>
                    In eu velit sed enim egestas volutpat quis a diam. Vivamus porta lectus nec sapien porttitor, et iaculis augue pretium. Aliquam id ex venenatis, pharetra dui a, rhoncus leo. Maecenas hendrerit pharetra vulputate. Sed et maximus justo, gravida vehicula ligula. Donec purus lacus, tincidunt tempus eros ultrices, finibus faucibus velit. Vestibulum volutpat, tellus ut consectetur rutrum, leo est pretium dolor, dignissim semper turpis lectus sit amet mi. Praesent sed quam convallis, congue augue nec, malesuada ex. Ut vehicula venenatis nulla, id dignissim metus varius sed. Etiam lacinia neque a tellus gravida, sed interdum quam mollis. Sed sit amet dolor dapibus, efficitur lacus at, cursus nulla. Ut vitae sodales libero. In et eros aliquam, varius nisi eget, iaculis enim. Suspendisse pretium sapien ullamcorper libero egestas, in consectetur ante auctor.
                    </p>
                    <p>Cras dolor massa, accumsan id bibendum ut, pellentesque non lectus. Proin eu arcu ac ex elementum commodo et non eros. Morbi malesuada felis at commodo vestibulum. Mauris vitae sodales metus, quis semper risus. Pellentesque lobortis auctor sollicitudin. Suspendisse potenti. Sed bibendum nisi ut velit tempus interdum. Vivamus tempor ornare enim sed laoreet. Phasellus elementum finibus ex at mollis. Integer tristique felis eu rhoncus fringilla.</p>
                 </div>
             </div>
             </div>
         </section>
 </Aux>   
        )
    }
}