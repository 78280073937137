import * as actionTypes from './../ActionTypes';
import {updateObject} from './../utility';

const initialState = {
    isFetching: true,
    result : [],
    countries: [],
}

const FetchServiceReducer = ( state = initialState, action ) => {

    switch( action.type ) {
        case actionTypes.DEMO_DATA_REQUEST : 
            return updateObject ( state , { } );
        case actionTypes.DEMO_DATA_REQUEST_SUCCESS :
            return updateObject ( state, { result : action.data, isFetching: false });
        case actionTypes.DEMO_DATA_REQUEST_FAIL : 
            return updateObject ( state , {isFetching: false} );
        
            case actionTypes.FETCH_COUNTRY_REQUEST : 
            return updateObject ( state , { } );
        case actionTypes.FETCH_COUNTRY_SUCCESS :
            return updateObject ( state, { countries : action.data, isFetching: false });
        case actionTypes.FETCH_COUNTRY_FAIL : 
            return updateObject ( state , {isFetching: false} )
        default : 
            return false        
    }
}

export default FetchServiceReducer;